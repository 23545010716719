import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Navbar from "./blocks/Navbar.js";
import { Typography, Box, Paper, Tabs, Tab, Icon } from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import CalendarList from "./blocks/CalendarList.js";
import { useNavigate, Link } from "react-router-dom";
import ActionCard from "./blocks/ActionCard.js";
import LoadingAnimation from "./blocks/LoadingAnimation.js";
import {
  copyCalendarLinkToClipboard,
  getCalendarApiUrl,
  getWebcalFormat,
  handleCustomerPortal,
  handleOpenWebcal,
  handleSubscribeExperience,
  handleUnsubscribe,
} from "src/utils/Utils.js";
import { AuthContext } from "src/utils/AuthContext.js";
import {
  getPageRoute,
  getApiRoute,
  useGetUserCalendars,
  useUnsubscribeGroup,
  useUnsubscribeCalendar,
  useRemoveFromGroup,
} from "src/services";
import Footer from "./blocks/Footer.js";
import { useTheme } from "@emotion/react";
import { revealNavbar, sortAlphabetically } from "src/utils/Utils.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import ShareDialog from "./blocks/dialogs/ShareDialog.js";
import { useGroupDialogs } from "src/utils/useGroupDialogs.js";
import NewGroupDialog from "./blocks/dialogs/NewGroupDialog.js";
import AddToGroupDialog from "./blocks/dialogs/AddToGroupDialog.js";
import { Helmet } from "react-helmet";

function Library({ user }) {
  const [importedCalendars, setImportedCalendars] = useState([]);
  const [createdGroups, setCreatedGroups] = useState([]);
  const [addedItems, setAddedItems] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [activeTab, setActiveTab] = useState("calendars");
  const { refetchAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [shareDialogCalendar, setShareDialogCalendar] = useState(null);
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();
  const { mutateAsync: unsubscribeCalendar } = useUnsubscribeCalendar();
  const { mutateAsync: removeFromGroup } = useRemoveFromGroup();
  const { mutateAsync: unsubscribeGroup } = useUnsubscribeGroup();
  const {
    data: userCalendars,
    refetch: refetchUserCalendars,
    isLoading,
  } = useGetUserCalendars(
    { user },
    {
      onSuccess: (data) => {
        setImportedCalendars(
          sortAlphabetically(data.createdCalendars, "name") ?? []
        );
        setCreatedGroups(sortAlphabetically(data.createdGroups, "name") ?? []);
        setAddedItems(
          sortAlphabetically(
            [...(data.addedCalendars ?? []), ...(data.addedGroups ?? [])],
            "name"
          )
        );
        setInitialLoad(false);
      },
    }
  );
  const {
    openAddToGroup,
    openNewGroup,
    setOpenAddToGroup,
    setOpenNewGroup,
    handleGroupDialog,
    handleAddToGroup,
    handleCreateGroup,
    handleMessage,
    targetCalendar,
  } = useGroupDialogs({
    setCreatedGroups,
    moveCalendarToGroup: true,
  });

  useEffect(() => {
    refetchUserCalendars();
  }, []);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }).format(date);
    } catch (error) {
      console.error(error);
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "This browser isn't displaying dates correctly. Please use another browser.",
        "error"
      );
      return "Unknown date/time";
    }
  };

  const handleTabChange = async (event, key) => {
    revealNavbar();
    if (key === "groups") {
      refetchUserCalendars();
    }
    setActiveTab(key);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Helmet>
        <title>Library - Stanza</title>
        <meta
          name="description"
          content="All the calendars you've added or imported."
        />
      </Helmet>
      <Navbar user={user} />
      <Box
        sx={{
          margin: "auto",
          maxWidth: { xs: "calc(100% - 2rem)", sm: "600px" },
          minWidth: { xs: "calc(100% - 2rem)", sm: "600px" },
          marginTop: 0,
          flexGrow: 1,
          p: 2,
        }}
      >
        <Typography
          as="h1"
          variant="title"
          sx={{ mb: 1, mt: { md: 3 }, fontWeight: "600" }}
        >
          Your Library
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 3 }}>
          Everything you've created or added.
        </Typography>
        <Tabs
          id="library-tabs"
          data-testid="library-tabs"
          value={activeTab}
          onChange={handleTabChange}
          variant={"fullWidth"}
        >
          <Tab label="Calendars" value="calendars" disableRipple />
          <Tab label="Groups" value="groups" disableRipple />
          <Tab label="Imports" value="imports" disableRipple />
        </Tabs>
        <Box role="tabpanel" hidden={activeTab !== "calendars"}>
          {user.consumerSubscription?.isCancelled ? (
            <Paper
              variant="outlined"
              sx={{ p: 2, mt: 2 }}
              data-testid="library-canceled-calendar-copy"
            >
              <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
                ⚠️ Your plan will be canceled, but is still available until the
                end of your billing period on{" "}
                {user.consumerSubscription
                  ? formatDate(user.consumerSubscription?.expiresAt)
                  : ""}
                . At that point, you can add only 2 calendars.{" "}
                <strong>
                  Any extra calendars will be automatically deleted.
                </strong>{" "}
                <br /> <br />
                If you change your mind, you can{" "}
                <Link
                  onClick={handleCustomerPortal}
                  style={{ textDecoration: "none" }}
                >
                  renew
                </Link>{" "}
                your subscription to keep all your calendars.
              </Typography>
            </Paper>
          ) : null}
          {isLoading || initialLoad ? (
            <LoadingAnimation />
          ) : addedItems.length > 0 ? (
            <CalendarList
              id={"calendars"}
              calendars={addedItems}
              buttonIcon={(props) => (
                <Icon baseClassName="material-symbols-outlined" {...props}>
                  event_repeat
                </Icon>
              )}
              buttonText="Resubscribe"
              buttonAction={(calendar) => {
                const calendarApiUrl = getCalendarApiUrl({ calendar, user });
                handleOpenWebcal(
                  user,
                  getWebcalFormat(calendarApiUrl),
                  calendar,
                  navigate,
                );
              }}
              threeDotsMenu={[
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      create_new_folder
                    </Icon>
                  ),
                  name: "Add to Group",
                  action: handleGroupDialog,
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      link
                    </Icon>
                  ),
                  name: "Copy Link",
                  action: (calendar) => {
                    copyCalendarLinkToClipboard({
                      inGroup: false,
                      user,
                      calendar,
                      showSnackBar,
                      setSnackbarOpen,
                      setSnackbarMessage,
                      setSnackbarSeverity,
                    });
                  },
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      share
                    </Icon>
                  ),
                  name: "Share",
                  action: (calendar) => {
                    setShareDialogCalendar(calendar);
                  },
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      delete
                    </Icon>
                  ),
                  name: "Delete",
                  color: "error",
                  action: (calendar) =>
                    handleUnsubscribe({
                      user,
                      calendar,
                      unsubscribeGroup,
                      refetchUserCalendars,
                      createdGroups: userCalendars.createdGroups,
                      removeFromGroup,
                      unsubscribeCalendar,
                      onUnsubscribed: async () => {
                        setAddedItems(
                          addedItems.filter((c) => c._id !== calendar._id)
                        );
                        refetchAuth();
                      },
                      showSnackBar,
                      setSnackbarOpen,
                      setSnackbarMessage,
                      setSnackbarSeverity,
                    }),
                },
              ]}
            />
          ) : (
            <ActionCard
              title="No calendars"
              testid="user-no-added-calendar"
              description="You haven't added any calendars yet."
              primaryButtonCTA="Discover calendars"
              primaryAction={() => navigate(getPageRoute("home", "HOME"))}
              iconName="calendar_month"
            />
          )}
        </Box>
        <Box role="tabpanel" hidden={activeTab !== "groups"}>
          {user.consumerSubscription?.isCancelled ? (
            <Paper
              variant="outlined"
              sx={{ p: 2, mt: 2 }}
              data-testid="library-canceled-group-copy"
            >
              <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
                ⚠️ Your plan will be canceled, but is still available until the
                end of your billing period on{" "}
                {user.consumerSubscription
                  ? formatDate(user.consumerSubscription?.expiresAt)
                  : ""}
                . At that point, you can no longer add any groups.{" "}
                <strong>Any groups will be automatically deleted.</strong>{" "}
                <br /> <br />
                If you change your mind, you can{" "}
                <Link
                  onClick={handleCustomerPortal}
                  style={{ textDecoration: "none" }}
                >
                  renew
                </Link>{" "}
                your subscription to keep all your calendars.
              </Typography>
            </Paper>
          ) : null}
          {isLoading || initialLoad ? (
            <LoadingAnimation />
          ) : createdGroups.length > 0 ? (
            <CalendarList
              id={"groups"}
              calendars={createdGroups}
              buttonIcon={(props) => <EditOutlined {...props} />}
              buttonText="Edit"
              buttonAction={(calendar) =>
                navigate(
                  getPageRoute("calendar", "CALENDAR_SETTINGS", {
                    handle: user.handle,
                    calendarId: calendar._id,
                  }),
                  {
                    state: { calendar: calendar },
                  }
                )
              }
              threeDotsMenu={[
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      event_repeat
                    </Icon>
                  ),
                  name: "Resubscribe",
                  action: (calendar) => {
                    const calendarApiUrl = getCalendarApiUrl({
                      calendar,
                      user,
                    });
                    const defaultFn = () =>
                      handleOpenWebcal(
                        getWebcalFormat(calendarApiUrl),
                        calendar,
                        navigate
                      );
                    handleSubscribeExperience({
                      navigate,
                      calendar,
                      defaultFn,
                    });
                  },
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      link
                    </Icon>
                  ),
                  name: "Copy Link",
                  action: (calendar) => {
                    copyCalendarLinkToClipboard({
                      inGroup: false,
                      user,
                      calendar,
                      showSnackBar,
                      setSnackbarOpen,
                      setSnackbarMessage,
                      setSnackbarSeverity,
                    });
                  },
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      share
                    </Icon>
                  ),
                  name: "Share",
                  action: (calendar) => {
                    setShareDialogCalendar(calendar);
                  },
                },
              ]}
            />
          ) : (
            <ActionCard
              title="No groups"
              testid="user-no-created-group"
              description="You haven't created any groups yet."
              primaryButtonCTA="Discover calendars"
              primaryAction={() => navigate(getPageRoute("home", "HOME"))}
              iconName="folder"
            />
          )}
        </Box>
        <Box role="tabpanel" hidden={activeTab !== "imports"}>
          {user.createdCalendars.length > user.maxNumCreatedCalendars ? (
            <Paper
              variant="outlined"
              sx={{ p: 2, mt: 2 }}
              data-testid="library-canceled-creator-copy"
            >
              <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
                ⚠️ Your current plan allows you to import{" "}
                {user.maxNumCreatedCalendars} calendar
                {user.maxNumCreatedCalendars === 1 ? "" : "s"}. Please delete{" "}
                {importedCalendars.length - user.maxNumCreatedCalendars}{" "}
                calendar
                {importedCalendars.length - user.maxNumCreatedCalendars === 1
                  ? ""
                  : "s"}{" "}
                by{" "}
                {user.consumerSubscription
                  ? formatDate(user.creatorSubscription?.expiresAt)
                  : ""}
                .{" "}
                <strong>
                  Any extra calendars will be automatically deleted.
                </strong>{" "}
                <br />
                <br />
                If you change your mind, you can{" "}
                <Link
                  onClick={handleCustomerPortal}
                  style={{ textDecoration: "none" }}
                >
                  update your subscription
                </Link>{" "}
                to keep all your calendars.
              </Typography>
            </Paper>
          ) : null}
          {isLoading || initialLoad ? (
            <LoadingAnimation />
          ) : importedCalendars.length === 0 ? (
            <ActionCard
              title="No imports"
              testid="user-no-imported-calendar"
              description="You haven't imported any calendars yet."
              primaryButtonCTA="Import calendar"
              primaryAction={() => navigate(getPageRoute("calendar", "IMPORT"))}
              iconName="calendar_apps_script"
            />
          ) : (
            <CalendarList
              id={"imports"}
              calendars={importedCalendars}
              buttonIcon={(props) => <EditOutlined {...props} />}
              buttonText="Edit"
              buttonAction={(calendar) =>
                navigate(
                  getPageRoute("calendar", "CALENDAR_SETTINGS", {
                    handle: user.handle,
                    calendarId: calendar._id,
                  }),
                  {
                    state: { calendar: calendar },
                  }
                )
              }
              threeDotsMenu={[
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      link
                    </Icon>
                  ),
                  name: "Copy Link",
                  action: (calendar) => {
                    copyCalendarLinkToClipboard({
                      inGroup: false,
                      user,
                      calendar,
                      showSnackBar,
                      setSnackbarOpen,
                      setSnackbarMessage,
                      setSnackbarSeverity,
                    });
                  },
                },
                {
                  icon: (props) => <DeleteOutlined {...props} />,
                  color: "error",
                  name: "Delete",
                  action: async (calendar) => {
                    await axios.post(
                      getApiRoute("calendar", "DELETE_CALENDAR", {
                        calendarId: calendar._id,
                      }),
                      null,
                      { withCredentials: true }
                    );
                    refetchUserCalendars();
                  },
                },
              ]}
            />
          )}
        </Box>
      </Box>
      <ShareDialog
        calendar={shareDialogCalendar}
        handle={shareDialogCalendar?.handle}
        open={Boolean(shareDialogCalendar)}
        setOpen={() => setShareDialogCalendar(null)}
        as="Hidden"
      />

      <NewGroupDialog
        open={openNewGroup}
        handleClose={() => setOpenNewGroup(false)}
        handleCreateGroup={handleCreateGroup}
        user={user}
        calendarId={targetCalendar._id}
        handleMessage={handleMessage}
      />
      <AddToGroupDialog
        createdGroups={createdGroups}
        open={openAddToGroup}
        handleClose={() => setOpenAddToGroup(false)}
        handleCreate={() => {
          setOpenAddToGroup(false);
          setOpenNewGroup(true);
        }}
        handleAdd={handleAddToGroup}
      />
      <Footer />
    </div>
  );
}

export default Library;
