import { useEffect, useState } from 'react';
import { useSnackbarContext } from "src/utils/SnackbarContext.js";

const RedirectionMessage = ({ where = "your calendar app", count = 5, redirectionCallback }) => {
    const [redirectionCounter, setRedirectionCounter] = useState(count);
    const { setSnackbarOpen } = useSnackbarContext();

    useEffect(() => {
        if (redirectionCounter === 0) {
            return setSnackbarOpen(false);
        }
        const timeout = setTimeout(() => {
            setRedirectionCounter(redirectionCounter - 1);
        }, 1000);
        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectionCounter]);

    useEffect(() => {
        return () => {
            if (redirectionCounter === 0) redirectionCallback(); // callback to redirect after countdown
        }
    }, [redirectionCallback, redirectionCounter]);

    return (
        <span>Redirecting to {where} in {redirectionCounter} seconds...</span>
    )

}

export default RedirectionMessage;