import { Box, Typography, Icon, CircularProgress, Button } from "@mui/material";
import { memo, useMemo, useState } from "react";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js";
import RecommendedCalendar from "./event/RecommendedCalendar.js";
import CalendarAddedSuccessfully from "./CalendarAddedSuccessfully.js";

export const CalendarSection = ({ calendarSection, onSubscribeAction, setShowRecommendedCalendars, setAddedCalendars }) => {
    const theme = useTheme();
    const { isMediumScreen } = useDimensions();
    const { title, calendars } = calendarSection;
    const itemsPerPage = 10;

    const calendarsPages = useMemo(() => {
        const pages = [];
        for (let i = 0; i < calendars.length; i += itemsPerPage) {
            pages.push(calendars.slice(i, i + itemsPerPage));
        }
        return pages;
    }, [calendars])
    const [calendarView, setCalendarView] = useState(calendarsPages[0]);

    if (!calendarSection) return null;


    return (
        <>
            <style>{`
    #calendar-section + div > #event-card {
      border-top: none;
    }
    #calendar-section + div {
      margin-top: 0px;
    }
    #calendar-section:first-of-type .MuiBox-root {
      border-top: none;
    }
  `}
            </style>
            <Box id="calendar-section" sx={{
                display: "flex", flexDirection: "column", borderTop: `1px solid ${theme.palette.text.tertiary}`,
            }}>
                <Box sx={{
                    display: "flex", flexDirection: "column", py: 4.5, pl: 2,
                    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
                }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 1 }}>
                        <Icon baseClassName="material-symbols-outlined" sx={{ fontWeight: "300", fontSize: "1.8rem", color: "success.main" }}>check</Icon>
                        <Typography variant="h2" data-testid="calendar-section-title">{title}</Typography>
                    </Box>
                    <Box sx={{ display: { xs: "flex", md: "grid" }, alginItems: "center", mt: 1, gap: { xs: 2, md: "24px 16px" }, flexDirection: { xs: "column" }, gridTemplateColumns: { md: "1fr 1fr" } }}>
                        {calendarView?.map((event, index) => (
                            <>
                                <RecommendedCalendar
                                    calendar={event.calendar}
                                    key={`${event.calendarId}_${index}`}
                                    event={event.event}
                                    calendarId={event.calendarId}
                                    showUpload={false}
                                    position={index}
                                    onSubscribeAction={onSubscribeAction}
                                    setShowRecommendedCalendars={setShowRecommendedCalendars}
                                    setAddedCalendars={setAddedCalendars}
                                />
                                {isMediumScreen && index !== calendarView.length - 1 && <hr style={{ width: "100%", border: `0.5px solid ${theme.palette.text.tertiary}` }} />}
                            </>
                        ))}
                    </Box>
                    {calendarView.length < calendars.length &&
                        <Box sx={{ display: "flex", justifyContent: "center", mt: { xs: 3, md: 5.5 } }}>
                            <Button
                                variant="text"
                                sx={{
                                    textTransform: "none",
                                    padding: 1,
                                }}
                                onClick={() => setCalendarView([...calendarView, ...calendarsPages[Math.floor(calendarView.length / itemsPerPage)]])}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Icon fontSize="medium" sx={{ fontWeight: "300" }} baseClassName="material-symbols-outlined">visibility</Icon>
                                    <Typography variant="subtitle2">See More</Typography>
                                </Box>
                            </Button>
                        </Box>
                    }
                </Box >
            </Box >
        </>
    )
}