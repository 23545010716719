import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { IconButton } from "@mui/material";
import { Close } from '@mui/icons-material'

export const ContainerCloseButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePosition = () => {
      document.getElementById('close-button')?.style.setProperty('right', `calc(10% - ${document.getElementById('container')?.getBoundingClientRect().left}px)`)
    }
    handlePosition();
    window.addEventListener('resize', handlePosition);
    return () => window.removeEventListener('resize', handlePosition);
  }, [])

  const handleGoBack = () => {
    navigate(-1)
  };

  return (
    <IconButton
      id="close-button"
      edge="end"
      color="inherit"
      variant="outlined"
      onClick={handleGoBack}
      aria-label="close"
      sx={{ position: 'absolute', top: "26px" }}
    >
      <Close fontSize="small" />
    </IconButton>
  )
}