import * as React from 'react';
import { AppBar, Box, Toolbar, Typography, Container, MenuItem } from '@mui/material';
import { useDimensions } from "src/utils/useDimensions.js";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { getPageRoute } from 'src/services';

const pages = [{
  title: 'About',
  url: getPageRoute("help", "HELP_IMPORT"),
},
{
  title: 'Privacy',
  url: getPageRoute("privacy", "PRIVACY"),
},
{
  title: 'Terms',
  url: getPageRoute("terms", "TERMS"),
}];

function Footer() {
  const navigate = useNavigate();
  const { isMediumScreen } = useDimensions();
  const theme = useTheme();
  const footerSmall = isMediumScreen ? { boxShadow: "none !important" } : { boxShadow: "none !important", borderTop: `1px solid ${theme.palette.text.tertiary}` };
  return (
    <Box style={{ display: 'flex', justifyContent: 'center', bottom: 0, width: '100%' }}>
      <AppBar position="static" sx={{ backgroundColor: 'white', ...footerSmall }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={() => navigate(page.url)}>
                  <Typography textAlign="center" variant="footer" sx={{ color: 'grey', mr: 1 }}>{page.title}</Typography>
                </MenuItem>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
export default Footer;