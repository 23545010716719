import { Avatar, Typography, Box, Button, Menu, MenuItem, ListItemIcon, Link, Icon } from "@mui/material";
import AvatarProfile from "../AvatarProfile.js";
import { useProfileImage } from "src/utils/useProfileImage.js";
import { PhotoCameraOutlined, EditCalendarOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPageRoute } from "src/services";
import { formatSubscribers } from "src/utils/Utils.js";
import { useTheme } from "@emotion/react";

// Profile component oriented to show profile information
export function ProfileUser({ creator, viewer, creatorView, preview, selectedCalendar, sx }) {
    const profileImage = useProfileImage(creator);
    const navigate = useNavigate();
    const theme = useTheme();

    const EditMenu = () => {
        const [editOpen, setEditOpen] = useState(null);
        const handleOpenEditMenu = (event) => {
            setEditOpen(event.currentTarget);
        };
        const handleCloseEditMenu = () => {
            setEditOpen(null);
        };
        return (
            <>
                <Button
                    data-testid="profile-edit-button"
                    variant="filled"
                    onClick={handleOpenEditMenu}
                    sx={{ color: "text.primary", p: "0px" }}
                >
                    Edit
                </Button>
                <Menu
                    sx={{ mt: "32px" }}
                    id="edit-menu"
                    anchorEl={editOpen}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(editOpen)}
                    onClose={handleCloseEditMenu}
                >
                    <MenuItem
                        data-testid="edit-profile-avatar"
                        onClick={(e) => {
                            document.getElementById('avatar-input').click();
                            handleCloseEditMenu();
                        }}>
                        <ListItemIcon sx={{ minWidth: "26px !important" }}>
                            <PhotoCameraOutlined color="icon.primary" sx={{ fontSize: "1rem !important", color: "icon.primary" }} />
                        </ListItemIcon>
                        <Typography variant="h5" textAlign="center">Edit Avatar</Typography>
                    </MenuItem>
                    {creatorView && <MenuItem
                        data-testid="calendar-details-edit-button"
                        onClick={() =>
                            navigate(
                                getPageRoute("calendar", "CALENDAR_SETTINGS", {
                                    handle: selectedCalendar.handle,
                                    calendarId: selectedCalendar._id,
                                }),
                                { state: { calendar: selectedCalendar } }
                            )
                        }
                    >
                        <ListItemIcon sx={{ minWidth: "26px !important" }}>
                            <EditCalendarOutlined sx={{ fontSize: "1rem !important", color: "icon.primary" }} />
                        </ListItemIcon>
                        { selectedCalendar?.calendars ? <Typography variant="h5" textAlign="center">Edit Group</Typography> : <Typography variant="h5" textAlign="center">Edit Calendar</Typography> }
                    </MenuItem>}
                </Menu>
            </>
        )
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={sx}>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="8px" alignItems="center">
                    {viewer?._id === creator?._id && !preview ?
                        <AvatarProfile creator={creator} profileImage={profileImage} />
                        :
                        <Avatar size="small" src={profileImage} data-testid="avatar-profile-img" alt={creator?.name} />
                    }
                    {/* <Typography variant="subtitle1">{creator?.name}</Typography> */}
                    <Typography variant="subtitle2" color="text.secondary">
                        {formatSubscribers(creator?.subscribers)}
                    </Typography>
                </Box>
                {viewer?._id === creator?._id && !preview && <EditMenu />}
                {viewer?._id !== creator?._id && !preview &&
                    <Link
                        href={`https://docs.google.com/forms/d/e/1FAIpQLSfd3VCKjLMA4mTaD6wFXrZ5M8pFZQLbR8cbnJnQvzwVHWqVgA/viewform?usp=pp_url&entry.2130649886=${encodeURIComponent(window.location.href)}&entry.1077233496=${creator?.subscribers}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none"
                        sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
                    >
                        <Icon
                            baseClassName="material-symbols-outlined"
                            fontSize="small"
                            sx={{ color: `${theme.palette.text.secondary}`, verticalAlign: "middle" }}
                        >flag</Icon>
                        <Typography variant="subtitle2" color="text.secondary">Feedback</Typography>
                    </Link>
                }
            </Box>
        </Box >
    );
}
