import { IconButton, Menu, MenuItem, ListItemIcon, Typography } from "@mui/material";
import { MoreVertOutlined } from '@mui/icons-material';
import { useState } from 'react';

const ThreeDotsMenu = ({ threeDotsMenu, calendar }) => {
    const [threedotsOpen, setThreedotsOpen] = useState(null);
    const handleOpenThreeDotsMenu = (event) => {
      setThreedotsOpen(event.currentTarget);
    };
    const handleCloseThreeDotsMenu = () => {
      setThreedotsOpen(null);
    };

    return (
      <>
        <IconButton data-testid={`threedots-${calendar.name}`} onClick={handleOpenThreeDotsMenu}>
          <MoreVertOutlined />
        </IconButton>
        <Menu
          sx={{ mt: "32px" }}
          anchorEl={threedotsOpen}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(threedotsOpen)}
          onClose={handleCloseThreeDotsMenu}
        >
          {threeDotsMenu.map((menu, index) => (
            <MenuItem
              key={index}
              data-testid={`${menu.name}-button-${calendar.name}`}
              onClick={() => {
                menu.action(calendar)
                handleCloseThreeDotsMenu()
              }}
              sx={{ minWidth: "180px" }}
            >
              <ListItemIcon sx={{ minWidth: "26px !important" }}>{menu.icon({ ...{...menu.color ? {color: menu.color} : {}}, sx: { fontSize: "1.25rem" } })}</ListItemIcon>
              <Typography variant="h5" textAlign="center" {...menu.color ? {color: menu.color} : {}}>{menu.name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }


export default ThreeDotsMenu;