import { useState, useCallback, useMemo, useEffect } from "react";
import { useFormatDescription } from "src/utils/useFormatDescription.js";
import { useIsEventLive } from "src/utils/useIsEventLive.js";

/*
    Here we are creating a higher order component that will be used to compose the event data and UI
    for the Event component and the RecommendedCalendar component.
*/

const UIComponent = ({ Component, ...props }) => {
    const { event, calendar, setAddedCalendars, onSubscribeAction = null, eventIdSubscribed, position, isPastEvent, isEmbedded, type, setShowRecommendedCalendars } = props;
    const { jCal } = event;
    const eventId = useMemo(() => jCal?.[1]?.find((prop) => prop[0] === "uid")[3], [jCal]);
    const eventSummary = useMemo(() => jCal?.[1]?.find((prop) => prop[0] === "summary"), [jCal]);
    const eventDateStart = useMemo(() => jCal?.[1]?.find((prop) => prop[0] === "dtstart"), [jCal]);
    const eventDateEnd = useMemo(() => jCal?.[1]?.find((prop) => prop[0] === "dtend") || jCal?.[1]?.find((prop) => prop[0] === "dtstart"), [jCal]);  // In cases where events start time / end time is the same, the dtend property is missing. Default to dtstart.
    const eventLocation = useMemo(() => jCal?.[1]?.find((prop) => prop[0] === "location"), [jCal]);
    const eventDescription = useMemo(() => jCal?.[1]?.find((prop) => prop[0] === "description")?.[3], [jCal]);
    const homeTeamLogo = useMemo(() => jCal?.[1]?.find((prop) => prop[0] === "x-homelogourl")?.[3], [jCal]);
    const awayTeamLogo = useMemo(() => jCal?.[1]?.find((prop) => prop[0] === "x-awaylogourl")?.[3], [jCal]);
    const background = useMemo(() => jCal?.[1]?.find((prop) => prop[0] === "x-backgroundurl")?.[3], [jCal]);
    const eventTvSeasonEpisode = useMemo(() => /S(\d+)\sE(\d+)/.exec(eventLocation)?.[0] ?? false, [eventLocation]);
    const isEventLive = useIsEventLive(eventDateStart, eventDateEnd);

    const [ticketUrl, setTicketUrl] = useState("");
    const [shopUrl, setShopUrl] = useState("");
    const [watchUrl, setWatchUrl] = useState("");

    const constructImageURL = useCallback(() => {
        // Construct the expected image URL
        return `${process.env.REACT_APP_CLOUD_STORAGE_IMAGE_URL}/${calendar.handle}-${eventId}-eventImage.png`;
    }, [calendar.handle, eventId]);

    const [imageUrl, setImageUrl] = useState(constructImageURL);


    useEffect(() => {
        setImageUrl(constructImageURL());
    }, [jCal, calendar.handle, eventId, constructImageURL]);

    // This function will be triggered when the image fails to load.
    const handleImageError = () => {
        setImageUrl(null);
    };

    const formattedDescription = useFormatDescription({
        description: eventDescription,
        isEmbedded,
        setTicketUrl,
        setShopUrl,
        setWatchUrl,
    })

    const onSubscribeActionLocal = useCallback((subscribed) => {
        // Optimistically update the UI to reflect the subscription status.
        if (onSubscribeAction && setShowRecommendedCalendars && setAddedCalendars) {
            setShowRecommendedCalendars(false);
            setAddedCalendars((prev) => {
                if (subscribed) {
                    return [...prev, { ...calendar, event }];
                }
                return prev.filter((item) => item.event?.jCal[1]?.find((prop) => prop[0] === "uid")[3] !== eventId);
            });
            if ((!subscribed && eventIdSubscribed === eventId) || subscribed) {
                onSubscribeAction(eventId, subscribed);
            }
        } else if (onSubscribeAction) {
            onSubscribeAction(eventId, subscribed);
        }
    }, [onSubscribeAction, setShowRecommendedCalendars, setAddedCalendars, eventIdSubscribed, eventId, calendar, event]);

    return <Component
        position={position}
        eventLinks={{
            ticketUrl,
            shopUrl,
            watchUrl
        }}
        eventData={{
            eventId,
            eventSummary,
            eventLocation,
            eventDateStart,
            eventDateEnd,
            eventTvSeasonEpisode,
            formattedDescription,
            eventDescription,
            isPastEvent,
            isEventLive,
        }}
        eventUI={{
            homeTeamLogo,
            awayTeamLogo,
            background,
            imageUrl,
            handleImageError,
            setImageUrl,
            isSubscribed: eventIdSubscribed === eventId,
        }}
        {...props}
        onSubscribeAction={onSubscribeActionLocal}
    />;
}

export const EventComposer = (Component) => {
    return (props) => {
        return <UIComponent
            Component={Component}
            {...props} />;
    }
}