import React, { useState, useEffect } from 'react';
import { Button, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Box, Typography, Switch, Icon } from '@mui/material';
import { Twitter, LinkedIn, FacebookOutlined, WhatsApp, Telegram, IosShareOutlined, Link, Code } from "@mui/icons-material";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js";
import Reddit from 'src/assets/svg/reddit.js';
import Messenger from 'src/assets/svg/messenger.js';
import BottomDialog from './BottomDialog.js';
import CenterDialog from './CenterDialog.js';

const EmbeddedInformation = ({ handle, calendar }) => {
  const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
  const [showHorizontal, setShowHorizontal] = useState(false);
  const baseSrc = `${window.location.origin}/embedded/${handle}/${calendar?._id}`;
  const iframeVertical = `<iframe style="width: 300px; height: 100%; border: 1px solid #D9D9D9; border-radius: 8px;" src="${baseSrc}?direction=vertical"></iframe>`
  const iframeHorizontal = `<iframe style="width: 100%; height: 250px; border: 1px solid #D9D9D9; border-radius: 8px;" src="${baseSrc}?direction=horizontal"></iframe>`

  const handleClickToCopyEmbed = () => {
    let embedded;
    if (!showHorizontal) {
      embedded = `${iframeVertical}`
    } else {
      embedded = `${iframeHorizontal}`
    }
    navigator.clipboard.writeText(embedded)
      .then(() => {
        console.log('Text successfully written to clipboard');
        showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Code copied successfully.', 'success');
      })
      .catch(err => {
        showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'There was an error. Please try again.', 'error');
      });
  }

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" gap="1.5rem" sx={{ mt: 0.5 }}>
        <Typography variant="subtitle2" sx={{ textAlign: "center", textWrap: "balance" }}>
          Embed the entire calendar on your website either vertically or horizontally. Just copy the code below and paste it into your website.
        </Typography>

        <Box display="flex" flexDirection="column" alignItems="center" gap="1rem" sx={{ mt: 1 }}>
          <Typography variant="h5">Choose direction</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2">Vertical</Typography>
            <Switch
              checked={showHorizontal}
              onChange={() => setShowHorizontal(!showHorizontal)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography variant="body2">Horizontal</Typography>
          </Stack>
        </Box>

        <Box display="block" sx={{
          p: 3, color: "#ffffff", backgroundColor: "#000000",
          borderRadius: "4px", fontSize: "14px", lineHeight: "1.125rem",
          lineBreak: "anywhere",
          fontFamily: 'Courier New, monospace',
          maxWidth: "-webkit-fill-available", overflow: "hidden",
        }} >
          {!showHorizontal ? (
            iframeVertical
          ) : (
            iframeHorizontal
          )}
        </Box>

        <Button variant="outlined" size="shrinked"
          onClick={handleClickToCopyEmbed}
          startIcon={<Icon
            baseClassName="material-symbols-outlined"
            fontSize="small"
            sx={{ color: "#000000", verticalAlign: "middle", fontWeight: 300 }}
          >content_copy</Icon>
          }>
          Copy Code
        </Button>
      </Box>
    </>
  )
}


function ShareDialog({ calendar, handle, as = "IconButton", ...props}) {
  const { open: openProp = false, setOpen: setOpenProp = null } = props;
  const [open, setOpen] = useState(false);
  const currentUrl = encodeURIComponent(window.location.href);
  const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
  const theme = useTheme();
  const { isSmallScreen } = useDimensions();
  const [showEmbed, setShowEmbed] = useState(false);

  useEffect(() => {
    setShowEmbed(false)
  }, [open])

  useEffect(() => {
    setOpen(openProp)
  }, [openProp])

  const handleShowEmbedInformation = () => {
    setShowEmbed(true)
  }

  const handleClickToCopyLink = () => {
    const link = `${window.location.origin}/${handle}/${calendar._id}`;
    navigator.clipboard.writeText(link)
      .then(() => {
        console.log('Text successfully written to clipboard');
        showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Link copied successfully.', 'success');
      })
      .catch(err => {
        showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'There was an error. Please try again.', 'error');
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    (setOpenProp ?? setOpen)(false);
  };

  const ShareButtons = () => {
    return (
      <>
        <Stack direction="row" spacing={isSmallScreen ? "7.5%" : 3} sx={{ mt: { xs: 0, md: 1 }, justifyContent: { xs: "center", md: "flex-start" } }}>
          <Tooltip title="Share on Facebook">
            <Box display="flex" flexDirection="column" alignItems="center" gap="2px" width="61px">
              <IconButton
                component="a"
                href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookOutlined sx={{ fontSize: "28px" }} />
              </IconButton>
              <Typography variant="caption" color="text.primary">Facebook</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Share on Reddit">
            <Box display="flex" flexDirection="column" alignItems="center" gap="2px" width="61px">
              <IconButton
                component="a"
                href={`https://www.reddit.com/submit?url=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Reddit width="28px" height="28px" />
              </IconButton>
              <Typography variant="caption" color="text.primary">Reddit</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Share on Twitter">
            <Box display="flex" flexDirection="column" alignItems="center" gap="2px" width="61px">
              <IconButton
                component="a"
                href={`https://twitter.com/intent/tweet?url=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter sx={{ fontSize: "28px" }} />
              </IconButton>
              <Typography variant="caption" color="text.primary">Twitter</Typography>
            </Box>
          </Tooltip>

          <Tooltip title="Share on LinkedIn">
            <Box display="flex" flexDirection="column" alignItems="center" gap="2px" width="61px">
              <IconButton
                component="a"
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn sx={{ fontSize: "28px" }} />
              </IconButton>
              <Typography variant="caption" color="text.primary">LinkedIn</Typography>
            </Box>
          </Tooltip>
        </Stack>
        <Stack direction="row" spacing={isSmallScreen ? "7.5%" : 3} sx={{ mt: 1.5, justifyContent: { xs: "center", md: "flex-start" } }} >
          <Tooltip title="Share on WhatsApp">
            <Box display="flex" flexDirection="column" alignItems="center" gap="2px" width="61px">
              <IconButton
                component="a"
                href={`https://wa.me/?text=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsApp sx={{ fontSize: "26px" }} />
              </IconButton>
              <Typography variant="caption" color="text.primary">WhatsApp</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Share on Messenger">
            <Box display="flex" flexDirection="column" alignItems="center" gap="2px" width="61px">
              <IconButton
                component="a"
                href={`https://www.facebook.com/dialog/send?link=${currentUrl}&app_id=291494419107518&redirect_uri=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Messenger width="28px" height="28px" />
              </IconButton>
              <Typography variant="caption" color="text.primary">Messenger</Typography>
            </Box>
          </Tooltip>

          <Tooltip title="Copy Link">
            <Box display="flex" flexDirection="column" alignItems="center" gap="2px" width="61px">
              <IconButton onClick={handleClickToCopyLink}>
                <Link sx={{ fontSize: "28px" }} />
              </IconButton>
              <Typography variant="caption" color="text.primary">Copy Link</Typography>
            </Box>
          </Tooltip>

          <Tooltip title="Show Embed">
            <Box display="flex" flexDirection="column" alignItems="center" gap="2px" width="61px">
              <IconButton onClick={handleShowEmbedInformation}>
                <Code sx={{ fontSize: "28px" }} />
              </IconButton>
              <Typography variant="caption" color="text.primary">Embed</Typography>
            </Box>
          </Tooltip>

        </Stack >
      </>
    )
  }

  return (
    <>
      {{
        "IconButton": (
          <Tooltip title="Share">
            <IconButton size="small" onClick={handleClickOpen}>
              <IosShareOutlined sx={{ color: `${theme.palette.icon.primary}`, transform: 'translateY(-2px)' }} />
            </IconButton>
          </Tooltip>
        ),
        "Button": (
          <Button size="shrinked" variant="contained" onClick={handleClickOpen}>
            Share
          </Button>
        ),
        "Button-Small": (
          <Button size="small" variant="contained" onClick={handleClickOpen}>
            Share
          </Button>
        ),
      }[as]}
      {isSmallScreen ? (
        <BottomDialog open={open} handleClose={handleClose} hiddenCloseButton sx={{ height: "auto" }}>
          {!showEmbed ? (
            <ShareButtons />
          ) : (
            <EmbeddedInformation calendar={calendar} handle={handle} />
          )}
        </BottomDialog>
      ) : (
        <CenterDialog open={open} handleClose={handleClose} sx={{ height: "auto" }} dividers dialogTitle={!showEmbed ? "Spread the word" : "Embed this calendar"}>
          {!showEmbed ? (
            <>
              <ShareButtons sx={{ pb: 3 }} />
            </>
          ) : (
            <>
              <EmbeddedInformation calendar={calendar} handle={handle} />
            </>
          )}
        </CenterDialog>
      )}
    </>
  );
}

export default ShareDialog;
