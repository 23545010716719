import React, { useMemo, useEffect } from "react";
import { useState, useContext } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Typography,
  IconButton,
  Menu,
  Container,
  Tooltip,
  MenuItem,
  ListItemIcon,
  Icon,
  Collapse,
  Divider,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AuthContext } from "src/utils/AuthContext.js";
import { handleCustomerPortal } from "src/utils/Utils";
import { getPageRoute } from "src/services";
import StanzaLogo from "src/assets/svg/stanza-logo.js";
import { BookOutlined, MenuOutlined, InfoOutlined, ExpandLess, ExpandMore, Add, PersonOutlineOutlined, RocketLaunchOutlined, WorkspacePremiumOutlined, LoginOutlined, LogoutOutlined, BugReportOutlined, DeviceHubOutlined, LightModeOutlined, UpgradeOutlined } from '@mui/icons-material';
import { useDimensions } from "src/utils/useDimensions.js";
import { useTheme } from "@emotion/react";
import { throttle } from "lodash";
import { UpgradeBanner } from "../upgrades/UpgradeBanner.js";

function Navbar({ user }) {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const AppBarRef = React.useRef(null);
  const UpgradeBannerRef = React.useRef(null);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [openBlog, setOpenBlog] = useState(false);

  const { handleSignOut } = useContext(AuthContext);

  const loggedIn = useMemo(() => {
    return !!user
  }, [user]);

  // used to determine if My Plan is shown
  const hasActiveSubscription = useMemo(() => {
    if (user) return (!!user.hasValidSubscription || !!user.hasValidCreatorSubscription);
    return false
  }, [user]);

  // used to determine if Upgrade menu should be shown
  const needsUpgrade = useMemo(() => {
    if (user) return !user.hasValidSubscription || !user.hasValidCreatorSubscription;
    return false
  }, [user]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOutClick = async () => {
    await handleSignOut();
    navigate(getPageRoute("auth", "SIGNIN"));
  };

  const { isMediumScreen } = useDimensions();
  const navbarSmall = { boxShadow: "none !important" }

  useEffect(() => {
    const tabs = document.querySelector('.MuiTabs-root');
    const chatInterface = document.getElementById("chat-interface");
    const isInSearchPage = window.location.pathname === "/search";
    const target = isInSearchPage ? "search" : "window"
    let capturedScrollY_A = {
      'window': 0,
      "search": 0,
    }[target]
    let capturedScrollableHeight = {
      'window': document.scrollingElement.scrollHeight,
      "search": chatInterface?.scrollHeight,
    }[target]
    const offset = UpgradeBannerRef.current ? UpgradeBannerRef.current.clientHeight : 0;
    AppBarRef.current.style.top = `${offset}px`;

    const handleScroll = (isTabStuck, targetElement, { onScrollUp, onScrollDown }) => {
      handleCloseUserMenu();
      if (capturedScrollableHeight !== targetElement.scrollHeight) {
        capturedScrollY_A = targetElement.scrollTop;
        capturedScrollableHeight = targetElement.scrollHeight;
        return
      }
      // if at top of page, show navbar
      if (targetElement.scrollTop === 0) {
        return onScrollUp();
      }
      // if at bottom of page, don't show navbar
      if (targetElement.scrollHeight - targetElement.scrollTop === targetElement.clientHeight) {
        return onScrollDown();
      }
      if (!isTabStuck) return onScrollUp();
      const capturedScrollY_B = targetElement.scrollTop;
      const capturedScrollDiff = capturedScrollY_B - capturedScrollY_A;

      if (capturedScrollDiff < -30 && isTabStuck) { // if scrolling up and tab is stuck
        onScrollUp();
      } else if (capturedScrollDiff > 30 && isTabStuck) { // if scrolling down and tab is stuck
        onScrollDown();
      }

      capturedScrollY_A = targetElement.scrollTop; // reset last scroll position
      capturedScrollableHeight = targetElement.scrollHeight; // reset scrollable height
    };

    // For window scroll
    const handleScrollWindow = () => {
      if (!AppBarRef.current || !tabs) return
      document.scrollingElement.style.overscrollBehavior = "none";
      const clientBounding = tabs?.getBoundingClientRect();
      const isTabStuck = clientBounding?.top < (70 + offset);
      const onScrollUp = () => {
        if (AppBarRef.current.style.opacity === "1") return
        AppBarRef.current.style.opacity = "1";
        tabs.style.top = `${48 + offset}px`;
      }
      const onScrollDown = () => {
        if (AppBarRef.current.style.opacity === "0") return
        AppBarRef.current.style.opacity = "0";
        tabs.style.top = `${0 + offset}px`;
      }
      handleScroll(isTabStuck, document.scrollingElement, { onScrollUp, onScrollDown })
    }

    // For search page scroll
    const handleScrollSearch = () => {
      if (!AppBarRef.current || !tabs || !chatInterface) return
      chatInterface.style.overscrollBehavior = "none";
      const onScrollUp = () => {
        if (AppBarRef.current.style.opacity === "1") return
        AppBarRef.current.style.opacity = "1";
        AppBarRef.current.style.height = "56px";
        if (!chatInterface) {
          tabs.style.top = `${48 + offset}px`;
        } else {
          tabs.style.top = `${0 + offset}px`;
        }
      }
      const onScrollDown = () => {
        if (AppBarRef.current.style.opacity === "0") return
        AppBarRef.current.style.opacity = "0";
        AppBarRef.current.style.height = "0px";
        tabs.style.top = `${0 + offset}px`;
      }
      handleScroll(true, chatInterface, { onScrollUp, onScrollDown })
    }

    const functionToCall = {
      'window': handleScrollWindow,
      "search": handleScrollSearch,
    }[target]

    // Throttle scroll handler to improve performance
    const throttledHandleScroll = throttle(functionToCall, 100);

    if (!isInSearchPage) {
      window.addEventListener('scroll', throttledHandleScroll);
    } else if (isInSearchPage) {
      chatInterface?.addEventListener('scroll', throttledHandleScroll);
    }

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
      chatInterface?.removeEventListener('scroll', throttledHandleScroll);
    }
  }, [user?.needsConsumerCalendarUpgrade]);

  const commonItems = {
    "import": () => (<MenuItem
      component={RouterLink}
      to="/help/import"
      onClick={handleCloseUserMenu}
    >
      <ListItemIcon><Add sx={{ color: `${theme.palette.icon.primary}` }} /></ListItemIcon>
      <Typography variant="h5" textAlign="center">Import</Typography>
    </MenuItem>),
    "about": () => (<MenuItem
      component={RouterLink}
      to="https://stanzacreators.substack.com/about"
      target="_blank"
      onClick={handleCloseUserMenu}
    >
      <ListItemIcon><InfoOutlined fontSize="medium" sx={{ color: `${theme.palette.icon.primary}` }} /></ListItemIcon>
      <Typography variant="h5" textAlign="center">About</Typography>
    </MenuItem>),
    "upgrade": () => (
      <>
        <MenuItem
          component={RouterLink}
          onClick={() => setOpenUpgrade(!openUpgrade)}
        >
          <ListItemIcon>
            <UpgradeOutlined sx={{ color: `${theme.palette.icon.primary}` }} />
          </ListItemIcon>
          <Typography variant="h5" textAlign="center">Upgrade</Typography>
          {openUpgrade ? <ExpandLess sx={{ ml: "auto" }} /> : <ExpandMore sx={{ ml: "auto" }} />}
        </MenuItem>
        <Collapse in={openUpgrade} timeout="auto" unmountOnExit>
          <Box sx={{
            'li': {
              paddingLeft: 3.125
            }
          }}>
            {user && !user.hasValidSubscription && (
              <MenuItem onClick={() => {
                handleCloseUserMenu();
                navigate("/upgrade/plus");
              }}>
                <ListItemIcon><RocketLaunchOutlined sx={{ color: `${theme.palette.icon.primary}` }} /></ListItemIcon>
                <Typography variant="h5" textAlign="center">Stanza+</Typography>
              </MenuItem>
            )}
            {user && !user.hasValidCreatorSubscription && (
              <MenuItem onClick={() => {
                handleCloseUserMenu();
                navigate("/upgrade/creator");
              }}>
                <ListItemIcon><Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ color: `${theme.palette.icon.primary}` }}>editor_choice</Icon></ListItemIcon>
                <Typography variant="h5" textAlign="center">Creator</Typography>
              </MenuItem>
            )}
          </Box>
        </Collapse>
      </>
    ),
    "report": () => (<MenuItem component={RouterLink} to="https://forms.gle/ef95879BhcjwivYC6" target="_blank" data-testid="home-user-menu-report-issue">
      <ListItemIcon><BugReportOutlined sx={{ color: `${theme.palette.icon.primary}` }} /></ListItemIcon>
      <Typography variant="h5" textAlign="center">Report</Typography>
    </MenuItem>),
    "blog": () => (
      <>
        <MenuItem
          component={RouterLink}
          onClick={() => setOpenBlog(!openBlog)}
        >
          <ListItemIcon>
            <BookOutlined sx={{ color: `${theme.palette.icon.primary}` }} />
          </ListItemIcon>
          <Typography variant="h5" textAlign="center">Blog</Typography>
          {openBlog ? <ExpandLess sx={{ ml: "auto" }} /> : <ExpandMore sx={{ ml: "auto" }} />}
        </MenuItem>
        <Collapse in={openBlog} timeout="auto" unmountOnExit>
          <Box sx={{
            'a': {
              paddingLeft: 3.125
            }
          }}>
            <MenuItem
              component={RouterLink}
              to="https://stanzacal.substack.com"
              target="_blank"
              onClick={handleCloseUserMenu}
            >
              <ListItemIcon><LightModeOutlined sx={{ color: `${theme.palette.icon.primary}` }} /></ListItemIcon>
              <Typography variant="h5" textAlign="center">Spotlight</Typography>
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="https://stanzacreators.substack.com"
              target="_blank"
              onClick={handleCloseUserMenu}
              data-testid="home-user-creator-hub">
              <ListItemIcon><DeviceHubOutlined sx={{ color: `${theme.palette.icon.primary}` }} /></ListItemIcon>
              <Typography variant="h5" textAlign="center">Creator Hub</Typography>
            </MenuItem>
          </Box>
        </Collapse>
      </>
    ),
  }


  return (
    <>
      {user?.needsConsumerCalendarUpgrade && (
          <UpgradeBanner id="UpgradeBanner" ref={UpgradeBannerRef} />
      )}
      <AppBar ref={AppBarRef} id="AppBar" position="sticky" sx={{ backgroundColor: "white", zIndex: "4", transition: "opacity, height 0.1s linear", ...navbarSmall }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ margin: !isMediumScreen && "0rem 3rem", minHeight: { md: "48px" } }}>

            <StanzaLogo style={{ cursor: "pointer" }} onClick={() => navigate("/")} />

            <Box sx={{ flexGrow: 1 }}></Box>

            <Tooltip title="Search">
              <IconButton onClick={() => navigate('/search')} color="primary" size="small" sx={{ mr: 1 }} >
                <Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "middle" }}>
                  search
                </Icon>
              </IconButton>
            </Tooltip>

            {loggedIn ? (
              <Box display="flex" alignItems={isMediumScreen ? "end" : "center"} sx={{ flexGrow: 0 }}>
                <Button
                  data-testid="home-user-import"
                  variant="filled"
                  component={RouterLink}
                  to="/help/import"
                  sx={{ display: { xs: "none", md: "inline" }, color: `${theme.palette.text.primary}`, px: { md: "8px" } }}
                >
                  Import
                </Button>
                <Button
                  data-testid="home-user-library"
                  variant="filled"
                  component={RouterLink}
                  to="/library"
                  sx={{ display: { xs: "none", md: "inline" }, color: `${theme.palette.text.primary}`, px: { md: "8px" }, mr: { md: "2px" } }}
                >
                  Library
                </Button>


                <Tooltip title="Import">
                  <IconButton onClick={() => navigate('/help/import')} color="primary" size="small" sx={{ display: { xs: "inline-flex", md: "none" }, mr: 1 }} >
                    <Add fontSize="medium" sx={{ color: `${theme.palette.icon.primary}` }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Your library">
                  <IconButton onClick={() => navigate('/library')} color="primary" size="small" sx={{ display: { xs: "inline-flex", md: "none" }, mr: 1 }} >
                    <Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "middle" }}>
                      newsstand
                    </Icon>
                  </IconButton>
                </Tooltip>


                <Tooltip title="Open settings">
                  <IconButton data-testid="home-user-menu" onClick={handleOpenUserMenu} size="small" >
                    <PersonOutlineOutlined fontSize="medium" sx={{ color: `${theme.palette.icon.primary}` }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "36px", '.MuiMenu-paper': { minWidth: "166px" } }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {user.handle && [...(user.createdCalendars || []), ...(user.createdGroups || [])].length > 0 &&
                    <MenuItem
                      component={RouterLink}
                      to={`/${user.handle}`}
                      onClick={handleCloseUserMenu}
                    >
                      <ListItemIcon>
                        <Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ color: `${theme.palette.icon.primary}` }}>
                          location_home
                        </Icon>
                      </ListItemIcon>
                      <Typography variant="h5" textAlign="center">Your Profile</Typography>
                    </MenuItem>
                  }
                  {hasActiveSubscription && (
                    user?.lifetimeMember ? (
                      <MenuItem component={RouterLink} to="/upgrade/plus">
                        <ListItemIcon>
                          <WorkspacePremiumOutlined sx={{ color: `${theme.palette.icon.primary}` }} />
                        </ListItemIcon>
                        <Typography variant="h5" textAlign="center">Your plan</Typography>
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={handleCustomerPortal}>
                        <ListItemIcon>
                          <WorkspacePremiumOutlined sx={{ color: `${theme.palette.icon.primary}` }} />
                        </ListItemIcon>
                        <Typography variant="h5" textAlign="center">Your plan</Typography>
                      </MenuItem>
                    )
                  )}
                  {commonItems.import()}
                  <MenuItem
                    component={RouterLink}
                    to="/library"
                    onClick={handleCloseUserMenu}
                  >
                    <ListItemIcon>
                      <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}` }}>
                        newsstand
                      </Icon>
                    </ListItemIcon>
                    <Typography variant="h5" textAlign="center">Library</Typography>
                  </MenuItem>
                  <Divider component="li" />
                  {commonItems.about()}
                  {needsUpgrade && commonItems.upgrade()}
                  {commonItems.blog()}
                  {commonItems.report()}
                  <Divider component="li" />
                  <MenuItem to="/signin" onClick={handleSignOutClick} data-testid="home-user-menu-sign-out">
                    <ListItemIcon><LogoutOutlined color="delete" /></ListItemIcon>
                    <Typography variant="h5" textAlign="center" color="delete.main">Sign Out</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <>
                <Button
                  component={RouterLink}
                  to="/signin"
                  variant="filled"
                  sx={{ color: "black", display: "block", mr: 1 }}
                >
                  Sign In
                </Button>
                <Tooltip title="Open menu">
                  <IconButton onClick={handleOpenUserMenu} size="small">
                    <MenuOutlined fontSize="medium" sx={{ color: `${theme.palette.icon.primary}` }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "36px", '.MuiMenu-paper': { minWidth: "166px" } }}
                  id="menu-appbar-sign-out"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {commonItems.about()}
                  {/* {commonItems.import()} */}
                  {/* {commonItems.upgrade()} */}
                  {commonItems.blog()}
                  {commonItems.report()}
                  <Divider component="li" />
                  <MenuItem
                    component={RouterLink}
                    to="/signin"
                    onClick={handleCloseUserMenu}
                  >
                    <ListItemIcon ><LoginOutlined sx={{ color: `${theme.palette.icon.primary}` }} /></ListItemIcon>
                    <Typography variant="h5" textAlign="center" >Sign In</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar >
    </>
  );
}
export default Navbar;
