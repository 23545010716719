import { useRef, useCallback } from 'react';
import { getApiRoute } from 'src/services';
import { getEventsFromIcsFiles } from 'src/utils/Utils.js';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useRecommendedCalendars = ({ sourceCalendar }) => {
    const recommendedCalendarsRef = useRef(null);

    const fetchRecommendedCalendars = useCallback(async (prompt) => {
        const response = await axios.post(getApiRoute("chat", "DISCOVER"), {
            message: prompt,
        });

        if (response.data?.calendars) {
            const setFn = (subcomponents) => {
                // find calendars that are not the last calendar added and are not already in the list
                const calendarsSelected = [...(recommendedCalendarsRef.current ?? [])];
                const calendarsIdSelected = new Set(calendarsSelected.map((calendar) => calendar.calendarId));
                for (let i = 0; i < subcomponents.length; i++) {
                    if (subcomponents[i].calendarId !== sourceCalendar.id && !calendarsIdSelected.has(subcomponents[i].calendarId)) {
                        calendarsSelected.push(subcomponents[i]);
                        calendarsIdSelected.add(subcomponents[i].calendarId);
                    }
                }
                recommendedCalendarsRef.current = calendarsSelected;
            }
            return getEventsFromIcsFiles(response.data.calendars, setFn); // parse the ics files
        }
    }, [sourceCalendar?.id]);

    const discoverCalendars = useCallback(async () => {
        const prompt = `Share with me calendars similar to ${sourceCalendar.name} (${sourceCalendar.handle}), please don't add the same calendar handle (i.e. ${sourceCalendar.handle}) again.`;
        await fetchRecommendedCalendars(prompt);

        // After all fetch is complete, update state.

        const calendarsSelected = [...(recommendedCalendarsRef.current ?? [])];
        if (calendarsSelected.length % 2 !== 0) {
            // if the number of calendars is odd, remove the last calendar
            calendarsSelected.pop();
        }
        return calendarsSelected.slice(0, 6);
    }, [fetchRecommendedCalendars, sourceCalendar?.handle, sourceCalendar?.name, sourceCalendar?.tags]);


    // Manage the cache for the recommended calendars
    const { data: recommendedCalendars, isLoading } = useQuery([
        `recommended-calendars-${sourceCalendar?.name}-${sourceCalendar?.handle}-${(sourceCalendar?.tags || []).join(", ")}`], discoverCalendars, {
        enabled: !!sourceCalendar,
    })

    const noRecommendedCalendars = !recommendedCalendars || recommendedCalendars.length === 0;

    return { recommendedCalendars, isLoading, noRecommendedCalendars };
};