import { useEffect, useState, useMemo, useCallback } from "react";

export const useSessionStorage = (
    key,
    initialValue,
) => {

    const getValue = useCallback(() => {
        // Retrieve from sessionStorage
        const item = window.sessionStorage.getItem(key);
        if (item) {
            return JSON.parse(item);
        }
        window.sessionStorage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
    }, [key, initialValue]);

    const [storedValue, setStoredValue] = useState(getValue());

    useEffect(() => {
        setStoredValue(getValue());
    }, [key]);

    const setValue = useCallback((value) => {
        // Save state
        if (typeof value === 'function') {
            setStoredValue(prevState => {
                const valueToSet = value(prevState);
                // Save to sessionStorage
                window.sessionStorage.setItem(key, JSON.stringify(valueToSet));
                return valueToSet
            })
        } else {
            setStoredValue(value);
            // Save to sessionStorage
            window.sessionStorage.setItem(key, JSON.stringify(value));
        }
    }, [key]);

    const generateStatePropertyFunctions = useCallback((property) => {
        return {
            [`set${property[0].toUpperCase()}${property.slice(1)}`]: (value) => {
                setValue(prevState => {
                    const valueToSet = typeof value === 'function' ? value(prevState[property]) : value;
                    return { ...prevState, [property]: valueToSet }
                });
            }
        }
    }, [setValue]);

    const statePropertyFunctions = useMemo(() => {
        return Object.keys(storedValue).reduce((acc, property) => {
            return { ...acc, ...generateStatePropertyFunctions(property) }
        }, {})
    }, [storedValue, generateStatePropertyFunctions]);

    return [storedValue, setValue, statePropertyFunctions];
};