import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function removeFromGroup({ calendarId }) {
  await axios.post(
    getApiRoute("group", "REMOVE_CALENDARS"),
    { calendarId },
    { withCredentials: true }
  );
}

export function useRemoveFromGroup() {
  return useMutation(removeFromGroup);
}