import { useState, useContext } from "react";
import { Dialog, DialogTitle, DialogContent, FormControlLabel, DialogActions, TextField, Button, Avatar, Checkbox } from "@mui/material";
import axios from "axios";
import { getApiRoute } from "src/services";
import { AuthContext } from "src/utils/AuthContext.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { useProfileImage } from "src/utils/useProfileImage.js";


function ConsumerMailingListDialog({ open, handleClose, user, creator, onConfirm }) {
  const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
  const { refetchAuth } = useContext(AuthContext);
  const [email, setEmail] = useState(user.email);
  const [name, setName] = useState(user.name);
  const [isAgreed, setIsAgreed] = useState(false);

  const profileImage = useProfileImage(creator)

  async function handleSkipOrSubmit(skip) {
    if (!skip && (!name || !email || !isAgreed)) {
      showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Please fill out all fields.', 'error');
      return;
    }
    try {
      await axios.post(
        getApiRoute("auth", "SKIP_OR_SUBMIT_MAILINGLIST"),
        {
          handle: creator?.handle,
          skip: skip
        },
        { withCredentials: true }
      );
      refetchAuth()
      onConfirm();
    } catch (error) {
      showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, (error.response?.data?.message ?? error.message), 'error');
      console.error(error);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={{ margin: "auto", maxWidth: "400px" }} data-testid="consumer-mailing-list-dialog">
      <DialogTitle sx={{ textAlign: "center", mt: 2 }}>
        <Avatar src={`${profileImage}` + user.picture} sx={{ margin: "auto", width: "70px", height: "70px", mb: 1 }} />
        Join the Mailing List
      </DialogTitle>
      <DialogContent>
        <TextField
          data-testid="consumer-mailing-list-dialog-name"
          value={user.name}
          label="Name"
          fullWidth
          variant="standard"
          required
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          data-testid="consumer-mailing-list-dialog-email"
          value={user.email}
          label="Email"
          fullWidth
          variant="standard"
          required
          onChange={(event) => setEmail(event.target.value)}
          sx={{ mt: 2 }}
        />
        <FormControlLabel
          control={<Checkbox
            data-testid="consumer-mailing-list-dialog-agree"
            required
            checked={isAgreed}
            onChange={(event) => setIsAgreed(event.target.checked)}
          />}
          label={
            <span>
              I agree to the <a href={creator?.mailingList?.terms} target="_blank" rel="noopener noreferrer">terms</a> and
              <a href={creator?.mailingList?.privacy} target="_blank" rel="noopener noreferrer"> privacy policy</a>.
            </span>
          }
          sx={{
            mt: 2,
            '& .MuiFormControlLabel-label': { fontSize: "12px" },
            '& .MuiSvgIcon-root': { fontSize: "20px" }
          }}
        />
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button data-testid="consumer-mailing-list-dialog-skip" onClick={() => handleSkipOrSubmit(true)}>Skip</Button>
        <Button data-testid="consumer-mailing-list-dialog-submit" variant="contained" onClick={() => handleSkipOrSubmit(false)}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConsumerMailingListDialog;