import { useEffect, useState, useMemo, useCallback } from "react";

export const useIndexedDB = (
    dbName,
    storeName,
    key,
    initialValue,
    setError
) => {

    const getValue = () => {
        const request = window.indexedDB.open(dbName);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore(storeName);
        };
        request.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction([storeName], "readwrite");
            const store = transaction.objectStore(storeName);
            const getRequest = store.get(key);
            getRequest.onsuccess = () => {
                if (getRequest.result !== undefined) {
                    setStoredValue(getRequest.result);
                } else {
                    const addRequest = store.add(initialValue, key);
                    addRequest.onsuccess = () => {
                        setStoredValue(initialValue);
                    };
                    addRequest.onerror = (event) => {
                        console.error("Error adding value", event.target.errorCode);
                        throw new Error("Error adding value", event.target.errorCodee);
                    };
                }
            };
        };
        request.onerror = (event) => {
            console.error("Error opening database", event.target.errorCode);
            throw new Error("Error opening database", event.target.errorCode);
        };
    };

    const [storedValue, setStoredValue] = useState(initialValue);

    useEffect(() => {
        getValue();
    }, []);

    const setValue = (value) => {
        const valueToStore = typeof value === "function" ? value(storedValue) : value;
        const request = window.indexedDB.open(dbName);
        request.onsuccess = (event) => {
            const db = event.target.result;
            const transaction = db.transaction([storeName], "readwrite");
            const store = transaction.objectStore(storeName);
            const putRequest = store.put(valueToStore, key);
            putRequest.onsuccess = () => {
                setStoredValue(value);
            };
            putRequest.onerror = (event) => {
                console.error("Error setting value", event.target.errorCode);
                throw new Error("Error setting value", event.target.errorCode);
            };
        };
        request.onerror = (event) => {
            console.error("Error opening database", event.target.errorCode);
            throw new Error("Error opening database", event.target.errorCode);
        };
    };

    const generateStatePropertyFunctions = useCallback((property) => {
        return {
            [`set${property[0].toUpperCase()}${property.slice(1)}`]: (value) => {
                setValue(prevState => {
                    const valueToSet = typeof value === 'function' ? value(prevState[property]) : value;
                    return { ...prevState, [property]: valueToSet }
                });
            }
        }
    }, [setValue]);

    const statePropertyFunctions = useMemo(() => {
        return Object.keys(storedValue).reduce((acc, property) => {
            return { ...acc, ...generateStatePropertyFunctions(property) }
        }, {})
    }, [storedValue, generateStatePropertyFunctions]);

    return [storedValue, setStoredValue, statePropertyFunctions];
};
