import React from 'react';
import { Paper, Typography, Stack, Tooltip, IconButton, Button, Box } from '@mui/material';
import { EmailOutlined, ModeEditOutlineOutlined } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { getPageRoute } from 'src/services/Config.js';
import { useTheme } from '@emotion/react';

function CreatorMailingList({ creator }) {
  const theme = useTheme();
  const navigate = useNavigate();

  async function handleClick() {
    return navigate(
      getPageRoute(
        "upgrade",
        "UPGRADE_CREATOR_MAILING_LIST",
        {},
      )
    );
  };

  const BasePaperSx = {
    mt: 1.5,
    p: '6px 18px 6px 12px',
    borderRadius: "12px",
    background: `${theme.palette.paper.secondary}`
  }

  return (
    <>
      {
        (Number(creator.maxNumContactsCollected) === 0) ? (
          <Paper variant="outlined" sx={{ ...BasePaperSx, width: 'fit-content' }}>
            <Stack direction="row" spacing={1}>
              <EmailOutlined sx={{ fontSize: '18px' }} />
              <Typography variant="body2" component="div">
                Ask subscribers to join your mailing list.
                <Button variant="filled" onClick={handleClick} sx={{ mt: "-1px", ml: 1, p: 0, fontSize: "12px" }}>Learn more</Button>
              </Typography>
            </Stack>
          </Paper>
        ) : (
          <Paper variant="outlined" sx={{ ...BasePaperSx, width: { sm: "100%", md: "300px" }, maxWidth: "300px" }}>
            <Stack direction="column" spacing={0.125} >
              <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body2" component="div">
                  Contacts Collected
                </Typography>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    data-testid="creator-mailing-list-edit-button"
                    sx={{ mr: "-8px" }}
                  >
                    <ModeEditOutlineOutlined sx={{ color: `${theme.palette.icon.primary}`, fontSize: '14px' }} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h2" component="div" data-testid="subscribed-contacts-amount">
                  {creator?.numSubscribedContacts}/{creator?.maxNumContactsCollected}
                </Typography>
                <Button
                  component="a"
                  variant="outlined"
                  size="micro"
                  href={`https://docs.google.com/spreadsheets/d/${creator.mailingList.googleSpreadsheetId}/edit`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ mr: "-8px" }}
                >
                  View Contacts
                </Button>
              </Box>

            </Stack>
          </Paper>
        )}
    </>
  );
};

export default CreatorMailingList;