import * as React from 'react';
import SignIn from 'src/components/SignIn.js';
import BottomDialog from './BottomDialog.js';

const SignInDialog = ({
    open,
    handleClose,
}) => {

    return (
        <BottomDialog open={open} handleClose={handleClose} sx={{ height: "auto" }}>
            <SignIn dialog />
        </BottomDialog>

    )
}

export default SignInDialog;