import { Box, Typography } from "@mui/material";

const LiveBadge = ({ ...props }) => {
    return (
        <Box as="span" sx={{ display: "flex", position: "absolute", top: { xs: 6, md: 10 }, left: { xs: 6, md: 10 }, backgroundColor: "#FF0000", color: "white", padding: "0rem 0.25rem", borderRadius: "4px", zIndex: 2 }}>
            <Typography variant="caption" sx={{ fontWeight: 700 }} {...props}>LIVE</Typography>
        </Box>
    )
}

export default LiveBadge;