import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={36}
        height={37}
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 18.095C4.5 10.5706 10.395 5 18 5C25.605 5 31.5 10.5706 31.5 18.095C31.5 25.6194 25.605 31.19 18 31.19C16.634 31.19 15.3235 31.0096 14.0922 30.6711C13.8528 30.6052 13.5983 30.6238 13.3711 30.724L10.6916 31.9069C9.99064 32.2163 9.19943 31.7178 9.17594 30.9519L9.10242 28.5505C9.09338 28.2546 8.96027 27.9781 8.73976 27.7807C6.11298 25.4317 4.5 22.0307 4.5 18.095ZM13.8589 15.633L9.89331 21.9246C9.51274 22.5283 10.2549 23.2087 10.8235 22.7773L15.0832 19.5445C15.3713 19.3258 15.7695 19.3246 16.0589 19.5416L19.213 21.9072C20.1595 22.6171 21.5103 22.3679 22.1411 21.367L26.1068 15.0754C26.4872 14.4716 25.7451 13.7912 25.1765 14.2227L20.9168 17.4555C20.6286 17.6742 20.2305 17.6754 19.9411 17.4583L16.787 15.0928C15.8405 14.3829 14.4897 14.6321 13.8589 15.633Z"
            fill="black"
        />
    </svg>
);
export default SVGComponent;
