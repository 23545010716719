import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { getApiRoute } from "src/services";

function GoogleCalendarCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  useEffect(() => {
    const handleAuth = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");

      if (code) {
        try {
          // Send the authorization code to the back-end
          const response = await axios.post(
            getApiRoute("auth", "CONNECT_GOOGLE_CALENDAR"),
            { code },
            { withCredentials: true } // Include cookies if needed
          );

          if (response.status === 200) {
            showSnackBar(
              setSnackbarOpen,
              setSnackbarMessage,
              setSnackbarSeverity,
              "Google Calendar connected successfully!",
              "success"
            );
            navigate("/"); // Redirect to a desired page
          }
        } catch (error) {
          console.error("Error connecting Google Calendar:", error);
          const errorMessage = error.response?.data?.message || "Failed to connect Google Calendar.";
          showSnackBar(
            setSnackbarOpen,
            setSnackbarMessage,
            setSnackbarSeverity,
            errorMessage,
            "error"
          );
        }
      } else {
        // Handle the case where no code is present
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "No authorization code found.",
          "error"
        );
        navigate("/"); // Redirect back to home or show an error page
      }
    };

    handleAuth();
  }, [location, navigate, showSnackBar, setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h2>Connecting to Google Calendar...</h2>
    </div>
  );
}

export default GoogleCalendarCallback;