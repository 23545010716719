import React, { useState, useEffect, useContext } from 'react';
import { Avatar, useTheme } from '@mui/material';
import { getApiRoute } from "src/services";
import axios from "axios";
import { AuthContext } from "src/utils/AuthContext.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";

function AvatarProfile({ creator, profileImage }) {
  const theme = useTheme();
  const [imageUrl, setImageUrl] = useState(profileImage);
  const { refetchAuth } = useContext(AuthContext);
  const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();

  useEffect(() => {
    setImageUrl(profileImage);
  }, [creator.picture]);

  async function handleProfileImageUpload(event) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("profileImage", file);
    try {
      const response = await axios.post(
        getApiRoute("image", "UPDATE_PROFILE_IMAGE"),
        formData,
        { withCredentials: true }
      );
      setImageUrl(
        response.data.imageUrl + "?timestamp=" + Date.now()
      );
      refetchAuth()
      showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Image updated successfully.', 'success');
    } catch (error) {
      console.error(error);
      showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, error, 'error');
    }
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <Avatar size="small" data-testid="avatar-profile-img" src={imageUrl} alt={creator?.name} />
      <input
        data-testid="avatar-profile-input"
        type="file"
        accept="image/png, image/jpeg"
        id="avatar-input"
        style={{ display: 'none' }}
        onChange={handleProfileImageUpload}
      />
    </div>
  );
};

export default AvatarProfile;