import * as React from 'react';
import { IconButton, Dialog, DialogContent, Button, Box, Slide, DialogTitle } from "@mui/material";
import { Close } from '@mui/icons-material'

const Transition = React.forwardRef(function Transition(
    props,
    ref
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BottomDialog = ({
    open,
    handleClose,
    children,
    sx,
    dialogTitle,
    dividers,
    hiddenCloseButton = false,
    hiddenRectangle = false,
    ...props
}) => {

    const Rectangle = () => {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "8px",
                    bottom: "8px",
                    left: "50%",
                    right: "50%",
                    width: "32px",
                    height: "4px",
                    backgroundColor: "#79747E",
                    opacity: 0.4,
                    borderRadius: "100px",
                    transform: "translateX(-50%)",
                    zIndex: 0,
                }}
            />
        )
    }


    const CloseButton = () => {
        return (
            <IconButton
                edge="end"
                color="inherit"
                variant="outlined"
                onClick={handleClose}
                aria-label="close"
                sx={{ position: 'absolute', top: "22px", right: "26px" }}
            >
                <Close fontSize="small" />
            </IconButton>
        )
    }

    return (
        <Dialog
            fullScreen
            sx={{
                height: "393px", inset: "auto 0 0 0",
                '.MuiPaper-root': { borderRadius: "4px 4px 0px 0px" },
                ...sx
            }}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            {...props}
        >
            <DialogTitle sx={{ mt: hiddenCloseButton ? "0rem !important" : "0.75rem !important", p: 2 }} variant="h2">
                {dialogTitle}
            </DialogTitle>
            {!hiddenCloseButton &&
                <CloseButton />
            }
            <DialogContent dividers={dividers}>
                {!hiddenRectangle &&
                    <Rectangle />
                }
                {children}
            </DialogContent>
        </Dialog >

    )
}

export default BottomDialog;