import StepsScreen from "src/components/instructions/StepsScreen.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "src/utils/AuthContext.js";
import { LoadingButton } from "../../blocks/LoadingButton.js";
import { Typography } from "@mui/material";
import { getPageRoute } from "src/services";
import { UpgradeConsumerButton } from "../../upgrades/UpgradeConsumerButton.js";

const GoogleCalendarSteps = () => {
  const steps = [
    {
      title: "Connect your Google Calendar",
      description:
        "Authorize Stanza to update your Google Calendar in the background.",
    },
    {
      title: "Get updates across all devices",
      description:
        "Calendars you add will automatically show up as new calendars in your Google Calendar, across all your devices.",
    },
    {
      title: "Know when your calendar updates",
      description:
        "Updates are pushed to your Google Calendar with timestamps, so you'll know when your calendar last updated.",
    },
  ];

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const handleGoHome = () => {
    navigate(getPageRoute("home", "HOME"));
  };

  return (
    <StepsScreen title="How it works" steps={steps}>
      <Typography variant={"h2"} sx={{ textAlign: "left", mr: "auto" }}>
        This is a new Stanza+ feature
      </Typography>

      {user && !user.hasValidSubscription ? (
        <>
          <Typography
            variant={"subtitle2"}
            sx={{ textAlign: "left", mr: "auto", mt: 1 }}
            color="text.secondary"
          >
            Save time and unlock unlimited calendar subscriptions.
          </Typography>
          <UpgradeConsumerButton
            size="grown"
            variant="contained"
            color="primary"
            sx={{ mt: 2, height: "auto", width: "100%" }}
          >
            <div>
              TRY FREE FOR 1 WEEK
              <br />
              <span style={{ textTransform: "capitalize" }}>Pay $0 today</span>
            </div>
          </UpgradeConsumerButton>
          <Typography
            variant="subtitle2"
            sx={{
              pt: 1.5,
              margin: "auto",
              color: "text.secondary",
              mb: 4,
              width: "90%",
            }}
          >
            Cancel any time before trial ends. After trial, Stanza+ is $2.50/mo
            (billed annually).
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant={"subtitle2"}
            sx={{ textAlign: "left", mr: "auto", mt: 1 }}
            color="text.secondary"
          >
            Members save time and are the first to know.
          </Typography>
          <LoadingButton
            size="grown"
            variant="contained"
            color="primary"
            sx={{ mt: 2, height: "auto", width: "100%" }}
            onClick={() => navigate("/home")}
          >
            Get Started
          </LoadingButton>
        </>
      )}
    </StepsScreen>
  );
};

export default GoogleCalendarSteps;
