import {
    Card,
    CardMedia,
    CardHeader,
    Typography,
    Button,
    Box,
    CardActions,
} from "@mui/material";
import TvBadge from "./TvBadge.js";
import LazyImageComponent from "../LazyImageComponent.js";
import DefaultImage from "./DefaultImage.js";
import LiveBadge from "./LiveBadge.js";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js"
import { useNavigate } from "react-router-dom";
import { useProfileImage } from "src/utils/useProfileImage.js";
import { useEffect, useState, useContext, memo } from "react";
import { fetchAndCacheImage } from "src/utils/Utils.js";
import EventProfileBg from "./EventProfileBg.js";
import { formatSubscribers } from "src/utils/Utils.js";
import SubscribeButton from "../SubscribeButton.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { EventComposer } from "src/utils/EventComposer.js";

const RecommendedCalendar = ({ event, eventData, eventUI, showUpload, calendar,
    calendarId, type, position, onSubscribeAction, isEmbedded }) => {

    const {
        eventId,
        eventSummary,
        eventTvSeasonEpisode,
        formattedDescription,
        isEventLive,
        isPastEvent
    } = eventData
    const {
        homeTeamLogo,
        background,
        imageUrl,
        handleImageError,
    } = eventUI;

    const { user } = useContext(AuthContext);
    const theme = useTheme();
    const { isMediumScreen } = useDimensions();
    const navigate = useNavigate();
    const profileImageUrl = useProfileImage({ picture: `${calendar.handle}-profileImage.png` });
    const [profileImage, setProfileImage] = useState(null);
    const [loading, setLoading] = useState(true);
    // Check if the home team logo is related to calendar name
    const homeTeamLogoChecked = homeTeamLogo?.split("/").at(-1).includes(calendar.name) ? homeTeamLogo : null;

    useEffect(() => {
        const func = async () => {
            await fetchAndCacheImage(profileImageUrl, setProfileImage);
            setLoading(false);
        }
        func();
    }, [profileImageUrl]);

    return (
        <Card id="recommended-calendar-card" data-testid={`recommended-calendar-card-${position}`} variant="outlined" sx={{ width: "100%", border: "none", overflow: "visible" }}>
            <Box id="image-recommended-calendar-box" position="relative" onClick={() => navigate(`/${calendar.handle}`)} sx={{ cursor: 'pointer' }} >
                {((profileImage || homeTeamLogoChecked) && !loading) || loading ? (
                    <EventProfileBg loading={loading} profileImage={profileImage || homeTeamLogoChecked} calendarId={calendarId} eventSummary={eventSummary} height={"140px"} width="100%" />
                ) : imageUrl ? (
                    <LazyImageComponent>
                        <CardMedia
                            data-testid={`image-recommended-calendar-img-${calendarId}`}
                            style={{ borderRadius: "4px" }}
                            component="img"
                            height={"140"}
                            width="100%"
                            image={imageUrl}
                            onError={handleImageError}
                            loading="eager"
                            alt={
                                eventSummary
                                    ? eventSummary[3]
                                    : ""
                            }
                        />
                    </LazyImageComponent>
                ) : (background) ? (
                    <LazyImageComponent>
                        <CardMedia
                            data-testid={`image-recommended-calendar-img-${calendarId}`}
                            style={{ borderRadius: "4px" }}
                            component="img"
                            height={"140"}
                            width="100%"
                            image={background}
                            onError={handleImageError}
                            loading="eager"
                            alt={
                                eventSummary
                                    ? eventSummary[3]
                                    : ""
                            }
                        />
                    </LazyImageComponent>
                ) : (
                    <DefaultImage
                        height={"140px"}
                        data-testid={`image-recommended-calendar-img-${calendarId}`}
                        src=""
                        width="100%"
                        id={eventId || "0"}
                        position={position}
                    />
                )}
                {!!eventTvSeasonEpisode && (
                    <TvBadge data-testid={`calendar-recommended-calendar-tv-${position}`} seasonEpisode={eventTvSeasonEpisode} />
                )}
                {isEventLive && (<LiveBadge data-testid={`calendar-recommended-calendar-live-${position}`} />)}
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Button variant="text" onClick={() => navigate(`/${calendar.handle}`)} sx={{ pl: 0, display: "grid" }}>

                    <Typography variant="subtitle1" textTransform="none" data-testid="calendar-name" sx={{ overflow: "hidden", textOverflow: "ellipsis", textAlign: 'left' }}>
                        {calendar.name}
                    </Typography>

                </Button>
                <Typography variant="subtitle2" color="text.secondary" textTransform="none" sx={{ textWrap: "nowrap" }}>
                    {isMediumScreen ? formatSubscribers(calendar?.subscribers) : formatSubscribers(calendar?.subscribers, false)}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end", gap: "19px", justifyContent: "space-between" }}>
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    id="calendar-recommended-calendar-description"
                    sx={{ ...theme.clampLines(2), minHeight: "2.5em" }}
                    dangerouslySetInnerHTML={{
                        __html: formattedDescription,
                    }}
                />
                {isMediumScreen && (
                    <SubscribeButton
                        user={user}
                        calendar={calendar}
                        handle={calendar.handle}
                        onSubscribeAction={onSubscribeAction}
                        isEventCard={true}
                    />
                )}
            </Box>
            {!isMediumScreen && (
                <CardActions id={"calendar-recommended-calendar-add-container"} sx={{ p: 0, mt: 2 }}>
                    <SubscribeButton
                        user={user}
                        calendar={calendar}
                        handle={calendar.handle}
                        onSubscribeAction={onSubscribeAction}
                        isEventCard={true}
                    />
                </CardActions>
            )}
        </Card>
    )
}

export default memo(EventComposer(RecommendedCalendar));