import axios from "axios";
import { getApiRoute } from "src/services";
import { useQuery } from "@tanstack/react-query";

export async function getCalendar({ handle, calendarId }) {
  let response = { data: null };
  if (handle && calendarId) {
    response = await axios.get(
      getApiRoute("calendar", "GET_CALENDAR", {
        handle,
        calendarId,
      }),
      { withCredentials: true }
    );
  }
  return response.data;
}

export const useGetCalendar = (
  params = {
    handle: null,
    calendarId: null,
  },
  options = {}
) => {
  return useQuery(
    [`calendar-${params.handle}-${params.calendarId}`],
    () => getCalendar(params),
    {
      ...options,
      // Only retry if there was an error and it was not a 404
      retry: (failureCount, error) => {
        // If the error response is a 404, then don't retry
        if (error.response?.status === 404) {
          return 0;
        }
        return true;
      }
    }
  );
};
