import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingAnimation from 'src/components/blocks/LoadingAnimation.js';
import { AuthContext } from 'src/utils/AuthContext.js';
import { getPageRoute } from "src/services";

export function ProtectedRoute({ Component }) {
  const { user, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return (
      <LoadingAnimation />
    );
  }

  return user ? <Component user={user} /> : <Navigate to='/signin' />;
}

export function ProtectedCreatorUpgradeRoute({ Component }) {
  const { user, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return (
      <LoadingAnimation />
    );
  }

  return (!user.needsCreatorUpgrade) ? <Component user={user} /> : <Navigate to={getPageRoute("upgrade", "UPGRADE_CREATOR")} />;
}