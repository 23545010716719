import UAParser from "ua-parser-js";
import ActionCard from "src/components/blocks/ActionCard.js";
import { Box, Icon, Typography } from "@mui/material";
import Footer from "src/components/blocks/Footer.js";

/**
 * Higher Order Component to check if the user agent is embedded in an insecure environment for OAuth
 */
export const userAgentEmbeddedCheck = (Children) => {
  const uaParser = new UAParser();
  const parserResults = uaParser.getResult();

  const isAndroid = parserResults.os.name === "Android";
  const isEmbeddedInsecure =
    [
      "Instagram",
      "LinkedIn",
      "Snapchat",
      "Facebook",
      "TikTok",
      "Electron",
      "Twitter",
      "X",
    ].includes(parserResults.browser.name) ||
    ["inapp", "module", "mediaplayer"].includes(parserResults.browser.type);

  const whiteListConditions = [
    ["Twitter", "X"].includes(parserResults.browser.name) && !isAndroid,
  ];

  const isTwitter = ["Twitter", "X"].includes(parserResults.browser.name);

  if (
    isEmbeddedInsecure &&
    !whiteListConditions.some((condition) => condition)
  ) {
    return () => {
      return (
        <>
          <style>
            {`
        @keyframes vertical-slide {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(16px);
          }
          100% {
            transform: translateY(0);
          }
        }
        `}
          </style>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "100vh",
            }}
          >
            <div>
              <Box
                sx={{
                  margin: "auto",
                }}
              >
                <ActionCard
                  title={
                    <Typography variant="h2">
                      {parserResults.browser.name} has changed its browser
                      policies.
                    </Typography>
                  }
                  description={
                    <Box sx={{ mt: 2 }}>
                      To continue signing in, tap the menu
                      <br />
                      {!isTwitter ? "above" : "below"} and select 'Open in
                      browser'.
                    </Box>
                  }
                  iconName="perm_contact_calendar"
                  sx={{
                    top: "50vh",
                    transform: "translate(-50%, -75%)",
                    minWidth: "250px",
                    maxWidth: "250px",
                  }}
                  iconSx={{
                    fontWeight: "200 !important",
                  }}
                />
              </Box>
            </div>
            <Footer />

            <Icon
              baseClassName="material-symbols-outlined"
              sx={{
                fontWeight: 300,
                fontSize: "2rem",
                position: "fixed",
                ...{
                  ...(!isTwitter
                    ? {
                        top: "10px",
                        right: "12px",
                      }
                    : {
                        bottom: "10px",
                        right: "12px",
                        rotate: "180deg",
                      }),
                },
                animation: "vertical-slide 1s infinite",
                zIndex: 1,
              }}
            >
              arrow_upward
            </Icon>
          </div>
        </>
      );
    };
  }

  return Children;
};
