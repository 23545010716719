import { useQuery } from '@tanstack/react-query';
import { useState, useEffect, useContext, useMemo } from 'react';
import { getUpcomingEvents, getEventsFromIcsFiles } from "src/utils/Utils.js";
import { useGetUserEvents, useGetTrendingTags } from "src/services";
import { AuthContext } from "src/utils/AuthContext.js";
import { useCalendarSections } from "src/services/queries/useGetCalendarSections.js";

export const useTrendingContent = () => {
    const { user } = useContext(AuthContext);
    const [initialLoad, setInitialLoad] = useState(false);
    const [addedCalendars, setAddedCalendars] = useState([]);
    const [isTagsContentLoading, setIsTagsContentLoading] = useState(true);
    const [isAddedLoading, setIsAddedLoading] = useState(true);
    const isUser = Boolean(user);

    const { data: dataUserEvents, refetch: refetchUserEvents} = useGetUserEvents(
        {
            user,
        },
        {
            enabled: isUser,
        }
    );
    useEffect(() => {
        if (isUser) {
            refetchUserEvents();
        }
    }, [isUser]);
    const { data: dataTrendingTags } = useGetTrendingTags(null, {});
    // Trending tag events 
    const trendingTags = useMemo(() => Object.keys(dataTrendingTags ?? {}), [dataTrendingTags]);
    const { data: trendingTagEvents, refetch: refetchTrendingTagEvents } = useQuery(['trending-tag-events'], async () => {
        setInitialLoad(false);
        if (trendingTags.length === 0) return [];
        const tagEventsAndTerms = {}
        const promises = [];
        for (const tag in dataTrendingTags) {
            const callback = (events) => tagEventsAndTerms[tag] = [...(tagEventsAndTerms[tag] ?? []), ...events]
            promises.push(getEventsFromIcsFiles(dataTrendingTags[tag].calendars, callback));
        }
        await Promise.all(promises);
        for (const tag in tagEventsAndTerms) {
            tagEventsAndTerms[tag] = {
                events: getUpcomingEvents(tagEventsAndTerms[tag], 100, true),
                searchTerms: dataTrendingTags[tag].searchTerms
            }
        }
        setIsTagsContentLoading(false)
        return tagEventsAndTerms;
    }, {
        initialData: {},
        enabled: trendingTags.length > 0,
    });

    const { data: upcomingAddedEvents, refetch: refetchUpcomingAddedEvents } = useQuery([`upcoming-added-events`, dataUserEvents], async () => {
        setInitialLoad(false);
        setAddedCalendars(dataUserEvents)
        if (dataUserEvents.length === 0) {
           setIsAddedLoading(false)
           return [];
        }
        let addedEvents = [];
        const callback = (events) => addedEvents = [...addedEvents, ...events]
        await getEventsFromIcsFiles(dataUserEvents, callback);
        setIsAddedLoading(false)
        return getUpcomingEvents(addedEvents, 100, true);
    }, {
        initialData: [],
        enabled: !!dataUserEvents,
        refetchOnMount: true,
    });

    useEffect(() => {
        if (trendingTags?.length > 0 && Object.keys(trendingTagEvents)?.length === 0) {
            refetchTrendingTagEvents()
        } else if (Object.keys(trendingTagEvents)?.length !== 0) {
            setIsTagsContentLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trendingTags]);

    useEffect(() => {
        if (dataUserEvents) {
            refetchUpcomingAddedEvents()
        }
    }, [dataUserEvents]);

    const { data: calendarSections, isLoading: isCalendarSectionsLoading } = useCalendarSections();

    return {
        refetchUserEvents,
        addedCalendars,
        setAddedCalendars,
        isAddedLoading,
        upcomingAddedEvents,
        initialLoad,
        setInitialLoad,
        isTrendingLoading: isTagsContentLoading || isCalendarSectionsLoading,
        trendingTagEvents,
        calendarSections,
    };
}
