import { useLocation } from "react-router-dom";
import { useSnackbarContext } from "./SnackbarContext.js";
import { usePrevious } from "./usePrevious.js";
import { useEffect } from "react";

export const NavigationManager = () => {
    const location = useLocation();
    const previusPathname = usePrevious(location.pathname);
    const { setSnackbarOpen } = useSnackbarContext();

    useEffect(() => {
        if (previusPathname !== location.pathname) {
            setSnackbarOpen(false); // Close snackbar on route change
        }
    }, [location.pathname, previusPathname, setSnackbarOpen]);

    return null;
}
