import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={34}
        height={39}
        viewBox="0 0 34 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.985 31.27c-2.306 0-4.529.406-6.67 1.219-2.14.813-4.163 2.075-6.065 3.788v.339a.622.622 0 0 0 .154.086c.051.02.109.029.173.029h24.808c.064 0 .118-.01.163-.029a.712.712 0 0 0 .144-.086v-.135c-1.782-1.692-3.766-2.984-5.952-3.875a17.74 17.74 0 0 0-6.755-1.337ZM2.269 34.922c1.895-1.861 4.109-3.314 6.64-4.357C11.443 29.522 14.14 29 17 29c2.861 0 5.55.522 8.065 1.566a21.202 21.202 0 0 1 6.666 4.357V7.154a.588.588 0 0 0-.193-.423.588.588 0 0 0-.423-.192H2.885a.588.588 0 0 0-.423.192.588.588 0 0 0-.193.423v27.77ZM17 23.723c-1.772 0-3.274-.616-4.508-1.85-1.233-1.233-1.85-2.736-1.85-4.507 0-1.772.617-3.275 1.85-4.508 1.234-1.233 2.736-1.85 4.508-1.85 1.772 0 3.274.617 4.508 1.85 1.233 1.233 1.85 2.736 1.85 4.508 0 1.771-.617 3.274-1.85 4.507-1.234 1.234-2.736 1.85-4.508 1.85Zm.012-2.269c1.136 0 2.099-.4 2.89-1.198.79-.8 1.186-1.766 1.186-2.902 0-1.136-.399-2.1-1.198-2.89-.799-.791-1.766-1.187-2.902-1.187-1.136 0-2.099.4-2.89 1.198-.791.8-1.187 1.767-1.187 2.902 0 1.136.4 2.1 1.199 2.89.799.792 1.766 1.187 2.902 1.187ZM2.885 39c-.785 0-1.461-.285-2.03-.854C.284 37.576 0 36.9 0 36.116V7.153c0-.784.285-1.461.854-2.03.57-.57 1.246-.854 2.03-.854h3.693V2.023c0-.35.117-.647.353-.89.235-.242.528-.363.88-.363.366 0 .672.12.918.363.245.243.368.54.368.89V4.27h15.885V1.99A1.183 1.183 0 0 1 26.189.77c.357 0 .655.116.894.348.24.233.36.522.36.867V4.27h3.672c.785 0 1.461.284 2.03.854.57.569.855 1.246.855 2.03v28.961c0 .785-.285 1.462-.854 2.031-.57.57-1.246.854-2.03.854H2.884Z"
            fill="#1C1B1F"
        />
    </svg>
);
export default SVGComponent;