export const TimelineEmbeddedStyles = () => {
    return (
        <style>
            {`
    .MuiIconButton-root:link {
    color: #1C1B1F !important;
    }
    .events-embedded-container[data-source="webcomponent"] {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    overscroll-behavior: none;
    background-color: #ffffff;
    }
    .events-embedded-container[data-source="iframe"] {
    position: relative;
    width: 100vw;
    height: 100vh;
    overscroll-behavior: none;
    background-color: #ffffff;
    }
    *::-webkit-scrollbar {
    display: none;
    }
    #event-card {
    border-bottom: 0.5px solid #D9D9D9;
    border-radius: 4px;
    padding-bottom: 26px;
    background-color: transparent;
    }
    [data-testid="calendar-name"] {
    margin-right: 12px;
    }
    [data-testid="add-to-group-confirm"], [data-testid="add-to-calendar-button"]{
    text-wrap: nowrap;
    }
    .scroll-button {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    transition: inset 0.3s ease-in-out;
    }
    .scroll-button-top {
    top: var(--scroll-button-top-inset);
    --scroll-button-top-inset: -70px;
    }
    .scroll-button-bottom {
    bottom: var(--scroll-button-bottom-inset);
    --scroll-button-bottom-inset: -70px;
    }
    .MuiPaper-outlined {
    border: none;
    }
    #image-upload-button {
    display: none;
    }
    /* Vertical mode */
    .v-mode {
    overflow-y: auto;
    overflow-x: hidden;
    }
    .v-mode .scroll-button-top {
    border-top: none !important;
    }
    .v-mode .scroll-button-bottom {
    border-bottom: none !important;
    }
    .v-mode #calendar-card-box {
    margin-top: 16px;
    border-bottom: 0.5px solid #D9D9D9;
    }
    .v-mode #event-navigation-container {
    padding: 16px 16px 0px 16px;
    margin-top: 0px;
    margin-bottom: -32px;
    }
    .v-mode #image-event-box :is(div, img, #img-loader) {
    height: 100px;
    max-height: 100px;
    }
    .v-mode #team-logo {
    height: 38px !important;
    }
    .v-mode #team-logo:first-child {
    margin-right: 20% !important;
    }
    .v-mode #calendar-event-title span {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-wrap: pretty;
    }
    .v-mode #calendar-event-location {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-wrap: pretty;
    }
    /* Horizontal mode */
    .h-mode {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    }
    .h-mode #loader {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    }
    .h-mode #loader > div {
     height: 100%;
    }
    .h-mode .scroll-button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    transition: inset 0.3s ease-in-out;
    }
    .h-mode .scroll-button-top {
    left: var(--scroll-button-top-inset);
    right: auto;
    --scroll-button-top-inset: -70px;
    transform: rotate(270deg);
    border-top: none !important;
    }
    .h-mode .scroll-button-bottom {
    right: var(--scroll-button-bottom-inset);
    left: auto;
    --scroll-button-bottom-inset: -70px;
    transform: rotate(270deg);
    border-bottom: none !important;
    }
    .h-mode #calendar-card-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-right: 0.5px solid #D9D9D9;
    padding: 16px 16px 24px 16px;
    min-width: fit-content;
    }
    .h-mode #calendar-card-box button {
    margin-left: auto;
    margin-right: auto;
    }
    .h-mode #avatar-profile-img {
    margin: auto;
    flex: 0 1 auto;
    height: 35%;
    width: auto;
    aspect-ratio: 1/1;
    }
    .h-mode :is(#see-more-section, #alert-no-upcoming) {
    border: none;
    min-width: 235px;
    text-align: center;
    margin: 16px 0rem auto 0rem;
    border-bottom: none !important;
    border-right: 0.5px solid #D9D9D9 !important;
    height: 100%;
    padding-top: 16px;
    }
    .h-mode #calendar-not-found {
    border: none;
    min-width: 100%;
    min-height: auto;
    text-align: center;
    margin: auto;
    transform: translateY(0%) !important;
    }
    .h-mode #calendar-not-found button {
    width: auto;
    }
    .h-mode #event-navigation-container {
    flex: 1 1 0%;
    min-width: 240px;
    padding: 16px 0px 0px 16px;
    margin-top: -16px;
    margin-bottom: -32px;
    }
    .h-mode #event-navigation-container > div {
    border-right: 0.5px solid #D9D9D9;
    padding-right: 16px;
    min-height: 100%;
    }
    .h-mode #event-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    border-bottom: none;
    }
    .h-mode #image-event-box, .h-mode #image-event-box :not(#img-loader) div {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 72px;
    max-height: 38vh;
    }    
    .h-mode #image-event-box :is(#img-loaded, #img-loader) {
    flex: 1 1 auto;
    height: 72px !important;
    max-height: 38vh;
    }
    .h-mode #team-logo {
    height: 34px !important;
    position: absolute !important;
    }
    .h-mode #team-logo:first-child {
    margin-right: 0px !important;
    left: 4px;
    }
    .h-mode #team-logo:last-child {
    right: 4px;
    }
    .h-mode #calendar-event-title {
    flex: none;
    }
    .h-mode #calendar-event-title[data-event*="upcoming"] span {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-wrap: pretty;
    }
    .h-mode #calendar-event-title[data-event*="past"] span {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-wrap: pretty;
    }
    .h-mode .MuiCardContent-root {
    padding: 0.25rem;
    flex: 0 1 auto;
    }
    .h-mode #calendar-event-stack {
    margin-bottom: 0.5rem;
    }
    #calendar-event-description {
    display: none;
    }
    .h-mode #calendar-event-stack ~ div {
    margin-bottom: 0.5rem;
    }
    .h-mode #calendar-event-location[data-event*="upcoming"] {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: none;
    text-wrap: pretty;
    }
    .h-mode #calendar-event-location[data-event*="past"]{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: none;
    text-wrap: pretty;
    }
    .h-mode #calendar-event-add-container {
    padding-bottom: 2rem;
    margin-top: 0px;
    }
    `}
        </style>
    )
}
