import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      style={{
        mixBlendMode: "overlay",
      }}
    >
      <mask
        id="mask0_344_5476"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={48}
        height={48}
      >
        <rect width={48} height={48} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_344_5476)">
        <path
          d="M5.05005 22.8652C5.10392 21.2088 5.47123 19.4395 6.152 17.5575C6.83277 15.6754 7.70263 14.0177 8.7616 12.5844C10.6436 14.269 12.0116 15.9876 12.8654 17.7402C13.7193 19.4927 14.2372 21.2011 14.4192 22.8652H5.05005ZM33.5807 22.8652C33.7628 21.2011 34.2711 19.5023 35.1057 17.769C35.9403 16.0357 37.3179 14.3267 39.2384 12.6421C40.2974 14.0626 41.1672 15.7107 41.848 17.5863C42.5288 19.4619 42.8961 21.2216 42.95 22.8652H33.5807ZM8.7616 35.3267C7.70263 33.8933 6.83917 32.2504 6.1712 30.3978C5.50327 28.5452 5.12955 26.7908 5.05005 25.1344H14.4192C14.2372 26.7985 13.7193 28.4888 12.8654 30.2055C12.0116 31.9222 10.6436 33.6292 8.7616 35.3267ZM39.2384 35.3267C37.3179 33.6292 35.9403 31.9222 35.1057 30.2055C34.2711 28.4888 33.7628 26.7985 33.5807 25.1344H42.95C42.8961 26.778 42.5288 28.5292 41.848 30.3882C41.1672 32.2472 40.2974 33.8933 39.2384 35.3267ZM16.7384 22.8652C16.5359 20.8703 15.9705 18.8966 15.0423 16.944C14.1141 14.9915 12.5269 12.9767 10.2808 10.8998C11.727 9.39724 13.5064 8.0889 15.6192 6.9748C17.7321 5.8607 20.1475 5.21904 22.8654 5.0498V22.8652H16.7384ZM25.1346 22.8652V5.0498C27.8525 5.21904 30.273 5.8607 32.3961 6.9748C34.5192 8.0889 36.3102 9.39724 37.7692 10.8998C35.4974 12.9511 33.8955 14.9594 32.9634 16.9248C32.0314 18.8902 31.4641 20.8703 31.2616 22.8652H25.1346ZM22.8654 42.9497C20.0064 42.7805 17.5558 42.1273 15.5135 40.9901C13.4712 39.8529 11.727 38.533 10.2808 37.0305C12.5526 34.9664 14.1461 32.9728 15.0615 31.0497C15.9769 29.1266 16.5359 27.1549 16.7384 25.1344H22.8654V42.9497ZM25.1346 42.9497V25.1344H31.2616C31.4641 27.1549 32.0314 29.1331 32.9634 31.069C33.8955 33.0049 35.4974 35.0048 37.7692 37.0689C36.3102 38.5715 34.5512 39.8849 32.4922 41.0093C30.4333 42.1337 27.9807 42.7805 25.1346 42.9497Z"
          fill="#1C1B1F"
        />
      </g>
    </g>
  </svg>
);
export default SVGComponent;
