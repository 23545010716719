import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function deleteEvent({ calendarId, eventId }) {
  await axios.post(
    getApiRoute("event", "DELETE_EVENT", { calendarId, eventId }),
    {},
    { withCredentials: true }
  );
}

export function useDeleteEvent() {
  return useMutation(deleteEvent);
}
