import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function importCalendar({ handle, link, name, description, tags }) {
  return await axios.post(
    getApiRoute("calendar", "CREATE_CALENDAR"),
    { handle, link, name, description, tags },
    { withCredentials: true }
  );
}

export function useImportCalendar() {
  return useMutation(importCalendar);
}
