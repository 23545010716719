import React, { useContext, useState } from 'react';
import { Typography, Box, Button, Tooltip, IconButton, Icon, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/utils/AuthContext.js';
import MuxPlayer from '@mux/mux-player-react';
import { useDimensions } from "src/utils/useDimensions.js"
import Navbar from '../blocks/Navbar.js'
import Footer from '../blocks/Footer.js';
import { useTheme } from '@emotion/react';
import { EditCalendarOutlined, ContactSupportOutlined } from "@mui/icons-material";

function HowToImport() {
    const user = useContext(AuthContext).user;
    const navigate = useNavigate();
    const theme = useTheme();
    const { isMediumScreen } = useDimensions();
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar user={user} />
            <Box sx={{ margin: '0 auto', maxWidth: { xs: '100%', sm: "600px" }, minWidth: { sm: "600px" }, flexGrow: 1, p: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1, mt: { md: 3 } }}>
                    <Typography as="h1" variant="title" >
                        Import a New Calendar
                    </Typography>
                    <Tooltip title="Support">
                        <IconButton
                            component="a"
                            href={`https://stanzacreators.substack.com/p/getting-started`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ContactSupportOutlined fontSize="small" sx={{ color: `${theme.palette.text.secondary}` }} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Typography variant="body1" sx={{ mb: 3 }}>
                    Stanza gives your calendars a home — a place to see what's upcoming and subscribe to your events. 
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                    We'll also update your subscribers' calendars as you add or edit events.
                </Typography>

                <Typography as="h1" variant="title" sx={{ mb: 1, mt: { md: 3 } }}>
                    Next Steps
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 3 }}>
                    To import a new calendar:
                </Typography>

                <Box display="flex" flexDirection="column" gap={2} mb={4}>
                    <Box display="flex" alignItems="flex-start">
                        <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, mr: "10px", fontWeight: 300 }}>
                        monitoring
                        </Icon>
                        <Typography variant="body1" sx={{ textAlign: "left" }}>
                        Pick a SEO-friendly calendar name and description.
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="flex-start">
                        <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, mr: "10px", fontWeight: 300 }}>
                        edit
                        </Icon>
                        <Typography variant="body1" sx={{ textAlign: "left" }}>
                        Create events in your calendar app of choice.
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="flex-start">
                        <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, mr: "10px", fontWeight: 300 }}>
                        link
                        </Icon>
                        <Typography variant="body1" sx={{ textAlign: "left" }}>
                        Have your .ics link handy. Watch video below.
                        </Typography>
                    </Box>
                </Box>
                <Button data-testid="help-import-go" size="shrinked" variant="contained" color="primary" onClick={() => navigate('/import')} sx={{ width: "100%", marginBottom: 5 }} >
                    I'm Ready
                </Button>
                <Box sx={{ mb: { md: 5 }} } {...{
                    minWidth: "100%", maxWidth: "100%"
                }}>
                    {!isVideoLoaded && (
                        <Skeleton data-testid="video-loading" variant="rectangular" sx={{ width: "100%", height: "100%", aspectRatio: "16/9" }} />
                    )}
                    <MuxPlayer
                        streamType="on-demand"
                        data-testid="video-loaded"
                        playbackId="KZKa1HW84C5w00jbz8Q1QNQom5rWVaGjWmylLK00LQRXI"
                        metadataVideoTitle="Stanza - How to Import Calendar"
                        metadataViewerUserId={user?.email ?? "anonymous"}
                        accent-color="#000000"
                        style={{
                            display: isVideoLoaded ? "block" : "none",
                            aspectRatio: "16/9",
                        }}
                        onCanPlay={() => setIsVideoLoaded(true)}
                        onLoadedMetadata={() => setIsVideoLoaded(true)}
                    />

                </Box>
            </Box>
            <Footer />
        </div >
    );
}

export default HowToImport;
