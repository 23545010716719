import axios from "axios";
import { getApiRoute } from "src/services";
import { useQuery } from "@tanstack/react-query";
import { getEventsFromIcsFiles } from "src/utils/Utils";
// import { CALENDAR_SECTIONS_MOCK } from "src/mocks/calendar.js";


export async function getCalendarSections() {
  let response = { data: null };
  try {
    response = await axios.get(getApiRoute("home", "GET_CALENDARS_SECTIONS"));
    // response = CALENDAR_SECTIONS_MOCK; // Commented out mock data line
  } catch (error) {
    console.error('Error fetching calendar sections:', error);
    return null;
  }
  
  // parse calendars for each section
  const calendarsToAppend = {};
  
  for (let section in response.data) {
    if (!response.data[section].calendars) {
      continue; // Skip sections without calendars
    } 

    calendarsToAppend[section] = { calendars: [] };

    const setFn = (subcomponents) => {
      const calendarsResolved = [...calendarsToAppend[section]?.['calendars'] ?? []];
      const calendarsIdResolved = new Set(calendarsResolved.map((calendar) => calendar.calendarId));
      for (let i = 0; i < subcomponents.length; i++) {
        if (!calendarsIdResolved.has(subcomponents[i].calendarId)) {
          calendarsResolved.push(subcomponents[i]);
          calendarsIdResolved.add(subcomponents[i].calendarId);
        }
      }
      calendarsToAppend[section] = { calendars: [] };
      calendarsToAppend[section]['calendars'] = calendarsResolved;
    };

    await getEventsFromIcsFiles(response.data[section].calendars, setFn); // parse ics files

    // append to response if it has calendars else remove section
    if (calendarsToAppend[section]['calendars'].length > 0) {
      response.data[section].calendars = calendarsToAppend[section]['calendars'];
    } else {
      delete response.data[section];
    }

  }
  return response.data;
}

export const useCalendarSections = (
  options = {}
) => {
  return useQuery([`calendar-sections`], () => getCalendarSections(), {
    ...options,
  });
};
