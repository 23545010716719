import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button } from "@mui/material";
import { LoadingButton } from "../LoadingButton.js";

function DeleteGroupDialog({ open, handleClose, handleDelete, deleteText, deleteMessage, setDeleteText, isLoading }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm deleting your group</DialogTitle>
      <DialogContent>
        <DialogContentText>
          All calendars within this group will be deleted. You cannot undo this action.
        </DialogContentText>
        <TextField
          data-testid="group-delete-dialog-input"
          autoFocus
          value={deleteText}
          label="Type DELETE to confirm"
          fullWidth
          variant="standard"
          onChange={(event) => setDeleteText(event.target.value)}
          helperText={deleteMessage ? deleteMessage : ""}
          sx={{ mt: 2 }}
          error
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton data-testid="group-delete-dialog-confirm-button" onClick={handleDelete} loading={isLoading}>Continue</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
  
export default DeleteGroupDialog;