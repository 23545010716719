export const customEvent = ({
  name,
  label,
  category,
  action = "click",
  data = {},
}) => {
  if (!window.gtag) return;
  if (process.env.NODE_ENV !== "production") return;
  const newData = {
    date: new Intl.DateTimeFormat("en-US", {
      dateStyle: "full",
      timeStyle: "long",
      timeZone: "America/Los_Angeles",
    }).format(new Date()),
    userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...data,
  };
  window.gtag("event", name, {
    event_category: category,
    event_action: action,
    event_label: label,
    ...newData,
  });
};
