import { List, ListItem, Avatar, Box, ListItemText } from '@mui/material';
import { useTheme } from '@emotion/react';

export const CommentsList = ({ comments }) => {
    const theme = useTheme();
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {comments.map(({ text, src }, index) => (
                <ListItem sx={{
                    display: 'flex', alignItems: 'flex-start', border: `0.5px solid ${theme.palette.text.tertiary}`, borderRadius: "8px", padding: "12px",
                    '&:not(:first-child)': { marginTop: '1rem' }
                }} >
                    <Avatar alt="User" size="small" src={src} sx={{ mt: .5 }} />
                    <Box sx={{ flex: "1 1 auto", maxWidth: "100%" }}>
                        <ListItemText primary={text} sx={{ ml: 1, whiteSpace: 'pre-wrap' }} primaryTypographyProps={{ variant: "body1" }} />
                    </Box>
                </ListItem>
            ))}
        </List>
    )
}

