import { useContext } from "react";
import {
    Typography,
    Box,
    Link,
} from "@mui/material";
import Navbar from "./blocks/Navbar.js";
import Footer from "./blocks/Footer.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { Helmet } from 'react-helmet';

const Privacy = () => {
    const { user } = useContext(AuthContext);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Helmet>
                <title>Privacy - Stanza</title>
                <meta name="description" content="This policy applies to the information SpotOn Computing, Inc. (dba Stanza) collects and receives from individuals (“end users” or “you”) who interact with Publishers and their Digital Media Properties (both defined below) that have implemented Stanza’s technology to provide you with Stanza’s interactive event calendars (“StanzaCal”) and associated ads. Stanza is committed to transparency, and we want you to fully understand how we may collect, use and share data relating to individuals (like you), and the choices that are available to you." />
            </Helmet>
            <Navbar user={user} />
            <Box sx={{ margin: "auto", maxWidth: { xs: 'calc(100% - 2rem)', sm: "600px" }, minWidth: { xs: 'calc(100% - 2rem)', sm: "600px" }, marginTop: 0, flexGrow: 1, p: 2 }}>
                <Typography as="h1" variant="title" sx={{ mb: 3, mt: { md: 3 }, fontWeight: "600" }}>
                    Privacy Policy
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    This policy applies to the information SpotOn Computing, Inc. (dba Stanza) collects and receives from individuals (“end users” or “you”) who interact with Publishers and their Digital Media Properties (both defined below) that have implemented Stanza’s technology to provide you with Stanza’s interactive event calendars (“StanzaCal”) and associated ads. Stanza is committed to transparency, and we want you to fully understand how we may collect, use and share data relating to individuals (like you), and the choices that are available to you.
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    <span style={{ textDecoration: 'underline' }}>Please note:</span><br />
                    This privacy policy is distinct from (i) the collection and use of data when you directly enter our website (<Link href="https://stanzacal.com" taget="_blank">www.stanzacal.com</Link>) – that information can be found here; and (ii) a Publisher’s use and disclosure of information through their Digital Media Properties (defined below), which is governed by their own privacy policies and practices.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    About Us
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Stanza is a calendar platform used by entities to create shareable, interactive event calendars.  These calendars (“<b>StanzaCals</b>”) may be embedded on any websites with relevant content (the owners of such websites are “<b>Publishers</b>” and their websites are “<b>Digital Media Properties</b>”).  End users who see StanzaCals embedded on Digital Media Properties can view event content while on the Digital Media Properties.  If you elect to sync the event content to your personal calendar, or click on specific events for more details, you will navigate away from the Digital Media Property to a https://stanzacal.com hosted page, governed by a separate privacy policy.  StanzaCals embedded on Digital Media Properties also may contain display advertising.  Stanza works with multiple advertising exchange partners to provide such advertising.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Information We Collect
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    When you visit a Digital Media Property that has embedded a StanzaCal, we collect certain information about you and your device (“<b>User Information</b>“). Some of this information may identify a device, and may be considered “personal data” in some jurisdictions, including the European Union.
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Information that we may collect includes:
                </Typography>

                <Typography as="ul" variant="body1" sx={{ mb: 1 }}>
                    <li>
                        <b>Information about your behavior on the Digital Media Properties:</b> including information about the domain, date/time of visits, page view data, geolocation (including city and country);
                    </li>
                    <li>
                        <b>Information about your behavior on StanzaCals embedded on the Digital Media properties:</b> including your scroll and/or click actions within the StanzaCal;
                    </li>
                    <li>
                        <b>Information about your browser:</b> including information about your browser type; and
                    </li>
                    <li>
                        <b>Information about your device:</b> including information about your IP address, device model, device operating system.
                    </li>
                </Typography>


                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    We do not collect any “sensitive” or “special categories of personal data” as defined under European data protection laws.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    How We Collect User Information
                </Typography>


                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    We use standard web technologies to collect information automatically from your device, including:
                </Typography>

                <Typography as="ul" variant="body1" sx={{ mb: 1 }}>
                    <li>
                        <b>“Pixels”:</b> these are blocks of code that we and our web analytics partners may use to track your browsing behavior within the StanzaCal embedded on Digital Media Properties
                    </li>
                    <li>
                        <b>"Cookies":</b> these are data files that often include a unique identifier, and are placed on a visitor’s device or computer. Our web analytics partners or third party advertising platforms may place a cookie in order to track unique visitor browsing behavior within the StanzaCal embedded on Digital Media Properties or serve you with ads.  For more information about cookies, and how to disable cookies, visit <Link href="http://www.allaboutcookies.org" target="_blank" rel="noreferrer">http://www.allaboutcookies.org</Link>.
                    </li>
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    <b>Other technologies:</b> We work with third party advertising platforms that may separately and independently collect and use User Information to deliver targeted advertisements.  This may include information about your visits to various websites or applications across multiple devices in order to provide you content and ads of interest across those devices. These third party advertising platform may use this third-party data in combination with your User Information to deliver you targeted ads across multiple devices.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    How We Use User Information
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    We primarily use the User Information we collect to understand how end users are using our product and improve on delivering relevant and useful calendar content to end users on behalf of Publishers.
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    We may also use the User Information we collect for other legitimate business interests:
                </Typography>


                <Typography as="ul" variant="body1" sx={{ mb: 1 }}>
                    <li>
                        To operate and improve our technology;
                    </li>
                    <li>
                        To compile statistics and conduct research and development for our internal business purposes – all of which is done in aggregate without identifying you;
                    </li>
                    <li>
                        To prepare reports that summarize visitor activity; and
                    </li>
                    <li>
                        To analyze and report on product performance (such as tracking viewability, engagement).
                    </li>
                </Typography>


                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Our third party advertising platforms may use information they have independently collected to deliver end users targeted advertising they believe will be of particular interest to you.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Legal basis for Processing User Information (EEA end users only)
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    If you are an end user located in the European Economic Area, our legal basis for collecting and using the User Information described above is based on Article 6 of the GDPR that allows companies to process personal data if it is in their “legitimate interests”, or those of a third party, to do so, as long as such interests are not overriden by the rights and freedoms of the end user.  Stanza has worked with counsel to develop its GDPR compliance, and intends to generally rely on legitimate interests for our processing activities, although we may rely upon another legal basis in some circumstances.  We have taken measures to ensure that our legitimate interests are not overridden by the rights and freedoms of the end user by allowing individuals to exercise their data protection rights with respect to personal data we may hold (including their right of access) and by requiring publishers, like you, to obtain consent from users for our use of tracking technologies, including cookies, where this is required by law (see below).
                </Typography>


                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    As an end user seeing an embedded StanzaCal on Digital Media Properties in the EEA, Publishers should have a user consent management system such that this consent can be passed to Stanza’s third party advertising partners.  By default, Stanza’s third party ad partners will disable all tracking technologies for users who do not explicitly submit consent to Publishers.
                </Typography>


                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    If you have questions about or need further information concerning the legal basis on which we collect and use your User Information, please contact us using the contact details provided under the “Contact Us” heading below.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Information Retention
                </Typography>


                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    We never store any data longer than we need it. When we no longer need the User Information we collect, it is deleted from our systems.
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    In some cases, we may keep some of the User Information for longer periods of time where required under certain laws or to comply with law enforcement or any regulatory requests.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Sharing of User Information
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    We share User Information that we collect as follows:
                </Typography>


                <Typography as="ul" variant="body1" sx={{ mb: 1 }}>
                    <li>
                        <b>Business Transfers:</b> We may share User Information in connection with the sale or transfer of all or a part of our business or assets to a third party buyer.
                    </li>
                    <li>
                        <b>Vital interest, legal rights and compliance with laws.</b> We may share User Information with law enforcement, regulatory authorities, courts with competent jurisdictions, emergency services or other necessary third parties for legal, protection, security, and safety purposes, including:
                        <ul>
                            <li>
                                to comply with laws or regulatory requirements and to respond to lawful requests and legal process;
                            </li>
                            <li>
                                to protect the rights and property of Rubicon Project, our agents, customers, and others, including enforcing our agreements, policies, and terms of use and protecting our network and physical assets; and/or
                            </li>
                            <li>
                                to protect the safety of our employees and agents, our customers, or any person.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b>Vendors, partners and other service providers:</b> We may share User Information with our third party vendors and partners who perform functions on our behalf and require access to such information to provide us with services or do work for us. Examples include: web analytics, hosting data, and performing analysis related to our technology platform and related services.
                    </li>
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Information Our Publishers and Vendors Collect
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Our Publishers and our third party vendors may use their own tags, pixels, cookies, or other similar technology (or those of their other affiliates). We are not responsible for such use of tracking technologies or for their privacy practices.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Your Opt-Out Choices
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    You are able to opt out of any cookie tracking by Stanza or our third party vendors by choosing to disable cookies in your browser. Note that different devices use different identifiers and different technologies, so that you must opt out separately from each browser and each device that you would like to be opted out.
                </Typography>


                <Typography as="p" variant="body1">
                    For the ads served by our third party advertising platforms, you can opt out of targeted ads by visiting a number of resources:
                </Typography>

                <Typography as="ul" variant="body1" sx={{ mb: 1 }}>
                    <li>
                        Network Advertising Initiative (“NAI”) opt-out page for interest-based advertising at <Link href="http://www.networkadvertising.org/choices" target="_blank" rel="noreferrer">http://www.networkadvertising.org/choices</Link>
                    </li>
                    <li>
                        Digital Advertising Alliance’s (“DAA”) opt-out page at <Link href="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer">http://www.aboutads.info/choices/</Link>
                    </li>
                    <li>
                        EU-based users can opt-out of targeted advertising through the European Interactive Digital Advertising Alliance’s opt-out page at <Link href="http://www.youronlinechoices.eu" target="_blank" rel="noreferrer">http://www.youronlinechoices.eu</Link>
                    </li>
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Please note that these opt-outs operate by placing a cookie on your device that is unique to the browser and device you use to opt-out. They may not function properly if you have configured your browser to reject certain cookies. If you change browsers or computers, or delete the cookies on your computer, you will need to opt-out again. You also can set your browser to notify you when a cookie is being set and to block most cookies, including ours.
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Your device may give you the ability to opt-out of the use of information about the apps you use in order to serve you ads that are targeted to your interests (“Opt out of Interest-Based Ads” or “Opt out of Ads Personalization” on Android devices or “Limit Ad Tracking” on iOS devices). You may stop the collection of location information by particular apps or from your device as a whole at any time by changing the preferences on your mobile device.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    European Data Subject Rights
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    If you are a resident of a country in the European Economic Area (“EEA”), you have certain rights and protections under the law regarding the collection, processing, and use of information about you. As stated above, when you visit any Digital Media Properties that have embedded StanzaCals and provide user consent to the Publishers of such Digital Media Properties, the information that we then collect about you may include those that are considered “personal data” under European law. We process this data as it is necessary to deliver the most relevant and useful StanzaCals to end users, and our third party advertising platforms independently collect and process such data in order to deliver you with advertisements (including targeted advertisements).
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    As a resident of a country in the EEA, you have the right to: (i) to request access and obtain a copy of your User Information, (ii) to request rectification or erasure; (iii) to restrict the processing of your User Information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your User Information.
                </Typography>

                <Typography as="p" variant="body1">
                    If you make a request to access personal information we hold about, please contact us with details of the data subject rights you wish to exercise. The easiest way to do so would be to email us at <Link href="mailto:founders@stanza.co">founders@stanza.co</Link> with your request, including:
                </Typography>

                <Typography as="ul" variant="body1" sx={{ mb: 1 }}>
                    <li>
                        Your full name
                    </li>
                    <li>
                        The country in which you are located at the time you are making your request
                    </li>
                    <li>
                        The data subject rights you wish to exercise
                    </li>
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    We will then direct you to the steps needed to fulfill your data subject right.
                </Typography>


                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Important Note: We have a responsibility to verify your identity before we respond to an access request. This is to make sure that we provide a copy of your personal information only to the person who is entitled to it – you. For that reason, it is our policy to only accept requests submitted directly to us by the data subject. If you wish to make an access request, we kindly ask that you contact us directly, and we will provide instructions to you so we can verify your identity.
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Please also note that because most of the information we store can only identify a particular browser or device, and cannot identify you individually, you need to provide us with some additional information to enable us to identify the User Information we hold about you and ensure that we accurately fulfill your request.
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Finally, as a resident of the EEA, you have the right to lodge a complaint about our processing of personal data with a European Data Protection Authority. For contact details of your relevant local Data Protection Authority, please see here: <Link href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm" target="_blank" rel="noreferrer">http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm</Link>
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Security
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    We use industry-standard technical and organizational security measures to help protect information transmitted over or stored on our systems. Please note that no transmission or storage of information, however, can ever be guaranteed to be completely secure, though we take all reasonable precautions to protect against security incidents.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Changes to this Policy
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    We may update this policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons. If we make material changes to this policy, we will notify you of any material changes by posting the revised policy on our website, and where necessary, by any other means required by applicable law.
                </Typography>


                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Data Transfers for Residents of the European Economic Area (EEA)
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    Stanza is headquartered in the United States.  Our data is stored with third party data processors whose data centers are located in the United States. If you are accessing any of the Digital Media Properties from outside the United States, please be aware that your data may be transferred to, stored and processed by those third parties with whom we may share your User Information.
                </Typography>

                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    The United States may not provide an adequate level of data protection for your User Information (as determined by the European Commission) or have data protection or other laws as comprehensive as those in your country. We will however ensure that where Stanza or third party service providers receive User Information outside of the EEA, appropriate protections are in place under European data protection legislation.
                </Typography>


                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    For example, in order to ensure that your User Information is adequately protected when transferred outside of the EEA, Stanza has entered into inter-company “model clause” agreements and other adequacy arrangements with other various entities located outside the EEA that may process your User Information on behalf of Stanza.
                </Typography>

                <Typography as="h5" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Contact Us
                </Typography>


                <Typography as="p" variant="body1" sx={{ mb: 1 }}>
                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact Stanza’s Data Protection Officer by e-mail at <Link href="mailto:founders@stanza.co">founders@stanza.co</Link> or by direct mail using the details provided below:
                </Typography>


                <Typography as="p" variant="body1">
                    Stanza<br />
                    Attn:  Privacy Officer<br />
                    548 Market St, PMB 58583<br />
                    San Francisco, CA 94104-5401<br />
                    USA<br />
                </Typography>


            </Box>
            <Footer />
        </div>

    );

}

export default Privacy;
