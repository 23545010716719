import { useMemo } from "react";
import { getMinuteTimestamp } from "./Utils.js";

export const useProfileImage = (
  creator,
  { minuteTimeStamp } = { minuteTimeStamp: false }
) => {
  return useMemo(() => {
    const imageUrl = creator?.picture?.includes("http")
      ? creator.picture
      : `${process.env.REACT_APP_CLOUD_STORAGE_IMAGE_URL}/${creator?.picture}`;
    const timestamp = minuteTimeStamp ? getMinuteTimestamp() : Date.now();
    
    return `${imageUrl}?timestamp=${timestamp}`;
  }, [creator?.picture]);
};
