import { useMemo, useState, useEffect } from "react";
import BasketballIcon from "src/assets/svg/basket-ball.js";
import { Box, Skeleton } from "@mui/material";
import { extractImageColors, fetchAndCacheImage } from "src/utils/Utils";
import { useProfileImage } from "src/utils/useProfileImage";
import { useQuery } from "@tanstack/react-query";

const DefaultImageBasketball = ({ eventUniqueNumber, loading, ...props }) => {
  // this is to assign the same gradient to the same event every time
  const gradientAsignation = eventUniqueNumber
    ? parseInt(eventUniqueNumber) % 8
    : 0;
  const gradients = [
    "linear-gradient(105.36deg, #E6E7EF 0%, #DBDEF3 25.52%, #BDC6F9 53.13%, #B6BEEB 79.69%, #BDC1D8 100%)",
    "linear-gradient(105.36deg, #DFDEE6 0%, #DAD9E3 25.52%, #D7DBEA 53.13%, #CDD0E1 79.69%, #BABBCD 100%)",
    "linear-gradient(105.36deg, #E0DFE8 0%, #E8DEE8 25.52%, #F9DFE5 53.13%, #EED5DB 79.69%, #C9C4D3 100%)",
    "linear-gradient(105.36deg, #FBF1EA 0%, #FDE5D5 25.52%, #FFC8AB 53.13%, #F6CEBC 79.69%, #F0D5CA 100%)",
    "linear-gradient(105.36deg, #F3ECE5 0%, #F6E9DE 25.52%, #FCE5CF 53.13%, #F5DAB9 79.69%, #E5D0B5 100%)",
    "linear-gradient(105.36deg, #E7E8F0 0%, #E8E2F2 25.52%, #F1DAF5 53.13%, #DECDE8 79.69%, #C7C3D7 100%)",
    "linear-gradient(105.36deg, #E6E7EF 0%, #E0E9EB 25.52%, #D3F1DD 53.13%, #C6DDD3 79.69%, #C2C8D2 100%)",
    "linear-gradient(105.36deg, #F1F1F1 0%, #F5F5F6 25.52%, #DDDDDD 53.13%, #F6F6F6 79.69%, #EBEBEC 100%)",
  ];
  return (
    <Box
      display={loading ? "none" : "flex"}
      alignItems="center"
      justifyContent="center"
      sx={{ background: gradients[gradientAsignation], borderRadius: "4px" }}
      {...props}
    >
      <BasketballIcon height="28%" width="28%" style={{ opacity: 0.1 }} />
    </Box>
  );
};

const DefaultImageAvatar = ({
  userAvatar,
  bgGradients,
  loading,
  eventUniqueNumber,
  ...props
}) => {
  const filterImg = "drop-shadow(2px 2px 7px rgba(0, 0, 0, 0.6)";
  const ImgStyles = {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    minWidth: "70px",
    transform: "scale(1.1)",
    objectFit: "contain",
    filter: filterImg,
  };
  // this is to assign the same gradient to the same event every time
  const gradientAsignation = eventUniqueNumber
    ? parseInt(eventUniqueNumber) % 6
    : 0;

  return (
    <Box
      display={loading ? "none" : "flex"}
      justifyContent="center"
      alignItems="center"
      bgcolor={"#f0f0f0"} // Set the background color
      border={`1px solid #d0d0d0`} // Set the border color
      borderRadius="8px" // Rounded corners
      sx={{
        background: bgGradients?.[gradientAsignation],
        maxWidth: "-webkit-fill-available",
        position: "relative",
        overflow: "hidden",
      }}
      {...props}
    >
      <img src={userAvatar} id="user-avatar" style={{ ...ImgStyles }} />
    </Box>
  );
};

const DefaultImage = (props) => {
  const profileImage = useProfileImage(
    { picture: `${props.calendar?.handle ?? ""}-profileImage.png` },
    { minuteTimeStamp: true }
  );
  const [loading, setLoading] = useState(true);

  // within 1 minute only handle 1 request then use the cache
  const {
    data: { userAvatar, bgGradients } = { userAvatar: null, bgGradients: null },
    refetch,
  } = useQuery([profileImage, "bgGradients-DefaultImage"], async () => {
    const userAvatar = await fetchAndCacheImage(profileImage, () => {});
    if (!userAvatar) {
      setLoading(false);
      return { userAvatar: false, bgGradients: null }; // no image, use basketball default image
    }
    const { dark, vibrant, lightVibrant, lightMuted, darkMuted } =
      await extractImageColors(userAvatar);
    setLoading(false);
    return {
      userAvatar,
      bgGradients: [
        `linear-gradient(270deg, ${vibrant}, ${lightMuted})`,
        `linear-gradient(315deg, ${vibrant}, ${darkMuted})`,
        `linear-gradient(270deg, ${lightMuted}, ${vibrant}, ${darkMuted})`,
        `linear-gradient(180deg, ${lightMuted}, ${vibrant})`,
        `linear-gradient(315deg, ${lightVibrant}, ${vibrant})`,
        `linear-gradient(315deg, ${vibrant}, ${vibrant})`,
      ],
    };
  });

  useEffect(() => {
    if (userAvatar || bgGradients) {
      setLoading(false); // we have in cache the image uri and gradients
    } else if (userAvatar === false) {
      setLoading(false); // no image, use basketball default
    }
  }, [bgGradients, userAvatar]);

  // first digit: last digit of the id
  // second digit: position in the timeline
  const eventUniqueNumber = useMemo(
    () =>
      props.id
        ?.split("")
        .reverse()
        .find((char) => !isNaN(char)) + props.position,
    [props.id, props.position]
  );

  return (
    <>
      {loading && (
        <Skeleton
          id="img-loader"
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "4px" }}
          width={props.width}
          height={Number(props.height.split("px")[0])}
        />
      )}

      {!userAvatar ? (
        <DefaultImageBasketball
          loading={loading}
          eventUniqueNumber={eventUniqueNumber}
          {...props}
        />
      ) : (
        <DefaultImageAvatar
          loading={loading}
          eventUniqueNumber={eventUniqueNumber}
          userAvatar={userAvatar}
          bgGradients={bgGradients}
          {...props}
        />
      )}
    </>
  );
};

export default DefaultImage;
