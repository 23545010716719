import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function updateEvent({
  calendarId,
  eventId,
  name,
  description,
  location,
  startDate,
  endDate,
  allDay
}) {
  await axios.post(
    getApiRoute("event", "UPDATE_EVENT", { calendarId, eventId }),
    {
      name,
      description,
      location,
      startDate,
      endDate,
      allDay
    },
    { withCredentials: true }
  );
}

export function useUpdateEvent() {
  return useMutation(updateEvent);
}
