import * as React from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  Grow,
  DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const CenterDialog = ({
  open,
  handleClose,
  children,
  dialogTitle = "Modal Title",
  maxWidth = "sm",
  dividers,
  sx,
  ...props
}) => {
  const CloseButton = () => {
    return (
      <IconButton
        edge="end"
        color="inherit"
        variant="outlined"
        data-testid="dialog-close-button"
        onClick={handleClose}
        aria-label="close"
        sx={{ position: "absolute", top: "12px", right: "32px" }}
      >
        <Close fontSize="small" />
      </IconButton>
    );
  };

  return (
    <Dialog
      sx={{
        ".MuiDialog-paper": { borderRadius: "16px" },
        ...sx,
      }}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      {...props}
    >
      <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontSize: "16px" }}>
        {dialogTitle}
      </DialogTitle>
      <CloseButton />
      <DialogContent dividers={dividers} sx={{ padding: "16px 24px 32px" }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CenterDialog;
