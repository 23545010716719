import { useEffect, useRef } from 'react';

export const useScrollButtons = ({ direction = "vertical", containerRef, upButtonRef, downButtonRef, scrollDelta = 20 }) => {
    const scrollIntervalRef = useRef(null);
    const container = containerRef?.current;
    const upButton = upButtonRef?.current;
    const downButton = downButtonRef?.current;


    const showScrollButtons = () => {
        upButton?.style.setProperty('--scroll-button-top-inset', `0px`)
        downButton?.style.setProperty('--scroll-button-bottom-inset', `0px`)
        if (direction === "vertical") {
            upButton.style.transform = `translateY(${container.scrollTop}px)`
            downButton.style.transform = `translateY(${container.scrollTop}px)`
        } else {
            upButton.style.transform = `translateX(${container.scrollLeft - 5}px) rotate(270deg)`
            downButton.style.transform = `translateX(${container.scrollLeft + 5}px) rotate(270deg)`
        }
    }

    useEffect(() => {
        if (!container || !upButton || !downButton) return;
        const scrollUp = (e) => {
            if (direction === "vertical") {
                container.scrollTop -= scrollDelta;
                showScrollButtons()
            } else {
                container.scrollLeft -= scrollDelta;
                showScrollButtons()
            }
        };

        const scrollDown = (e) => {
            if (direction === "vertical") {
                container.scrollTop += scrollDelta;
                showScrollButtons()
            } else {
                container.scrollLeft += scrollDelta;
                showScrollButtons()
            }
        };

        const startScrolling = (scrollFunction) => {
            if (scrollIntervalRef.current) return;
            scrollIntervalRef.current = setInterval(scrollFunction, 70);
        };

        const stopScrolling = () => {
            clearInterval(scrollIntervalRef.current);
            scrollIntervalRef.current = null;
        };

        const startScrollUp = (e) => {
            e.preventDefault();
            startScrolling(scrollUp)
        };
        const startScrollDown = (e) => {
            e.preventDefault();
            startScrolling(scrollDown)
        };

        // Eventos del mouse
        upButton?.addEventListener('mousedown', startScrollUp);
        upButton?.addEventListener('mouseup', stopScrolling);
        upButton?.addEventListener('mouseleave', stopScrolling);

        downButton?.addEventListener('mousedown', startScrollDown);
        downButton?.addEventListener('mouseup', stopScrolling);
        downButton?.addEventListener('mouseleave', stopScrolling);

        // Eventos táctiles
        upButton?.addEventListener('touchstart', startScrollUp);
        upButton?.addEventListener('touchend', stopScrolling);

        downButton?.addEventListener('touchstart', startScrollDown);
        downButton?.addEventListener('touchend', stopScrolling);

        return () => {
            // Eliminar eventos del mouse
            upButton?.removeEventListener('mousedown', startScrollUp);
            upButton?.removeEventListener('mouseup', stopScrolling);
            upButton?.removeEventListener('mouseleave', stopScrolling);

            downButton?.removeEventListener('mousedown', startScrollDown);
            downButton?.removeEventListener('mouseup', stopScrolling);
            downButton?.removeEventListener('mouseleave', stopScrolling);

            // Eliminar eventos táctiles
            upButton?.removeEventListener('touchstart', startScrollUp);
            upButton?.removeEventListener('touchend', stopScrolling);

            downButton?.removeEventListener('touchstart', startScrollDown);
            downButton?.removeEventListener('touchend', stopScrolling);
        };
    }, [upButton]);


    // Scroll buttons show/hide
    useEffect(() => {
        if (!container || !upButton || !downButton) return;
        const hideScrollButtons = () => {
            upButton?.style.setProperty('--scroll-button-top-inset', '-70px')
            downButton?.style.setProperty('--scroll-button-bottom-inset', '-70px')
        }

        container.addEventListener('mouseenter', showScrollButtons)
        container.addEventListener('mouseleave', hideScrollButtons)
        container.addEventListener('touchstart', showScrollButtons)
        container.addEventListener('scroll', showScrollButtons)

        return () => {
            container.removeEventListener('mouseenter', showScrollButtons)
            container.removeEventListener('mouseleave', hideScrollButtons)
            container.removeEventListener('touchstart', showScrollButtons)
            container.removeEventListener('scroll', showScrollButtons)
        }
    }, [upButton, container, downButton])

};