import LoadingAnimation from 'src/components/blocks/LoadingAnimation.js';
import useAuth from './UseAuth.js';
import { createContext } from 'react';

export const AuthContext = createContext();

function AuthProviderApp({ children }) {

  const { user, isLoading, refetchAuth, handleSignOut, checkSignIn } = useAuth();

  return isLoading ? <LoadingAnimation /> : (
    <AuthContext.Provider value={{ user, handleSignOut, refetchAuth, checkSignIn, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
}

// Mocked AuthProvider for embedded views, don't need to check auth
function AuthProviderEmbedded({ children }) {
  const defaultValues = {
    user: null,
    handleSignOut: () => { },
    refetchAuth: () => { },
    checkSignIn: () => { },
    isLoading: false
  };
  const { user, isLoading, refetchAuth, handleSignOut, checkSignIn } = defaultValues;
  return (
    <AuthContext.Provider value={{ user, handleSignOut, refetchAuth, checkSignIn, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
}

export const AuthProvider = (props) => {
  if (!props.isEmbedded && !(window.location.href.includes('/timeline_embedded') || window.location.href.includes('/embedded'))) {
    return <AuthProviderApp {...props} />;
  } else {
    return <AuthProviderEmbedded {...props} />;
  }
}