import React, {
  useState,
  useEffect,
  useContext,
  memo,
  useCallback,
} from "react";
import { AuthContext } from "src/utils/AuthContext.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { redirectSignedOutUser, getWebcalFormat, handleOpenWebcal, handleSubscribeExperience } from "src/utils/Utils.js";
import { useNavigate } from "react-router-dom";
import { getPageRoute, getApiRoute, useGetUserCalendars, useUnsubscribeCalendar } from "src/services";
import { useAddToGroup } from "src/services";
import RedirectionMessage from "src/components/blocks/RedirectionMessage";
import { Box, Icon } from "@mui/material";
import axios from "axios";

/**
 * 
 * @param {boolean} moveCalendarToGroup - If true, the user is unsubscribing and adding the calendar to a group.
 */
export const useGroupDialogs = ({ moveCalendarToGroup = false, setCreatedGroups, onSubscribeAction = () => {}, setSubscribed = () => {}  }) => {
    const { user, refetchAuth } = useContext(AuthContext);
    const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
    const [openAddToGroup, setOpenAddToGroup] = useState(false);
    const [openNewGroup, setOpenNewGroup] = useState(false);
    const { mutateAsync: addToGroup } = useAddToGroup();
    const navigate = useNavigate();
    const [handleMessage, setHandleMessage] = useState("");
    const [targetCalendar, setTargetCalendar] = useState(false);
    const { refetch: refetchUserCalendars, isLoading } = useGetUserCalendars({ user });
    const { mutateAsync: unsubscribeCalendar } = useUnsubscribeCalendar();

    const handleGroupDialog = useCallback(async (targetCalendar) => {
        if (redirectSignedOutUser(user, navigate)) return;
    
        if (user.hasValidSubscription) {
          try {
            // Fetch the latest calendars / groups user has added / created
            const data = await refetchUserCalendars();
            setTargetCalendar(targetCalendar);
            setCreatedGroups(data?.data?.createdGroups ?? []);
            if (data?.data?.createdGroups?.length > 0) {
              setOpenAddToGroup(true);
            } else {
              setOpenNewGroup(true);
            }
          } catch (error) {
            console.error(error);
            showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, error.message, 'error');
          }
        } else {
          return navigate(
            getPageRoute(
              "upgrade",
              "UPGRADE_PLUS",
              {},
              {
                forward: window.location.pathname + window.location.search,
              }
            )
          );
        }
      }, [navigate, refetchUserCalendars, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity, showSnackBar, user]);
    

    const handleUnsubscribeFromCalendar = useCallback(async () => {
        try {
          const response = await unsubscribeCalendar({ calendarId: targetCalendar._id });  
          return true;
        } catch (error) {
          // was not able to unsubscribe
          showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, "Sorry, there was an error moving this calendar to a group. Please try again.", 'error');
          return false;
        }
      }, [targetCalendar, unsubscribeCalendar, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity, showSnackBar]);


    const handleAddToGroup = useCallback(async (group) => {
        try {
          if (moveCalendarToGroup) {
            const unsubscribed = await handleUnsubscribeFromCalendar();
            if (!unsubscribed) return setOpenAddToGroup(false);
          }
          await addToGroup({ groupId: group._id, calendarIds: [targetCalendar._id] });
          onSubscribeAction?.(true);
          const defaultFn = () => setTimeout(() => {
            showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity,
               <Box display="flex" alignItems="center" onClick={() => navigate(`/${group.handle}/${group._id}`)} sx={{ cursor: "pointer" }}>Added to <Icon baseClassName="material-symbols-outlined" sx={{ fontWeight: "300", fontSize: "1rem", ml: 0.75, mr: 0.5 }}>folder</Icon> {group.name} <Icon baseClassName="material-symbols-outlined" sx={{ fontWeight: "300", fontSize: "1rem", ml: 0.75 }}>north_east</Icon></Box>, 'success');
          }, 1000);
          handleSubscribeExperience({ navigate, calendar: group, defaultFn })
          setOpenAddToGroup(false);
          setSubscribed(true);
          refetchUserCalendars();
          refetchAuth();
        } catch (error) {
          console.log(error);
          showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, error.message, 'error');
        }
      }, [addToGroup, targetCalendar._id, onSubscribeAction, refetchAuth, refetchUserCalendars, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity, showSnackBar]);
    

      const handleCreateGroup = useCallback(async (groupName, groupDescription, handle) => {
        try {
          if (moveCalendarToGroup) {
            const unsubscribed = await handleUnsubscribeFromCalendar();
            if (!unsubscribed) return setOpenNewGroup(false);
          }
          const response = await axios.post(getApiRoute("group", "CREATE_GROUP"),
            {
              name: groupName,
              description: groupDescription,
              tags: [],
              handle: handle ? handle : user?.handle,
              calendars: [targetCalendar._id]
            },
            { withCredentials: true }
          );
    
          console.log("Group created successfully");
          onSubscribeAction?.(true);
          setTimeout(() => {
            showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Group created successfully.', 'success');
            showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, <RedirectionMessage redirectionCallback={() => handleOpenWebcal(user, getWebcalFormat(response.data.groupLink), response.data, navigate)} />, 'success');
          }, 1000);
          setOpenNewGroup(false);
          setSubscribed(true);
          refetchUserCalendars();
          refetchAuth();
        } catch (error) {
          if (error.response && error.response.status === 400) {
            setHandleMessage("Handle already taken. Pick another one.");
          }
          if (error.response && error.response.status === 403) {
            return navigate(getPageRoute("upgrade", "UPGRADE_PLUS", {}, { forward: window.location.pathname + window.location.search, }));
          } else {
            showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, error.message, 'error');
          }
        }
      }, [targetCalendar._id, handleOpenWebcal, navigate, onSubscribeAction, refetchAuth, refetchUserCalendars, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity, showSnackBar, user?.handle]);
    


      return {
        openAddToGroup,
        openNewGroup,
        setOpenAddToGroup,
        setOpenNewGroup,
        handleGroupDialog,
        handleAddToGroup,
        handleCreateGroup,
        handleMessage,
        targetCalendar,
      }

}