import { Typography, Button, Box } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getPageRoute } from "src/services";
import Sports from 'src/assets/svg/sports.js';
import { useDimensions } from "src/utils/useDimensions.js";

function Hero({ user }) {
  const navigate = useNavigate();

  const { isMediumScreen } = useDimensions();

  return (
    <div>
      {!user &&
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="20px" >
          <Box flex="1 1 0%">
            <Typography
              data-testid="home-guest-title"
              variant="h1"
              gutterBottom
              sx={{ mt: { xs: 1, md: 5 }, mb: { xs: 0, md: 1 }, whiteSpace: { md: "pre" } }}
            >
              {"What's\nnext?"}
            </Typography>
            <Typography
              data-testid="home-guest-subtitle"
              variant="subtitle1"
              gutterBottom
              sx={{ mb: { xs: 1.5, md: 2 }, textWrap: "pretty" }}
            >
              Calendars for everyone
            </Typography>
            <Button
              data-testid="home-get-started-button"
              variant="contained"
              sx={{ mb: 3, width: { xs: '180px', md: '250px' } }}
              onClick={() => navigate(getPageRoute("auth", "SIGNIN"))}
            >
              Get Started
            </Button>
          </Box>

          {isMediumScreen ? (
            <Sports width="171px" height="171px" style={{ marginBottom: "1.5rem" }} />
          ) : (
            <Sports width="200px" height="200px" />
          )}

        </Box>
      }
    </div >
  );
}
export default Hero;
