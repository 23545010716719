import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./blocks/Navbar.js";
import { TextField, Box, Typography } from "@mui/material";
import ImportOrEditForm from "./blocks/ImportOrEditForm.js";
import { getPageRoute } from "src/services";
import { useImportCalendar } from "src/services";
import { validateCalendar } from "src/utils/Utils.js";
import Footer from "./blocks/Footer.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { Helmet } from "react-helmet";
import { useMutation } from "@tanstack/react-query";
import { useTheme } from "@emotion/react";

function ImportCalendar({ user }) {
  const navigate = useNavigate();
  const { refetchAuth } = useContext(AuthContext);
  const [handle, setHandle] = useState(user.handle);
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [urlMessage, setUrlMessage] = useState("");
  const [validCalendarMessage, setValidCalendarMessage] = useState("");
  const [tags, setTags] = useState([]);
  const [handleMessage, setHandleMessage] = useState("");
  const { error, mutateAsync } = useImportCalendar();
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();
  const theme = useTheme();

  useEffect(() => {
    if (user.handle) {
      setHandle(user.handle);
    } else {
      setHandle("");
    }
  }, [user.handle, user.createdCalendars]);

  // Update the tags object as new tags are checked or unchecked
  const handleTagChange = (tagName, checked) => {
    console.log("tags " + tags);
    setTags((prevTags) => {
      let newTags = prevTags.slice(); // create a copy of the tags array
      if (checked && !newTags.includes(tagName)) {
        newTags.push(tagName);
      } else if (!checked && newTags.includes(tagName)) {
        newTags = newTags.filter((tag) => tag !== tagName);
      }
      return newTags;
    });
  };

  // Save tag changes after hitting "Save" button in UI
  const { mutateAsync: handleSave, isLoading } = useMutation(async () => {
    // Validate webcal link format
    const validWebcal = link.startsWith("http") || link.startsWith("webcal");
    if (!validWebcal) {
      setUrlMessage("Webcal link must start with webcal:// or http://.");
      return;
    }
    // Validate webcal link
    const resolve = await validateCalendar(link);
    if (!(resolve?.status >= 200 && resolve?.status < 300))
      return setValidCalendarMessage(resolve.error);

    // create new calendar
    const response = await mutateAsync({
      handle,
      link,
      name,
      description,
      tags,
    });
    if (!error) {
      const { calendarId } = response.data;
      // navigate the user to the page that shows the webcal contents
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "Calendar imported successfully.",
        "success"
      );
      setTimeout(() => {
        navigate(
          getPageRoute("calendar", "CALENDAR_EVENTS", {
            handle,
            calendarId,
          })
        );
        refetchAuth();
      }, 2000);
    } else {
      console.error(error);
      if (error.response && error.response.status === 400) {
        setHandleMessage("Handle already taken. Pick another one.");
      } else {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "Error saving calendar.",
          "error"
        );
      }
    }
  });

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Helmet>
        <title>Import Calendar - Stanza</title>
        <meta
          name="description"
          content="Import a calendar using a webcal link. Stanza will automatically with new events and changes."
        />
      </Helmet>
      <Navbar user={user} />
      <Box
        sx={{
          margin: "auto",
          marginTop: "0",
          maxWidth: { xs: "100%", sm: "600px" },
          p: 2,
        }}
      >
        <Typography as="h1" variant="title" sx={{ mb: 3, mt: { md: 3 } }}>
          Import Calendar
        </Typography>

        {user &&
          (user.handle == null ||
            user.handle == undefined ||
            user.handle == "") && (
            <TextField
              required
              data-testid="import-calendar-handle"
              value={handle}
              label="Username"
              helperText={
                handleMessage
                  ? handleMessage
                  : `You can use letters, numbers, dashes (-), and underscores (_). For example, the Knicks use "nba-knicks".`
              }
              variant="standard"
              onChange={(event) => {
                let value = event.target.value;
                value = value.replace(/[A-Z]/g, (match) => match.toLowerCase());
                value = value.replace(/[^a-z0-9\-\_]/g, "");
                setHandle(value);
              }}
              error={!!handleMessage}
              sx={{ width: "100%", mb: 2 }}
            />
          )}
        <ImportOrEditForm
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          link={link}
          setLink={setLink}
          urlMessage={urlMessage}
          validCalendarMessage={validCalendarMessage}
          tags={tags}
          handleTagChange={handleTagChange}
          handleSave={handleSave}
          isLoading={isLoading}
        />
      </Box>
      <Footer />
    </div>
  );
}

export default ImportCalendar;
