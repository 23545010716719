import React, { useState, useEffect } from "react";
import BottomDialog from "./BottomDialog.js";
import CenterDialog from "./CenterDialog.js";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import {
  Box,
  Stack,
  TextField,
  Button,
  Typography,
  InputAdornment,
  styled,
  Switch,
  Link,
} from "@mui/material";
import {
  AccessTimeOutlined,
  LocationOnOutlined,
  NotesOutlined,
  TipsAndUpdatesOutlined,
} from "@mui/icons-material";
import {
  LocalizationProvider,
  DesktopDatePicker,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/index.js";
import {
  MobileDateRangePicker,
  DesktopDateRangePicker,
  MobileDateTimeRangePicker,
} from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { parseDateEvent } from "src/utils/Utils.js";
import { useCreateEvent } from "src/services/mutations/useCreateEvent.js";
import { useUpdateEvent } from "src/services/mutations/useUpdateEvent.js";
import { useDeleteEvent } from "src/services/mutations/useDeleteEvent.js";
import { LoadingButton } from "../LoadingButton.js";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
import DeleteEventDialog from "./DeleteEventDialog.js";

dayjs.extend(utc);
dayjs.extend(timezone);

const TransparentTextField = styled(TextField)({
  "& .MuiInputBase-root:before": {
    display: "none",
  },
  "& .Mui-focused::after": {
    display: "none",
  },
});

const EditEventContent = ({
  eventData,
  handleSave,
  handleOpenDelete,
  handleCancel,
  isLoading,
}) => {
  const {
    eventId,
    eventSummary,
    eventLocation,
    eventDateStart,
    eventDateEnd,
    eventTvSeasonEpisode,
    eventDescription,
    isEventLive,
    isPastEvent,
  } = eventData ?? {};
  const theme = useTheme();
  const { isSmallScreen } = useDimensions();
  const [maxRows, setMaxRows] = useState(isSmallScreen ? 8 : 12);
  const [name, setName] = useState(eventSummary ? eventSummary[3] : "");
  const [startDate, setStartDate] = useState(
    eventDateStart ? dayjs(eventDateStart[3]) : undefined
  );
  const [endDate, setEndDate] = useState(
    eventDateEnd ? dayjs(eventDateEnd[3]) : undefined
  );
  const [location, setLocation] = useState(
    eventLocation ? eventLocation[3] : ""
  );
  const [allDay, setAllDay] = useState(
    eventDateStart && eventDateStart[3]
      ? parseDateEvent(eventDateStart[3]).isAllDayEvent
      : false
  );
  const [description, setDescription] = useState(
    eventDescription ? parseDescripionInput(eventDescription) : ""
  );
  const isInvalid = !name || !location || !startDate || !endDate;

  const DatePicker = isSmallScreen ? MobileDatePicker : DesktopDatePicker;
  const DateRangePicker = isSmallScreen
    ? MobileDateRangePicker
    : DesktopDateRangePicker;
  const DateTimeRangePicker = MobileDateTimeRangePicker;

  const getSlotProps = (icon) => ({
    InputProps: {
      startAdornment: (
        <InputAdornment
          position="start"
          sx={{ width: "36px", height: "36px", mr: 2 }}
        >
          {icon()}
        </InputAdornment>
      ),
    },
  });

  function brTagToBreak(input) {
    return input.replace(/<br\s*\/?>/gi, "\n");
  }
  function removeTags(input) {
    return input.replace(/<\/?[^>]+(>|$)/g, "");
  }
  function removeAllNextDashes(input) {
    const index = input.indexOf("---");
    return index >= 0 ? input.slice(0, index) : input;
  }
  function removeLeadingCheckMarks(input) {
    return input.replace(/^[✔️\s]+/, "");
  }
  function removeExtraLines(input) {
    let endIndex = null;
    if (input.endsWith("\n\n")) endIndex = -2;
    else if (input.endsWith("\n")) endIndex = -1;
    return endIndex ? input.slice(0, endIndex) : input;
  }
  function parseDescripionInput(input) {
    const cleanedInput = removeLeadingCheckMarks(input);
    const noTags = removeAllNextDashes(removeTags(brTagToBreak(cleanedInput)));
    return removeExtraLines(noTags);
  }
  useEffect(() => {
    if (!isSmallScreen) {
      const updateMaxRows = () => {
        const height = window.innerHeight;
        if (height < 600) {
          setMaxRows(2);
        } else if (height < 700) {
          setMaxRows(4);
        } else if (height < 800) {
          setMaxRows(7);
        } else {
          setMaxRows(12);
        }
      };
      updateMaxRows();
      window.addEventListener("resize", updateMaxRows);
      return () => {
        window.removeEventListener("resize", updateMaxRows);
      };
    } else {
      setMaxRows(8);
    }
  }, [isSmallScreen]);

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/\n{3,}/g, "\n\n");
    setDescription(sanitizedValue);
  };

  return (
    <>
      <style>{`
      .MuiDateRangeCalendar-root>div:first-child {
        display: none
      }
    `}</style>
      <Stack
        spacing={3}
        sx={{ width: { sm: "450px" }, px: { sm: 2 }, pt: { xs: 0.5, sm: 1 } }}
      >
        <TextField
          required
          data-testid={"event-name"}
          value={name}
          placeholder="Add Title"
          variant="standard"
          onChange={(event) => setName(event.target.value)}
          sx={{
            pl: 6,
            "& .MuiInputBase-input": { fontSize: "18px", fontWeight: "500" },
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ display: "flex", gap: 3, alignItems: "start" }}>
            <AccessTimeOutlined sx={{ mt: 0.75 }} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="body1">All-day</Typography>
                <Switch
                  checked={allDay}
                  onChange={(e) => {
                    setAllDay(e.target.checked);
                    if (e.target.checked) {
                      // move to all day
                      if (startDate) {
                        setStartDate((prevState) => {
                          return prevState.hour(0).minute(0).second(0);
                        });
                        setEndDate((prevState) => {
                          return prevState
                            .add(1, "day")
                            .hour(0)
                            .minute(0)
                            .second(0);
                        });
                      } else {
                        // as it doesn't provide values before, we asume pre-filling with "all day of today"
                        setStartDate(dayjs().hour(0).minute(0).second(0));
                        setEndDate(
                          dayjs().add(1, "day").hour(0).minute(0).second(0)
                        );
                      }
                    } else {
                      // move to sigle day
                      if (endDate) {
                        setEndDate(endDate.add(-1, "hours"));
                      }
                    }
                  }}
                />
              </Box>
              {allDay ? (
                <DateRangePicker
                  localeText={{
                    start: "",
                    end: "",
                  }}
                  value={[
                    startDate,
                    endDate?.isBefore(startDate)
                      ? startDate
                      : endDate.add(-1, "day"),
                  ]}
                  onChange={([newValueStart, newValueEnd]) => {
                    setStartDate(newValueStart);
                    // as google calendar UX, the user selects the range of days that the event will happen.
                    setEndDate(newValueEnd.add(1, "day"));
                  }}
                  timezone="utc"
                  sx={{
                    "& .MuiFormLabel-root, .MuiOutlinedInput-notchedOutline": {
                      display: "none",
                    },
                    "& .MuiInputBase-input": {
                      padding: "4px 0 5px",
                      width: "90px",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "1.25rem",
                      color: `${theme.palette.text.secondary}`,
                      fontWeight: "300",
                    },
                  }}
                />
              ) : (
                <DateTimeRangePicker
                  localeText={{
                    start: "",
                    end: "",
                  }}
                  value={[
                    startDate,
                    endDate?.isBefore(startDate) || endDate?.isSame(startDate)
                      ? startDate.add(1, "hours")
                      : endDate,
                  ]}
                  onChange={([newValueStart, newValueEnd]) => {
                    const newStartDate = dayjs(newValueStart);
                    const newEndDate = dayjs(newValueEnd);
                    if (
                      newEndDate.isBefore(newStartDate) ||
                      newEndDate.isSame(newStartDate)
                    ) {
                      setStartDate(newValueStart);
                      setEndDate(newValueStart.add(1, "hours"));
                      return;
                    }
                    setStartDate(newValueStart);
                    setEndDate(newValueEnd);
                  }}
                  timezone="system"
                  sx={{
                    width: { xs: "200px", sm: "385px" },
                    flexDirection: "column",
                    gap: { xs: "8px" },
                    "& .MuiFormLabel-root, .MuiOutlinedInput-notchedOutline": {
                      display: "none",
                    },
                    "&>p": {
                      display: { xs: "none" },
                    },
                    "&>*": {
                      marginLeft: { xs: "0px !important" },
                    },
                    "& .MuiInputBase-input": {
                      padding: "4px 0 5px",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "1.25rem",
                      color: `${theme.palette.text.secondary}`,
                      fontWeight: "300",
                    },
                  }}
                />
              )}
            </Box>
          </Box>
          <TransparentTextField
            required
            multiline
            data-testid={"event-location"}
            placeholder="Add location"
            variant="standard"
            value={location}
            maxRows={3}
            sx={{
              "& .MuiInputBase-root": {
                "align-items": "flex-start",
              },
            }}
            onChange={(event) => {
              if (event.target.value.includes("\n")) {
                event.preventDefault();
              } else {
                setLocation(event.target.value);
              }
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
            {...getSlotProps(() => (
              <LocationOnOutlined
                sx={{ fontSize: "1.7rem", mt: -1, ml: "-2px" }}
              />
            ))}
          />
        </LocalizationProvider>
        <TransparentTextField
          required
          multiline
          data-testid={"event-description"}
          value={description}
          placeholder="Add description"
          variant="standard"
          maxRows={isSmallScreen ? 8 : maxRows}
          onChange={handleDescriptionChange}
          sx={{
            "& .MuiInputBase-root": {
              "align-items": "flex-start",
            },
            "& .MuiFormHelperText-root": {
              "margin-left": "3rem",
              "margin-top": "1rem",
            },
          }}
          helperText={
            <Typography variant="caption">
              <TipsAndUpdatesOutlined
                sx={{ verticalAlign: "middle", mr: 0.5, fontSize: "1rem" }}
              />
              <Link
                href="https://stanzacreators.substack.com/p/managing-calendars"
                target="_blank"
              >
                Learn more
              </Link>{" "}
              about how to add links that turn into icons.
            </Typography>
          }
          {...getSlotProps(() => <NotesOutlined sx={{ mt: -1 }} />)}
        />

        <Box sx={{ display: "flex", gap: 2, justifyContent: { sm: "end" } }}>
          {!eventId ? (
            <Button
              size="default"
              variant="outlined"
              sx={{ width: { xs: "100%", sm: "auto" } }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          ) : (
            <Button
              size="default"
              type="delete"
              color="error"
              sx={{ width: { xs: "100%", sm: "auto" } }}
              onClick={handleOpenDelete}
            >
              Delete
            </Button>
          )}
          <LoadingButton
            size="default"
            variant="contained"
            sx={{ width: { xs: "100%", sm: "120px" } }}
            onClick={() =>
              handleSave({
                name,
                description: parseDescripionInput(description),
                location,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                allDay,
              })
            }
            loading={isLoading}
            disabled={isInvalid}
          >
            Save
          </LoadingButton>
        </Box>
      </Stack>
    </>
  );
};

const EditEventDialog = ({
  calendarId,
  refetchCalendarEvents,
  refetchCalendars,
  eventData,
  open,
  setOpen,
}) => {
  const { isSmallScreen } = useDimensions();
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");

  const {
    eventId,
    eventSummary,
    eventLocation,
    eventDateStart,
    eventTvSeasonEpisode,
    eventDescription,
    isEventLive,
    isPastEvent,
    s,
  } = eventData ?? {};

  const { mutateAsync: createEvent, isLoading: isLoadingEventCreation } =
    useCreateEvent();
  const { mutateAsync: updateEvent, isLoading: isLoadingEventUpdate } =
    useUpdateEvent();
  const { mutateAsync: deleteEvent, isLoading: isLoadingEventDelete } =
    useDeleteEvent();
  const isLoading = isLoadingEventCreation || isLoadingEventUpdate;

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      if (deleteText !== "DELETE") {
        setDeleteMessage("Please check for typos.");
        return;
      }
      await deleteEvent({ calendarId, eventId });
      setShowDeleteEventModal(false);
      handleClose();
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "Event deleted successfully.",
        "success"
      );
      await refetchCalendars();
      await refetchCalendarEvents();
    } catch (error) {
      console.error(error);
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "Error deleting. Please try again.",
        "error"
      );
    }
  };

  const handleUpdate = async (data) => {
    try {
      await updateEvent({ calendarId, eventId, ...data });
      handleClose();
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "Event saved successfully.",
        "success"
      );
      await refetchCalendars();
      await refetchCalendarEvents();
    } catch (error) {
      console.error(error);
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "Error saving. Please try again.",
        "error"
      );
    }
  };

  const handleCreate = async (data) => {
    try {
      await createEvent({ calendarId, ...data });
      handleClose();
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "Event created successfully.",
        "success"
      );
      await refetchCalendars();
      await refetchCalendarEvents();
    } catch (error) {
      console.error(error);
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "Error creating event. Please try again.",
        "error"
      );
    }
  };

  return (
    <>
      {isSmallScreen ? (
        <BottomDialog
          open={open}
          handleClose={handleClose}
          sx={{ height: "auto" }}
          dialogTitle={eventId ? "Edit Event" : "Create Event"}
        >
          <EditEventContent
            eventData={eventData}
            handleSave={eventId ? handleUpdate : handleCreate}
            handleCancel={handleClose}
            handleOpenDelete={() => setShowDeleteEventModal(true)}
            isLoading={isLoading}
          />
        </BottomDialog>
      ) : (
        <CenterDialog
          open={open}
          handleClose={handleClose}
          sx={{ height: "auto" }}
          dividers
          dialogTitle={eventId ? "Edit Event" : "Create Event"}
        >
          <EditEventContent
            eventData={eventData}
            handleSave={eventId ? handleUpdate : handleCreate}
            handleCancel={handleClose}
            handleOpenDelete={() => setShowDeleteEventModal(true)}
            isLoading={isLoading}
          />
        </CenterDialog>
      )}
      <DeleteEventDialog
        open={showDeleteEventModal}
        handleDelete={handleDelete}
        handleClose={() => setShowDeleteEventModal(false)}
        deleteText={deleteText}
        setDeleteText={setDeleteText}
        deleteMessage={deleteMessage}
        isLoading={isLoadingEventDelete}
      />
    </>
  );
};

export default EditEventDialog;
