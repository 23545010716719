import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";

export const LoadingButton = ({ loading, children, ...props }) => {
  const buttonRef = React.useRef(null);
  const [width, setWidth] = useState(null);

  const updateWidth = () => {
    const widthPropWithPertentage =
      String(props.sx?.width ?? "")?.includes("%") ||
      String(props.sx?.width?.xs ?? "").includes("%") ||
      String(props.sx?.width?.sm ?? "")?.includes("%") ||
      String(props.sx?.width?.md ?? "")?.includes("%") ||
      String(props.sx?.width?.lg ?? "")?.includes("%");
    if (buttonRef.current && !widthPropWithPertentage) {
      setWidth(buttonRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        updateWidth();
        observer.disconnect();
      }
    });

    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }

    return () => {
      if (buttonRef.current) {
        observer.unobserve(buttonRef.current);
      }
    };
  }, []);

  const circularProgressSize = {
    grown: 24,
    shrinked: 24,
    large: 23,
    small: 10,
    micro: 8,
    default: 24,
  }[props.size || "default"];

  return (
    <Button
      ref={buttonRef}
      disabled={loading}
      {...props}
      sx={{ ...props.sx, ...{ ...(width ? { width } : undefined) } }}
    >
      {loading ? (
        <CircularProgress size={circularProgressSize} color="inherit" />
      ) : (
        children
      )}
    </Button>
  );
};
