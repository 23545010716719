import { Alert, Snackbar, Slide } from "@mui/material";
import React, { useState, useEffect } from 'react';

function Transition(props) {
  return <Slide {...props} direction="down" />;
}

function StatusSnackbar({ setSnackbarOpen, openState, message, severity, autoHideDuration }) {
  const [open, setOpen] = useState(openState);

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  const handleClose = (event) => {
    // do not close snackbar if user clicks away
    if (event && !document.getElementById('status-snackbar').contains(event.target)) {
      return;
    }
    setOpen(false);
    setSnackbarOpen(false);
  };

  // Function to render message
  const renderMessage = () => {
    // Check if message is a React component
    if (React.isValidElement(message)) {
      return message;
    }
    // If message is a string
    return <span>{message}</span>;
  };

  return (
    <Snackbar
      id="status-snackbar"
      data-testid="status-snackbar"
      open={open}
      TransitionComponent={Transition}
      message={message}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {renderMessage()} {/* Render message based on its type */}
      </Alert>
    </Snackbar>
  );
}

export default StatusSnackbar;
