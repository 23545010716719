import { useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useStripeCheckout } from "src/services/mutations/useStripeCheckout.js";
import { LoadingButton } from "src/components/blocks/LoadingButton.js";
import { customEvent } from "src/utils/gtag.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { getPageRoute } from "src/services/Config.js";
import { handleUpgrade } from "src/utils/Utils.js";

export const UpgradeConsumerButton = ({ sx, children, ...props }) => {
  const { mutateAsync: getStripeCheckout, isLoading } = useStripeCheckout();
  const user = useContext(AuthContext).user;
  const [searchParams] = useSearchParams();
  const isLifeTimeMember = user?.lifetimeMember;
  const navigate = useNavigate();

  const handleUpgradeClick = async () => {
    await handleUpgrade({ user, searchParams, getStripeCheckout });
  };

  const handleGoHome = () => {
    navigate(getPageRoute("home", "HOME"));
  };

  return (
    <LoadingButton
      size="grown"
      variant="contained"
      color="primary"
      loading={isLoading}
      onClick={!isLifeTimeMember ? handleUpgradeClick : handleGoHome}
      sx={{ height: "auto", width: "100%", ...sx }}
      {...props}
    >
      {children}
    </LoadingButton>
  );
};
