import { useCallback, useMemo } from 'react'
import { parseDateEvent } from './Utils.js'

export const useIsEventLive = (eventDateStart, eventDateEnd) => {
    const calc = useCallback(() => {
        const { eventParsed: eventParsedStartDay, isAllDayEvent } = parseDateEvent(eventDateStart[3])
        const currentDate = new Date();
        if (!isAllDayEvent) {
            const { eventParsed: eventParsedEndDay } = parseDateEvent(eventDateEnd[3])
            return currentDate >= eventParsedStartDay && currentDate <= eventParsedEndDay
        } else {
            return currentDate.toDateString() === eventParsedStartDay.toDateString()
        }
    }, [eventDateStart, eventDateEnd])
    const isEventLive = useMemo(calc, [calc])
    return isEventLive
}