const temporaryEmails = [
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "dispostable.com",
    "mailnesia.com",
    "mytemp.email",
    "throwawaymail.com",
    "tempmailo.com",
    "yopmail.com",
    "jetable.org",
    "getairmail.com",
    "fakeinbox.com",
    "mailcatch.com",
    "incognitomail.org",
    "temp-mail.io",
    "trash-mail.com",
    "mailmetrash.com",
    "mailnull.com",
    "spamgourmet.com",
    "anonbox.net",
    "tempail.com",
    "meltmail.com",
    "tempinbox.com",
    "e4ward.com",
    "mailnesia.org",
    "discard.email",
    "mailnesia.net",
    "spambox.us",
    "spamfree24.org",
    "tempomail.fr",
    "grr.la",
    "spamgourmet.net",
    "pokemail.net",
    "mailcatch.com",
    "trashmail.com",
    "discardmail.com",
    "spambog.com",
    "spamavert.com",
    "mailin8r.com",
    "mailinator2.com",
    "spamherelots.com",
    "mintemail.com",
    "tempemail.net",
    "spamhereplease.com",
    "spamhereplease.com",
    "spamherelots.com",
    "spaminator.de",
    "spam.la",
    "spam.su",
    "spamgourmet.org",
    "spambox.us",
    "spamfree24.de",
    "spamfree24.eu",
    "spamfree24.info",
    "spamfree24.net",
    "spamfree24.org",
    "spamfree24.xyz",
    "spamfree.eu",
    "spamfree24.top",
    "trashmail.com",
    "trashmail.net",
    "trashmail.org",
    "trashmailer.com",
    "wh4f.org",
    "whyspam.me",
    "willselfdestruct.com",
    "winemaven.info",
    "wronghead.com",
    "wuzup.net",
    "wuzupmail.net",
    "xagloo.com",
    "xemaps.com",
    "xmaily.com",
    "xoxy.net",
    "yep.it",
    "yogamaven.com",
    "yopmail.com",
    "yopmail.fr",
    "yopmail.net",
    "yopweb.com",
    "yourdomain.com",
    "zehnminutenmail.de",
    "zippymail.info",
    "zoaxe.com",
    "zoemail.net",
    "zoemail.org",
    "zomg.info",
    "storesr.com",
    "yopmail.com",
    "test.com",
    "0-mail.com",
    "0815.ru",
    "0845.ru",
    "0clickemail.com",
    "0wnd.net",
    "0wnd.org",
    "10minutemail.com",
    "10minutemail.net",
    "12houremail.com",
    "12minutemail.com",
    "1pad.de",
    "20minutemail.com",
    "2prong.com",
    "30minutemail.com",
    "3d-painting.com",
    "4warding.com",
    "4warding.net",
    "4warding.org",
    "60minutemail.com",
    "675hosting.com",
    "675hosting.net",
    "675hosting.org",
    "6url.com",
    "75hosting.com",
    "75hosting.net",
    "75hosting.org",
    "7tags.com",
    "8127ep.com",
    "9ox.net",
    "a-bc.net",
    "afrobacon.com",
    "agedmail.com",
    "ajaxapp.net",
    "akapost.com",
    "akerd.com",
    "ama-trade.de",
    "ama-trans.de",
    "amilegit.com",
    "amiri.net",
    "amiriindustries.com",
    "ano-mail.net",
    "anon-mail.de",
    "anonbox.net",
    "anonmails.de",
    "anonymbox.com",
    "antichef.com",
    "antichef.net",
    "antireg.ru",
    "antispam.de",
    "antispam24.de",
    "antispammail.de",
    "armyspy.com",
    "asdasd.ru",
    "b2cmail.de",
    "baxomale.ht.cx",
    "beefmilk.com",
    "binkmail.com",
    "bio-muesli.info",
    "bio-muesli.net",
    "blackmarket.to",
    "bobmail.info",
    "bodhi.lawlita.com",
    "bofthew.com",
    "bootybay.de",
    "br.mintemail.com",
    "breakthru.com",
    "brefmail.com",
    "brennendesreich.de",
    "broadbandninja.com",
    "bsnow.net",
    "bspamfree.org",
    "buffemail.com",
    "bugmenever.com",
    "bugmenot.com",
    "bumpymail.com",
    "bund.us",
    "byom.de",
    "cam4you.cc",
    "card.zp.ua",
    "casualdx.com",
    "cellurl.com",
    "centermail.com",
    "centermail.net",
    "cheatmail.de",
    "chogmail.com",
    "choicemail1.com",
    "consumerriot.com",
    "cool.fr.nf",
    "correo.blogos.net",
    "cosmorph.com",
    "courriel.fr.nf",
    "courrieltemporaire.com",
    "cubiclink.com",
    "curryworld.de",
    "cust.in",
    "cuvox.de",
    "dacoolest.com",
    "dandikmail.com",
    "dayrep.com",
    "dbunker.com",
    "deadaddress.com",
    "deadspam.com",
    "dealja.com",
    "delikkt.de",
    "despam.it",
    "despammed.com",
    "devnullmail.com",
    "dfgh.net",
    "digitalsanctuary.com",
    "dingbone.com",
    "discardmail.com",
    "discardmail.de",
    "disposableaddress.com",
    "disposableemailaddresses:emailmiser.com",
    "disposeamail.com",
    "disposemail.com",
    "dispostable.com",
    "dm.w3internet.co.ukexample.com",
    "dodgeit.com",
    "dodgit.com",
    "dodgit.org",
    "donemail.ru",
    "dontreg.com",
    "dontsendmespam.de",
    "dotman.de",
    "dropcake.de",
    "dropmail.me",
    "dudmail.com",
    "dump-email.info",
    "dumpandjunk.com",
    "dumpmail.de",
    "dumpyemail.com",
    "duskmail.com",
    "e4ward.com",
    "easytrashmail.com",
    "edv.to",
    "einmalmail.de",
    "einrot.com",
    "eintagsmail.de",
    "email60.com",
    "emaildienst.de",
    "emailgo.de",
    "emailias.com",
    "emailigo.de",
    "emailinfive.com",
    "emaillime.com",
    "emailmiser.com",
    "emailsensei.com",
    "emailtemporanea.com",
    "emailtemporanea.net",
    "emailtemporario.com.br",
    "emailto.de",
    "emailwarden.com",
    "emailx.at.hm",
    "emailxfer.com",
    "emz.net",
    "enterto.com",
    "ephemail.net",
    "ero-tube.org",
    "etranquil.com",
    "etranquil.net",
    "etranquil.org",
    "explodemail.com",
    "express.net.ua",
    "eyepaste.com",
    "fakedemail.com",
    "fakeinbox.com",
    "fakeinformation.com",
    "fakemail.fr",
    "fakemailgenerator.com",
    "fansworldwide.de",
    "fastacura.com",
    "fastchevy.com",
    "fastchrysler.com",
    "fastkawasaki.com",
    "fastmazda.com",
    "fastmitsubishi.com",
    "fastnissan.com",
    "fastsubaru.com",
    "fastsuzuki.com",
    "fasttoyota.com",
    "fastyamaha.com",
    "film-blog.biz",
    "filzmail.com",
    "fivemail.de",
    "fizmail.com",
    "fly-ts.de",
    "flyspam.com",
    "fr33mail.info",
    "frapmail.com",
    "front14.org",
    "fudgerub.com",
    "fux0ringduh.com",
    "fyii.de",
    "garbagemail.org",
    "garliclife.com",
    "gehensiemirnichtaufdensack.de",
    "geschent.biz",
    "get1mail.com",
    "get2mail.fr",
    "getairmail.com",
    "getmails.eu",
    "getonemail.com",
    "getonemail.net",
    "ghosttexter.de",
    "giantmail.de",
    "girlsundertheinfluence.com",
    "gishpuppy.com",
    "gmal.com",
    "gmial.com",
    "gomail.in",
    "gowikibooks.com",
    "gowikicampus.com",
    "gowikicars.com",
    "gowikifilms.com",
    "gowikigames.com",
    "gowikimusic.com",
    "gowikinetwork.com",
    "gowikitravel.com",
    "gowikitv.com",
    "great-host.in",
    "greensloth.com",
    "gsrv.co.uk",
    "guerillamail.biz",
    "guerillamail.com",
    "guerillamail.net",
    "guerillamail.org",
    "guerrillamail.biz",
    "guerrillamail.com",
    "guerrillamail.de",
    "guerrillamail.info",
    "guerrillamail.net",
    "guerrillamail.org",
    "guerrillamailblock.com",
    "h.mintemail.com",
    "h8s.org",
    "haltospam.com",
    "hat-geld.de",
    "hatespam.org",
    "hidemail.de",
    "hmamail.com",
    "hochsitze.com",
    "hotmai.com",
    "hotmial.com",
    "hotpop.com",
    "hulapla.de",
    "humaility.com",
    "ieatspam.eu",
    "ieatspam.info",
    "ieh-mail.de",
    "ignoremail.com",
    "ihateyoualot.info",
    "iheartspam.org",
    "ikbenspamvrij.nl",
    "imails.info",
    "inboxclean.com",
    "inboxclean.org",
    "inboxed.im",
    "inboxed.pw",
    "incognitomail.com",
    "incognitomail.net",
    "incognitomail.org",
    "infocom.zp.ua",
    "insorg-mail.info",
    "instant-mail.de",
    "ip6.li",
    "ipoo.org",
    "irish2me.com",
    "is.af",
    "iwi.net",
    "jetable.com",
    "jetable.fr.nf",
    "jetable.net",
    "jetable.org",
    "jnxjn.com",
    "junk.to",
    "junk1e.com",
    "kasmail.com",
    "kaspop.com",
    "keepmymail.com",
    "killmail.com",
    "killmail.net",
    "kir.ch.tc",
    "klassmaster.com",
    "klassmaster.net",
    "klzlk.com",
    "kostenlosemailadresse.de",
    "koszmail.pl",
    "kulturbetrieb.info",
    "kurzepost.de",
    "lawlita.com",
    "letthemeatspam.com",
    "lhsdv.com",
    "lifebyfood.com",
    "link2mail.net",
    "linuxmail.so",
    "litedrop.com",
    "llogin.ru",
    "lol.ovpn.to",
    "lolfreak.net",
    "lookugly.com",
    "lopl.co.cc",
    "lortemail.dk",
    "losemymail.com",
    "lr78.com",
    "luckymail.org",
    "m21.cc",
    "m4ilweb.info",
    "maboard.com",
    "mail-temporaire.fr",
    "mail.by",
    "mail.mezimages.net",
    "mail.zp.ua",
    "mail1a.de",
    "mail21.cc",
    "mail2rss.org",
    "mail333.com",
    "mail4trash.com",
    "mailbidon.com",
    "mailbiz.biz",
    "mailblocks.com",
    "mailcatch.com",
    "mailde.de",
    "mailde.info",
    "maildrop.cc",
    "maildu.de",
    "maileater.com",
    "maileimer.de",
    "mailexpire.com",
    "mailforspam.com",
    "mailfreeonline.com",
    "mailin8r.com",
    "mailinater.com",
    "mailinator.com",
    "mailinator.net",
    "mailinator2.com",
    "mailincubator.com",
    "mailita.tk",
    "mailme.ir",
    "mailme.lv",
    "mailme24.com",
    "mailmetrash.com",
    "mailmoat.com",
    "mailms.com",
    "mailnator.com",
    "mailnesia.com",
    "mailnull.com",
    "mailorg.org",
    "mailscrap.com",
    "mailseal.de",
    "mailshell.com",
    "mailsiphon.com",
    "mailslite.com",
    "mailtome.de",
    "mailtrash.net",
    "mailtv.net",
    "mailtv.tv",
    "mailzilla.com",
    "mailzilla.org",
    "makemetheking.com",
    "malahov.de",
    "mbx.cc",
    "mega.zik.dj",
    "meinspamschutz.de",
    "meltmail.com",
    "messagebeamer.de",
    "mierdamail.com",
    "ministry-of-silly-walks.de",
    "mintemail.com",
    "misterpinball.de",
    "moburl.com",
    "moncourrier.fr.nf",
    "monemail.fr.nf",
    "monmail.fr.nf",
    "msa.minsmail.com",
    "mt2009.com",
    "mt2014.com",
    "mx0.wwwnew.eu",
    "mycard.net.ua",
    "mycleaninbox.net",
    "mypartyclip.de",
    "myphantomemail.com",
    "mysamp.de",
    "myspaceinc.com",
    "myspaceinc.net",
    "myspaceinc.org",
    "myspacepimpedup.com",
    "myspamless.com",
    "mytempmail.com",
    "mytrashmail.com",
    "nabuma.com",
    "neomailbox.com",
    "nepwk.com",
    "nervmich.net",
    "nervtmich.net",
    "netmails.com",
    "netmails.net",
    "netzidiot.de",
    "neverbox.com",
    "nevermail.de",
    "nincsmail.hu",
    "no-spam.ws",
    "nobugmail.com",
    "nobulk.com",
    "nobuma.com",
    "noclickemail.com",
    "nogmailspam.info",
    "nomail.pw",
    "nomail.xl.cx",
    "nomail2me.com",
    "nomorespamemails.com",
    "nospam.ze.tc",
    "nospam4.us",
    "nospamfor.us",
    "nospammail.net",
    "nospamthanks.info",
    "notmailinator.com",
    "nowmymail.com",
    "nurfuerspam.de",
    "nus.edu.sg",
    "nwldx.com",
    "objectmail.com",
    "obobbo.com",
    "odnorazovoe.ru",
    "ohaaa.de",
    "omail.pro",
    "oneoffemail.com",
    "oneoffmail.com",
    "onewaymail.com",
    "onlatedotcom.info",
    "online.ms",
    "oopi.org",
    "ordinaryamerican.net",
    "otherinbox.com",
    "ourklips.com",
    "outlawspam.com",
    "ovpn.to",
    "owlpic.com",
    "pancakemail.com",
    "pimpedupmyspace.com",
    "pjjkp.com",
    "plexolan.de",
    "politikerclub.de",
    "poofy.org",
    "pookmail.com",
    "powered.name",
    "privacy.net",
    "privatdemail.net",
    "privy-mail.de",
    "privymail.de",
    "proxymail.eu",
    "prtnx.com",
    "punkass.com",
    "put2.net",
    "putthisinyourspamdatabase.com",
    "quickinbox.com",
    "rcpt.at",
    "realtyalerts.ca",
    "receiveee.com",
    "recode.me",
    "recursor.net",
    "regbypass.com",
    "regbypass.comsafe-mail.net",
    "rejectmail.com",
    "rhyta.com",
    "rklips.com",
    "rmqkr.net",
    "rppkn.com",
    "rtrtr.com",
    "s0ny.net",
    "safe-mail.net",
    "safersignup.de",
    "safetymail.info",
    "safetypost.de",
    "sandelf.de",
    "saynotospams.com",
    "schafmail.de",
    "schmeissweg.tk",
    "schrott-email.de",
    "secmail.pw",
    "secretemail.de",
    "secure-mail.biz",
    "secure-mail.cc",
    "selfdestructingmail.com",
    "sendspamhere.com",
    "senseless-entertainment.com",
    "server.ms",
    "sharklasers.com",
    "shieldemail.com",
    "shiftmail.com",
    "shitmail.me",
    "shortmail.net",
    "shut.name",
    "shut.ws",
    "sibmail.com",
    "sinnlos-mail.de",
    "skeefmail.com",
    "sky-ts.de",
    "slaskpost.se",
    "slopsbox.com",
    "smashmail.de",
    "smellfear.com",
    "snakemail.com",
    "sneakemail.com",
    "sneakmail.de",
    "snkmail.com",
    "sofimail.com",
    "sofort-mail.de",
    "sofortmail.de",
    "sogetthis.com",
    "soodonims.com",
    "spam.la",
    "spam.su",
    "spam4.me",
    "spamail.de",
    "spamavert.com",
    "spambob.com",
    "spambob.net",
    "spambob.org",
    "spambog.com",
    "spambog.de",
    "spambog.ru",
    "spambox.info",
    "spambox.irishspringrealty.com",
    "spambox.us",
    "spamcannon.com",
    "spamcannon.net",
    "spamcero.com",
    "spamcon.org",
    "spamcorptastic.com",
    "spamcowboy.com",
    "spamcowboy.net",
    "spamcowboy.org",
    "spamday.com",
    "spamex.com",
    "spamfree.eu",
    "spamfree24.com",
    "spamfree24.de",
    "spamfree24.eu",
    "spamfree24.info",
    "spamfree24.net",
    "spamfree24.org",
    "spamgourmet.com",
    "spamgourmet.net",
    "spamgourmet.org",
    "spamherelots.com",
    "spamhereplease.com",
    "spamhole.com",
    "spamify.com",
    "spaminator.de",
    "spamkill.info",
    "spaml.com",
    "spaml.de",
    "spammotel.com",
    "spamobox.com",
    "spamoff.de",
    "spamslicer.com",
    "spamspot.com",
    "spamthis.co.uk",
    "spamthisplease.com",
    "spamtrail.com",
    "speed.1s.fr",
    "spoofmail.de",
    "squizzy.de",
    "sry.li",
    "stinkefinger.net",
    "stuffmail.de",
    "super-auswahl.de",
    "supergreatmail.com",
    "supermailer.jp",
    "superstachel.de",
    "suremail.info",
    "tagyourself.com",
    "teewars.org",
    "teleworm.com",
    "teleworm.us",
    "temp-mail.ru",
    "tempail.com",
    "tempalias.com",
    "tempe-mail.com",
    "tempemail.biz",
    "tempemail.co.za",
    "tempemail.com",
    "tempemail.net",
    "tempinbox.co.uk",
    "tempinbox.com",
    "tempmail.eu",
    "tempmail.it",
    "tempmail2.com",
    "tempmailer.com",
    "tempmailer.de",
    "tempomail.fr",
    "temporarily.de",
    "temporarioemail.com.br",
    "temporaryemail.net",
    "temporaryforwarding.com",
    "temporaryinbox.com",
    "temporarymailaddress.com",
    "thanksnospam.info",
    "thankyou2010.com",
    "thc.st",
    "thisisnotmyrealemail.com",
    "thismail.net",
    "throwawayemailaddress.com",
    "tilien.com",
    "tittbit.in",
    "tmailinator.com",
    "tokem.co",
    "topranklist.de",
    "tormail.org",
    "tradermail.info",
    "trash-amil.com",
    "trash-mail.at",
    "trash-mail.com",
    "trash-mail.de",
    "trash2009.com",
    "trashdevil.com",
    "trashdevil.de",
    "trashemail.de",
    "trashinbox.com",
    "trashmail.at",
    "trashmail.com",
    "trashmail.de",
    "trashmail.me",
    "trashmail.net",
    "trashmail.org",
    "trashmail.ws",
    "trashmailer.com",
    "trashymail.com",
    "trashymail.net",
    "trialmail.de",
    "trillianpro.com",
    "turual.com",
    "twinmail.de",
    "tyldd.com",
    "uggsrock.com",
    "upliftnow.com",
    "uplipht.com",
    "us.af",
    "venompen.com",
    "veryrealemail.com",
    "vidchart.com",
    "viditag.com",
    "viewcastmedia.com",
    "viewcastmedia.net",
    "viewcastmedia.org",
    "vipmail.name",
    "vipmail.pw",
    "vpn.st",
    "vsimcard.com",
    "wasteland.rfc822.org",
    "watch-harry-potter.com",
    "watchfull.net",
    "webm4il.info",
    "weg-werf-email.de",
    "wegwerf-email-adressen.de",
    "wegwerf-email.de",
    "wegwerf-email.net",
    "wegwerf-emails.de",
    "wegwerfadresse.de",
    "wegwerfemail.com",
    "wegwerfemail.de",
    "wegwerfemail.net",
    "wegwerfemail.org",
    "wegwerfemailadresse.com",
    "wegwerfmail.de",
    "wegwerfmail.net",
    "wegwerfmail.org",
    "wetrainbayarea.com",
    "wetrainbayarea.org",
    "wh4f.org",
    "whyspam.me",
    "willhackforfood.biz",
    "willselfdestruct.com",
    "winemaven.info",
    "wolfsmail.tk",
    "writeme.us",
    "wronghead.com",
    "wuzup.net",
    "wuzupmail.net",
    "www.e4ward.com",
    "www.gishpuppy.com",
    "www.mailinator.com",
    "wwwnew.eu",
    "x.ip6.li",
    "xagloo.com",
    "xemaps.com",
    "xents.com",
    "xmaily.com",
    "xoxy.net",
    "yanet.me",
    "yep.it",
    "yogamaven.com",
    "yopmail.fr",
    "yopmail.net",
    "youmailr.com",
    "ypmail.webarnak.fr.eu.org",
    "yuurok.com",
    "yxzx.net",
    "z1p.biz",
    "zehnminuten.de",
    "zehnminutenmail.de",
    "zippymail.info",
    "zoaxe.com",
    "zoemail.org",
    "10minut.xyz",
    "nwytg.net",
    "dispostable.com",
    "nowmymail.net",
    "tempr.email",
    "discard.email",
    "discardmail.com",
    "discardmail.de",
    "spambog.com",
    "spambog.de",
    "spambog.ru",
    "0815.ru",
    "btcmail.pw",
    "knol-power.nl",
    "hartbot.de",
    "freundin.ru",
    "smashmail.de",
    "s0ny.net",
    "i6.cloudns.cc",
    "i6.cloudns.cx",
    "pecinan.net",
    "budaya-tionghoa.com",
    "1mail.x24hr.com",
    "from.onmypc.info",
    "now.mefound.com",
    "mowgli.jungleheart.com",
    "2.tebwinsoi.ooo",
    "2.sexymail.ooo",
    "janck.in",
    "bangsat.in",
    "kontol.co.uk",
    "kontol.city",
    "barrabravaz.com",
    "myfreemail.space",
    "yourspamgoesto.space",
    "pecinan.org",
    "budayationghoa.com",
    "katergizmo.de",
    "mailbox.r2.dns-cloud.net",
    "PW.r4.DNS-cloud.net",
    "r4.DNS-cloud.net",
    "sex.dns-cloud.net",
    "bratwurst.dnsabr.com",
    "DVX.dnsabr.com",
    "MXP.dnsabr.com",
    "MXP.dns-cloud.net",
    "APPLE.dnsabr.com",
    "opende.de",
    "CR.cloudns.asia",
    "TLS.cloudns.asia",
    "MSFT.cloudns.asia",
    "B.cr.cloUdnS.asia",
    "ssl.tls.cloudns.ASIA",
    "sweetxxx.de",
    "DVD.dns",
    "cloud.net",
    "DVD.dnsabr.com",
    "BD.dns-cloud.net",
    "123.DNS-cloud.net",
    "456.DNS-cloud.net",
    "789.DNS-cloud.net",
    "40.Volvo-XC.tk",
    "60.Volvo-XC.tk",
    "90.Volvo-XC.tk",
    "40.Volvo-XC.ml",
    "60.Volvo-XC.ml",
    "90.Volvo-XC.ml",
    "a.yertxenor.tk",
    "b.yertxenor.tk",
    "YX.dns-cloud.net",
    "SHIT.dns-cloud.net",
    "SHIT.dnsabr.com",
    "10.dns-cloud.net",
    "20.dns-cloud.net",
    "30.dns-cloud.net",
    "sky.dnsabr.com",
    "ubuntu.dnsabr.com",
    "ubuntu.dns-cloud.net",
    "HBO.dns-cloud.net",
    "HBO.dnsabr.com",
    "GMX.dnsabr.com",
    "GMX.dns-cloud.net",
    "ABB.dnsabr.com",
    "ABB.DNS-CLOUD.net",
    "VISA.dnsabr.com",
    "VISA.dns-cloud.net",
    "eu.dns-cloud.net",
    "eu.dnsabr.com",
    "asia.dnsabr.com",
    "8.dnsabr.com",
    "Yop.dnsabr.com",
    "Yop.Dns-Cloud.net",
    "pw.8.dnsabr.com",
    "mm.8.dnsabr.com",
    "23.8.dnsabr.com",
    "email-mail.pw",
    "pw.Email-Mail.cf",
    "terrapuz.ddns.net",
    "8-8.ddns.net",
    "8-8.sytes.net",
    "v250.tk",
    "v250.cf",
    "v250.ml",
    "v250.ga",
    "v250.gq",
    "v250.dns-cloud.net",
    "m3.dns-cloud.net",
    "m4.dns-cloud.net",
    "m5.dns-cloud.net",
    "ETR500.tk",
    "ETR500.cf",
    "ETR500.m",
    "ETR500.ga",
    "ETR500.gq",
    "ETR700.tk",
    "ETR700.cf",
    "ETR700.ml",
    "ETR700.ga",
    "ETR700.gq",
    "lajoska.pe.hu",
    "hotmailproduct.com",
    "yahooproduct.net",
    "attnetwork.com",
    "gabox.store",
    "urhen.com",
    "mail.ru",
    "bk.ru",
    "list.ru",
    "inbox.ru",
    "ca11.cf",
    "omegafive.net",
    "163.com",
    "bcaoo.com",
    "mt2015.com",
    "mozej.com",
    "tympe.net",
    "gufum.com",
    "lettershield.com",
    "alonecmw.com",
    "56tyxr.cse445.com",
    "pelagius.net",
    "citmo.net",
    "closetab.email",
    "clip.lat",
];

const temporaryEmailsSet = new Set(temporaryEmails);

export default temporaryEmailsSet;
