import { Dialog, DialogContent, DialogActions, Button, Typography, List, Avatar, ListItem, IconButton, ListItemText, ListItemAvatar, Divider } from "@mui/material";
import { Folder, Add } from '@mui/icons-material';
import React from 'react';

function AddToGroupDialog({ createdGroups, open, handleClose, handleCreate, handleAdd, errorMessage }) {

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent sx={{ margin: "auto" }}>
                <Button variant="contained" sx={{ width: "100%", float: 'center', mt: 2 }} onClick={handleCreate}>Create new group</Button>
                <Divider sx={{ backgroundColor: 'grey', mt: 4 }} />
                {createdGroups?.map((group, index) => (
                    <List key={group._id} sx={{ width: "100%" }}>
                        <ListItem
                            disableGutters
                            secondaryAction={
                                <IconButton data-testid={`add-${index}`} edge="end" onClick={() => handleAdd(group)}>
                                    <Add />
                                </IconButton>
                            }
                        >
                            <ListItemAvatar sx={{ alignItems: "flex-start" }}>
                                <Avatar>
                                    <Folder />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={group.name}
                                secondary={group.description}
                                sx={{ mr: 5 }}
                            />
                        </ListItem>
                    </List>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
            <Typography color="delete" sx={{ mt: 1 }}>{errorMessage}</Typography>
        </Dialog>

    );
}

export default AddToGroupDialog;