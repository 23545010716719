import React from "react";
import { Typography, Box, Button, Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getPageRoute } from "src/services";
import { useTheme } from "@emotion/react";

const SearchSuggestions = ({ searchTerms, index }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const start = index * 3;
    const end = start + 3;
    return (
      <>
        <style>{`
        #search-suggestions + div > #event-card {
          border-top: none;
        }
        #search-suggestions + div {
          margin-top: 0px;
        }
      `}
        </style>
        <Box
          id="search-suggestions"
          sx={{
            display: "flex", flexDirection: "column", py: 4.5, pl: 2,
            borderTop: `1px solid ${theme.palette.text.tertiary}`,
            borderBottom: `1px solid ${theme.palette.text.tertiary}`,
          }}>
          <Typography variant="h2">Try searching for</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 3, gap: 2 }}>
            {searchTerms.slice(start, end).map((term) => (
              <Button
                key={term}
                component="div"
                onClick={() => navigate(getPageRoute("chat", "SEARCH"), { state: { term } })}
                sx={{
                  textTransform: "none",
                  fontSize: "inherit",
                  padding: 1,
                  display: "block",
                  width: "fit-content",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1.25 }}>
                  <Icon fontSize="medium" sx={{ color: 'primary.main' }} baseClassName="material-symbols-outlined">search</Icon>
                  <Typography variant="body1">{term}</Typography>
                </Box>
              </Button>
            ))}
          </Box>
        </Box>
      </>
    )
}

export default SearchSuggestions;