import { useEffect, useState, useRef } from "react";

/* 
  This hook will prevent to refresh the page when the user tries to go back.
*/
export function usePreventBackButton() {
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
    };

    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return null;
}

export const PreventBackButton = () => {
  usePreventBackButton();

  return <></>;
};

/* 
  This hook will save the scroll position of the current page when the user navigates away from it.
  When the user navigates back to the page, the page will scroll to the position that was saved.
*/
export const useBackNavigationPosition = () => {
  const scrollPositionRef = useRef({});
  const willScrollRef = useRef(false);

  useEffect(() => {
    const handleBackButton = async (event) => {
      willScrollRef.current = true;
        await new Promise((resolve) => setTimeout(resolve, 10));
        while (document.querySelector("[role='progressbar']")) {
          await new Promise((resolve) => setTimeout(resolve, 10));
        }
      const cachedPagePosition =
        scrollPositionRef.current[window.location.pathname];
      window.scrollTo({
        top: cachedPagePosition,
        behavior: "instant",
      });
      willScrollRef.current = false;
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    const handleScroll = (event) => {
      // Save the scroll position of the current page
      if (!willScrollRef.current) {
        scrollPositionRef.current[window.location.pathname] = window.scrollY;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
};

export const BackNavigationPosition = () => {
  useBackNavigationPosition();

  return <></>;
};
