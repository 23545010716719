import { useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { AuthContext } from "src/utils/AuthContext.js";
import { UpgradeConsumerButton } from "../../upgrades/UpgradeConsumerButton.js";
import { ExpandMore } from "@mui/icons-material";
import { getPageRoute } from "src/services/Config.js";
import Footer from "src/components/blocks/Footer.js"

const FreeTrialCancel = () => {
  const user = useContext(AuthContext).user;
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (user?.hasValidSubscription) {
      navigate(getPageRoute("home", "HOME"));
    }

    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const faqData = [
    {
      question: "How do I cancel my free trial?",
      answer: "After you sign up for the free trial, you'll see 'Your Plan' in the drop down menu in the top right corner. Click on 'Your Plan' and then 'Cancel Subscription' on the next screen. It's as easy as as two clicks.",
    },
    {
      question: "What happens if I forget to cancel my free trial?",
      answer: "We get it – life happens. Submit a request via our support form by clicking on 'Report' in the top right menu. We'll refund your subscription within 30 days of the free trial ending.",
    },
    {
      question: "My payment information isn't being accepted.",
      answer: "If you're experiencing issues with a valid card not being accepted, please send us an email at founders@stanza.co with more details on the error. We'll look into it asap.",
    },
  ];

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Box
        id="container"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "5% auto 0 auto",
          maxWidth: "400px",
          position: "relative",
          p: 2,
          px: 3.5,
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Button
          variant="text"
          onClick={() => navigate(getPageRoute("home", "HOME"))}
          sx={{ color: "text.secondary", mr: "auto", px: 0 }}
        >
          GO HOME
        </Button>
        <Icon
          baseClassName="material-symbols-outlined"
          sx={{
            display: "block",
            margin: "0 auto",
            color: `${theme.palette.icon.primary}`,
            verticalAlign: "middle",
            fontSize: "4rem",
            mt: 3,
            mb: 1,
            fontWeight: 300,
          }}
        >
          lightbulb_outline
        </Icon>
        <Typography
          variant={"h2"}
          sx={{ mr: "auto", textAlign: "left", mt: 3 }}
        >
          We get it. Asking for your credit card for a free trial can seem like
          a lot.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{ mr: "auto", textAlign: "left", mt: 2.5 }}
        >
          So, why do we ask for it?
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{ mr: "auto", textAlign: "left", mt: 2.5, fontSize: "1rem" }}
          color="text.secondary"
        >
          We've tried it the other way – a free trial without a credit card. Turns out 90% of free trial-ers upgrade but many forget to add their payment info before the trial expired. 
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{ mr: "auto", textAlign: "left", mt: 2, fontSize: "1rem" }}
          color="text.primary"
        > 
          Their calendars were wiped once the trial ended, and <b> they missed important events. </b>
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{ mr: "auto", textAlign: "left", mt: 2, fontSize: "1rem" }}
          color="text.secondary"
        >
          We can't turn back time for missed events, but we can (and do) refund
          subscriptions if you forget to cancel before the free trial ends.
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{ mr: "auto", textAlign: "left", mt: 2, fontSize: "1rem" }}
          color="text.secondary"
        >
          Either way, we've got you covered.
        </Typography>
        <UpgradeConsumerButton sx={{ mt: 3.5 }}>
          FINISH SIGNING UP
        </UpgradeConsumerButton>
        <Typography variant={"h2"} sx={{ mt: 5, mb: 2 }}>
          FAQs
        </Typography>
        {faqData.map((item, index) => (
          <Accordion
            key={index}
            disableGutters
            sx={{
              width: "100%",
              boxShadow: "none",
              border: "none",
              "&::before": {
                display: "none",
              },
              "&:not(:last-child)": {
                marginBottom: 2,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`faq-${index}-content`}
              id={`faq-${index}-header`}
              sx={{
                backgroundColor: "transparent",
                boxShadow: "none",
                padding: 0,
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
                "& .MuiAccordionSummary-expandIconWrapper": {
                  color: "inherit",
                },
              }}
            >
              <Typography
                variant="h5"
                sx={{ mr: "auto", textAlign: "left", textWrap: "pretty" }}
              >
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{
                  mr: "auto",
                  textAlign: "left",
                  mt: 1,
                  textWrap: "pretty",
                }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        <Button
          size="grown"
          variant="outlined"
          onClick={() =>
            navigate(getPageRoute("help", "FREE_TRIAL_STEPS"))
          }
          sx={{ mt: 3, mb: 10, height: "auto", width: "100%" }}
        >
          How your Free Trial Works
        </Button>
      </Box>
      <Footer />
    </div>
  );
};

export default FreeTrialCancel;
