import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

export const useDimensions = () => {

    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isExtraLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

    return {
        isExtraSmallScreen,
        isSmallScreen,
        isMediumScreen,
        isLargeScreen,
        isExtraLargeScreen
    }
}