import * as React from "react";
const SVGComponent = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.599 0H0v7.599h7.599V0ZM16 0H8.401v7.599h7.6V0ZM7.599 8.401H0v7.6h7.599V8.4Zm8.401 0H8.401v7.6h7.6V8.4Z"
            fill="#000"
        />
    </svg>
);
export default SVGComponent;
