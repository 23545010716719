import { useLayoutEffect } from "react";

export const useFullHeightPageFix = (containerRef) => {
    useLayoutEffect(() => {
        if (navigator.userAgent.match(/(android)/i)) {
            const handleResize = () => {
                if (containerRef.current) {
                    containerRef.current.style.height = window.innerHeight + 'px';
                }
            }
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            }
        }
        if (navigator.userAgent.match(/(iPod|iPhone|iPad|safari)/i)) {
            containerRef.current.style.maxHeight = "-webkit-fill-available"
            document.scrollingElement.style.overscrollBehavior = "none";
        }
    }, [])
}