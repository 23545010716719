import axios from "axios";
import { getApiRoute } from "src/services";
import { useQuery } from "@tanstack/react-query";

export async function getUserCalendars({ user }) {
  let response = { data: null };
  if (user) {
    response = await axios.get(
      getApiRoute("calendar", "GET_USER_CALENDARS"),
      {
        withCredentials: true,
      }
    );
  }
  return response.data;
}

export const useGetUserCalendars = (
  params = {
    user: null,
  },
  options = {}
) => {
  return useQuery(["user-calendars"], () => getUserCalendars(params), {
    ...options,
    // Only retry if there was an error and it was not a 404
    retry: (failureCount, error) => {
      // If the error response is a 404, then don't retry
      if (error.response?.status === 404) {
        return 0;
      }
      return true;
    }
  });
};
