import { Dialog, DialogTitle, DialogContent, Button, TextField, DialogActions } from "@mui/material";
import React, { useState } from 'react';
import { LoadingButton } from "../LoadingButton.js";
import { useMutation } from "@tanstack/react-query";

function NewGroupDialog({ user, open, handleClose, handleCreateGroup, handleMessage }) {
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [handle, setHandle] = useState(user?.handle);
    const { mutateAsync: handleSubmit, isLoading } = useMutation(async () => {
        await handleCreateGroup(groupName, groupDescription, handle)
    });


    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Let's set up your group</DialogTitle>
            <DialogContent>
                <form data-testid="form-create-group" onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit();
                }}>
                    {!user?.handle && (
                        <TextField
                            required
                            disabled={user && user.handle !== null && user.handle !== undefined && user.handle !== ""}
                            value={handle}
                            label={user?.handle ? user.handle : "Pick a handle"}
                            helperText={
                                handleMessage
                                    ? handleMessage
                                    : `This is the URL where you can find & share your groups: stanza.co/${user?.handle ? user.handle : handle}`
                            }
                            variant="standard"
                            onChange={(event) => setHandle(event.target.value)}
                            error={!!handleMessage}
                            sx={{ width: "100%", mb: 2 }}
                        />
                    )}
                    <TextField
                        data-testid="input-group-name"
                        required
                        autoFocus
                        value={groupName}
                        label="Give your group a name"
                        fullWidth
                        variant="standard"
                        onChange={(event) => setGroupName(event.target.value)}
                    />
                    <TextField
                        data-testid="input-group-description"
                        autoFocus
                        value={groupDescription}
                        label="A few words to describe your group"
                        fullWidth
                        variant="standard"
                        sx={{ mt: 2 }}
                        onChange={(event) => setGroupDescription(event.target.value)}
                    />
                    <LoadingButton variant="contained" type="submit" size="large" loading={isLoading} sx={{ float: 'right', mt: 3 }}>Save</LoadingButton>
                    <Button onClick={handleClose} size="large" sx={{ float: 'right', mt: 3, mr: 1 }}>Cancel</Button>
                </form>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    );
}

export default NewGroupDialog;