import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function createEvent({
  calendarId,
  name,
  description,
  location,
  startDate,
  endDate,
  allDay,
}) {
  await axios.post(
    getApiRoute("event", "CREATE_EVENT"),
    {
      calendarId,
      name,
      description,
      location,
      startDate,
      endDate,
      allDay
    },
    { withCredentials: true }
  );
}

export function useCreateEvent() {
  return useMutation(createEvent);
}
