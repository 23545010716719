import { Button, Icon } from "@mui/material";

const UploadPhotoButton = ({ children, calendarId, handleImageUpload, ...props }) => (
  <Button
    variant="contained"
    size="small"
    startIcon={<Icon baseClassName="material-symbols-outlined" sx={{ fontSize: "1rem !important" }} >add_photo_alternate</Icon>}
    sx={{ position: "absolute", backgroundColor: "#8E8E93", height: "24px", top: "10px", right: "10px", '.MuiButton-startIcon': { marginRight: "5px" } }}
    component="label"
    {...props}
  >
    Update Photo <input type="file" accept="image/png, image/jpeg" hidden onChange={handleImageUpload} data-testid={`image-event-input-${calendarId}`} />{" "}
  </Button>
)

export default UploadPhotoButton;