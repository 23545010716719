import axios from "axios";
import { getApiRoute } from "src/services";
import { useQuery } from "@tanstack/react-query";

export async function getCalendarsByHandle({ handle }) {
  let response = { data: null };
  response = await axios.get(
    getApiRoute("calendar", "GET_CALENDARS", { handle })
  );
  return response.data;
}

export const useGetCalendarsByHandle = (
  params = {
    handle: null,
  },
  options = {}
) => {
  return useQuery([`calendars-${params.handle}`], () => getCalendarsByHandle(params), {
    ...options,
    // Only retry if there was an error and it was not a 404
    retry: (failureCount, error) => {
      // If the error response is a 404, then don't retry
      if (error.response?.status === 404) {
        return 0;
      }
      return true;
    }
  });
};
