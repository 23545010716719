import { Box, CircularProgress } from "@mui/material";

function LoadingAnimation() {

    return (
        <Box id="loading-animation" sx={{ margin: "auto", maxWidth: { xs: '100%', sm: "600px" }, p: 6 }} id="loader">
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <CircularProgress thickness={2} />
            </Box>
        </Box>
    );
}

export default LoadingAnimation;