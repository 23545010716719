import { createContext, useContext, useEffect, useState, useRef, isValidElement } from 'react';
import StatusSnackbar from "../components/blocks/StatusSnackbar.js";

const DEFAULT_AUTO_HIDE_DURATION = 6000; // default 6 seconds [6000ms]

// Show success or error snackbar
export function showSnackBar(setOpen, setMessage, setSeverity, message, severity) {
    setSeverity(severity);
    setMessage(message);
    setOpen(true);
}

const defaultState = {
    snackbarOpen: false,
    setSnackbarOpen: () => { },
    snackbarMessage: '',
    setSnackbarMessage: () => { },
    snackbarSeverity: '',
    setSnackbarSeverity: () => { },
};


const SnackbarContext = createContext(defaultState);

export const useSnackbarContext = () => useContext(SnackbarContext);

export const SnackbarProvider = (props) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [autoHideDuration, setAutoHideDuration] = useState(DEFAULT_AUTO_HIDE_DURATION);
    const openActionRef = useRef(null);

    // middleware function to set snackbar open state
    const setSnackbarOpenToggler = (state) => {
        if (state && snackbarOpen) {
            // if snackbar is open and a new message is set, then open the snackbar again (restarts the timer)
            setSnackbarOpen(false);
            openActionRef.current = true; // set to true to open snackbar again after closing
        }
        return setSnackbarOpen(state);
    }

    useEffect(() => {
        // openActionRef firing
        if (openActionRef.current && snackbarOpen) {
            setTimeout(() => {
                setSnackbarOpen(true);
                openActionRef.current = false;
            }, 1000);
        }
    }, [snackbarOpen])

    useEffect(() => {
        if (isValidElement(snackbarMessage) && snackbarMessage.props?.redirectionCallback) {
            // is a redirection message so not set to close automatically
            setAutoHideDuration(null);
        } else {
            setAutoHideDuration(DEFAULT_AUTO_HIDE_DURATION);
        }
    }, [snackbarMessage])


    const values = {
        snackbarOpen,
        setSnackbarOpen: setSnackbarOpenToggler,
        snackbarMessage,
        setSnackbarMessage,
        snackbarSeverity,
        setSnackbarSeverity,
        showSnackBar,
        autoHideDuration,
        setAutoHideDuration,
    };

    return <SnackbarContext.Provider value={values} {...props} />;
};

export const Snackbar = () => {
    const { snackbarOpen, setSnackbarOpen, snackbarMessage, snackbarSeverity, autoHideDuration } = useSnackbarContext();

    return snackbarOpen && <StatusSnackbar
        setSnackbarOpen={setSnackbarOpen}
        openState={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={autoHideDuration}
    />
}