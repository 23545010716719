import { Box, Typography } from "@mui/material";
import { useDimensions } from "src/utils/useDimensions.js"

const TvBadge = ({ seasonEpisode, ...props }) => {
    const { isMediumScreen } = useDimensions();
    return (
        <Box as="section" sx={{ display: "flex", position: "absolute", width: "100%", height: "100%", top: 0, left: 0, zIndex: 1, background: "linear-gradient(180deg, #000000, transparent)",  borderRadius: "8px" }} >
            <Box as="span" sx={{ display: "flex", height: "100%", alignItems: "center", margin: "0 auto" }} {...props}>
                <Typography variant={isMediumScreen ? "h2" : "title"} sx={{ color: "#ffffff", letterSpacing: "2px", fontWeight: 700 }}>{seasonEpisode}</Typography>
            </Box>
        </Box>
    );

}

export default TvBadge;