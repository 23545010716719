import { useCallback, useState, useMemo } from "react";
import { Box, Chip, Skeleton } from "@mui/material";

const TagsCollection = ({ trendingCalendarTags, setTagsFilter, tagsFilter }) => {
    const [selectedTag, setSelectedTag] = useState(tagsFilter[0]);
    const isLoading = trendingCalendarTags.length === 0;

    const handleSelectTag = useCallback((tag) => {
        setSelectedTag(tag);
        setTagsFilter([tag]);
    }, [setTagsFilter])

    const getChipStyle = useCallback((tag) => {
        return selectedTag === tag ? {
            color: "primary",
            variant: "default",
        } : {
            color: "default",
            variant: "outlined",
        }
    }, [selectedTag])

    const SkeletonTagContent = useMemo(() => Array.from({ length: 5 }, (_, i) => `VFE${i}`), []);

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", mb: 2 }}>
            {isLoading ? (
                SkeletonTagContent.map((tag) => (
                    <Skeleton key={tag} animation="wave" component="div" variant="rounded" sx={{ borderRadius: '19px' }}>
                        <Chip label={tag} />
                    </Skeleton>
                ))
            ) : (
                trendingCalendarTags.map((tag) => (
                    <Chip
                        data-testid={`tag-${tag}`}
                        key={tag}
                        label={tag}
                        {...getChipStyle(tag)}
                        onClick={() => handleSelectTag(tag)}
                    />
                ))
            )}
        </Box>
    );
}

export default TagsCollection;