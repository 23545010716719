import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function subscribeGroup({ groupId }) {
  await axios.post(
    getApiRoute("group", "ADD_GROUP"),
    { groupId },
    { withCredentials: true }
  );
}

export function useSubscribeGroup() {
  return useMutation(subscribeGroup);
}