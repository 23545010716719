import axios from "axios";
import { getApiRoute } from "src/services";
import { useQuery } from "@tanstack/react-query";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";

export async function getCalendarEvents({ link, updated }) {
  let response = { data: null };
  response = await axios.get(
    getApiRoute(
      "calendar",
      "GET_CALENDAR_EVENTS",
      {
        link: encodeURIComponent(link),
      },
      updated
        ? {
            updated: new Date(updated).getTime(),
          }
        : {}
    ),
    { withCredentials: true }
  );
  return response.data;
}

export async function getProfileCalendarEvents({ link, updated }) {
  let response = { data: null };
  response = await axios.get(
    getApiRoute(
      "calendar",
      "GET_PROFILE_CALENDAR_EVENTS",
      {
        link: encodeURIComponent(link),
      },
      updated
        ? {
            updated: new Date(updated).getTime(),
          }
        : {}
    ),
    { withCredentials: true }
  );
  return response.data;
}

export const useGetCalendarEvents = (
  params = {
    link: null,
    updated: null,
  },
  options = {
    enabled: true,
    isOwnProfile: false,
  }
) => {
  const { isOwnProfile, ...restOptions } = options;
  const queryOptions = {
    ...restOptions,
    // Only retry if there was an error and it was not a 404
    retry: (failureCount, error) => {
      // If the error response is a 404, then don't retry
      if (error.response?.status === 404) {
        return 0;
      }
      // If the server returns error.
      if (error.response?.status === 500) {
        setSnackbarMessage(
          "There was an error loading this calendar. Please try refreshing the page."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return 0;
      }
      return true;
    },
  };
  const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext();
  return useQuery(
    isOwnProfile
      ? [`profile-calendar-events-${params.link}`, params.updated]
      : [`calendar-events-${params.link}`, params.updated],
    isOwnProfile
      ? () => getProfileCalendarEvents(params)
      : () => getCalendarEvents(params),
    queryOptions
  );
};
