import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Box, Icon, Typography, Button } from "@mui/material";
import { getPageRoute } from "src/services/Config.js";

const StepsScreen = ({ title, steps = [], nav = "back", children }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Box
        id="container"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "5% auto 0 auto",
          maxWidth: "600px",
          position: "relative",
          p: 2,
          px: 3.5,
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Button
          variant="text"
          onClick={
            {
              back: () => navigate(-1),
              home: () => navigate(getPageRoute("home", "HOME")),
            }[nav]
          }
          sx={{ color: "text.secondary", mr: "auto", px: 0 }}
        >
          {{ back: "GO BACK", home: "GO HOME" }[nav]}
        </Button>
        <Icon
          baseClassName="material-symbols-outlined"
          sx={{
            display: "block",
            margin: "0 auto",
            color: `${theme.palette.icon.primary}`,
            verticalAlign: "middle",
            fontSize: "4rem",
            mt: 3,
            mb: 1,
            fontWeight: 300,
          }}
        >
          lightbulb_outline
        </Icon>
        <Typography variant={"h2"} sx={{ mb: 4 }}>
          {title}
        </Typography>

        <div style={{ position: "relative" }}>
          {/* step line */}
          <div
            style={{
              position: "absolute",
              background: "#abb1b4",
              width: "2px",
              height: "calc(100% - 65px)",
              top: "0px",
              left: "16px",
            }}
          />
          {/* step arrow */}
          <Icon
            baseClassName="material-symbols-outlined"
            sx={{
              color: `#abb1b4`,
              verticalAlign: "middle",
              position: "absolute",
              top: "calc(100% - 76px)",
              left: "15px",
              transform: "translateX(-50%)",
              width: 20,
              height: 20,
            }}
          >
            expand_more
          </Icon>
          {/* steps */}
          {steps.map((step, index) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: steps.length - 1 !== index ? 4.5 : 7,
              }}
              key={step.title}
            >
              <Box sx={{ display: "flex", alignItems: "start", gap: 2 }}>
                <div
                  style={{
                    minWidth: 35,
                    minHeight: 35,
                    borderRadius: "9999px",
                    background: "#e1e7ea",
                    position: "relative",
                    top: "-5px",
                  }}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant={"h5"}
                    sx={{ mr: "auto", textAlign: "left" }}
                  >
                    {step.title}
                  </Typography>
                  <Typography
                    variant={"subtitle2"}
                    sx={{
                      mt: 1,
                      mr: "auto",
                      textAlign: "left",
                      textWrap: "pretty",
                    }}
                    color="text.secondary"
                  >
                    {step.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </div>
        {children}
      </Box>
    </div>
  );
};

export default StepsScreen;
