import {
  Link, List, ListItem, IconButton, ListItemAvatar,
  ListItemText, Avatar, Menu, MenuItem, Typography,
  ListItemIcon, Box, Icon
} from "@mui/material";
import { CalendarToday, Folder } from '@mui/icons-material';
import { Link as RouterLink } from "react-router-dom";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { useTheme } from "@emotion/react";
import { getWebcalFormat } from "src/utils/Utils";
import { useContext, memo, useMemo } from "react";
import { AuthContext } from "src/utils/AuthContext";
import { useDimensions } from "src/utils/useDimensions";
import { useProfileImage } from "src/utils/useProfileImage.js";
import InfiniteScrollList from "src/components/blocks/InfiniteScrollList.js";
import ThreeDotsMenu from "./ThreeDotsMenu.js";

const CalendarItem = ({ calendar, 
  buttonIcon, buttonText, buttonAction, threeDotsMenu
}) => {
  const theme = useTheme();
  const { isMediumScreen, isSmallScreen } = useDimensions();
  const hrWidth = { isSmallScreen: "calc(100vw - 72px)", isMediumScreen: "calc(100% + 10px)", isLargeScreen: "calc(100% + 25px)" }[isSmallScreen ? "isSmallScreen" : isMediumScreen ? "isMediumScreen" : "isLargeScreen"]
  const profileImage = useProfileImage( useMemo(() => ({ picture: `${calendar.handle}-profileImage.png`}), []) );
  return (
    <div>
      <List sx={{ pt: 0, pb: 0.5 }}>
            <ListItem
              sx={{
                paddingLeft: 0, '& .MuiListItemSecondaryAction-root':
                {
                  right: isMediumScreen ? "-0.5rem" : "-1.5rem"
                }
              }}
              secondaryAction={
                isMediumScreen ?
                  <ThreeDotsMenu threeDotsMenu={[
                    {
                      name: buttonText,
                      action: buttonAction,
                      icon: buttonIcon,
                      color: buttonText.toLowerCase() === "delete" ? "error" : undefined
                    },
                    ...threeDotsMenu
                  ]} calendar={calendar}/>
                  :
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <IconButton data-testid={`${buttonText}-button-${calendar.name}`} aria-label={buttonText} onClick={() => buttonAction(calendar)}>
                      {buttonIcon()}
                    </IconButton>
                    {threeDotsMenu[0] && (
                      <IconButton data-testid={`${threeDotsMenu[0].name.replaceAll(' ', '-')}-button-${calendar.name}`} sx={{ mr: "-6px" }}aria-label={buttonText} onClick={() => threeDotsMenu[0].action(calendar)}>
                        {threeDotsMenu[0].icon()}
                      </IconButton>
                   )}
                    <ThreeDotsMenu threeDotsMenu={threeDotsMenu.slice(1)} calendar={calendar} />
                  </Box>
              }
            >
              <ListItemAvatar>
                {calendar.calendars ?
                  <Avatar src={profileImage}>
                    <Folder />
                  </Avatar>
                  :
                  <Avatar src={profileImage}>
                    <CalendarToday />
                  </Avatar>}
              </ListItemAvatar>
              <div style={{ position: "relative", minWidth: "100%" }}>
                <ListItemText
                  primary={
                    <Link
                      data-testid={`calendar-list-${calendar._id}`}
                      to={`/${calendar.handle}/${calendar._id}`}
                      component={RouterLink}
                      color="inherit"
                      underline="none"
                      variant="h5"
                    >
                      {calendar.name}
                    </Link>
                  }
                  secondary={
                    calendar.description !== "no description" ? calendar.description : "\n"
                  }
                  secondaryTypographyProps={
                    calendar.description !== "no description" ? {} : { whiteSpace: "pre-line !important", lineHeight: "1" }
                  }
                  sx={{
                    minWidth: "100%",
                    '& span, & p': {
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      paddingRight: isMediumScreen ? "60px" : "150px",
                    }
                  }}
                />
                <hr style={{ background: theme.palette.text.tertiary, bottom: "-1.1rem", position: "absolute", width: hrWidth, opacity: 1, height: "1px", border: "0px" }} />
              </div>
            </ListItem>
      </List>
    </div>
  )
};


// Used to display a list of calendars in a table and associated actions (edit or delete or add)
function CalendarList({ id, calendars, buttonIcon, buttonText, buttonAction, threeDotsMenu = []}) {
  const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
  const { user, refetchAuth } = useContext(AuthContext);
  const theme = useTheme();
  const { isMediumScreen, isSmallScreen } = useDimensions();

  return (
    <div style={{ paddingBottom: isMediumScreen ? "50px" : "100px" }}>
      <InfiniteScrollList threshold={400} id={`${id}Library`}>
        {calendars.map((calendar) => (
          <CalendarItem
          key={calendar._id}
          calendar={calendar} 
          buttonIcon={buttonIcon}
          buttonText={buttonText}
          buttonAction={buttonAction}
          threeDotsMenu={threeDotsMenu}
          />
        ))}
      </InfiniteScrollList>
    </div>
  );
}

export default memo(CalendarList, (prevProps, nextProps) => {
  return prevProps.calendars?.length === nextProps.calendars?.length &&
   prevProps.calendars?.every((calendar, index) => calendar.calendars?.length === nextProps.calendars[index].calendars?.length)
});