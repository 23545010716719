import React from "react";
import PropTypes from "prop-types";

export default class Ad extends React.Component {
  constructor(props) {
    super(props);
    this.adRef = React.createRef();
  }

  componentDidMount() {
    try {
      console.log("Ad component mounting...", this.adRef.current);

      if (typeof window === "undefined" || !window.top) {
        console.error("window.top is not available.");
        return;
      }

      // Verify if window.top is accessible
      try {
        // Attempt to access a property to check access
        void window.top.location.href;
      } catch (accessError) {
        console.error(
          "Cannot access window.top due to CORS restrictions:",
          accessError
        );
        return;
      }

      // Initialize __vm_add if it doesn't exist or isn't an array
      if (!Array.isArray(window.top.__vm_add)) {
        window.top.__vm_add = [];
        console.warn("window.top.__vm_add initialized as an empty array.");
      }

      if (this.adRef.current) {
        window.top.__vm_add.push(this.adRef.current);
        console.log(
          "Element added to window.top.__vm_add:",
          this.adRef.current
        );
      } else {
        console.error(
          "adRef.current is null when attempting to add to window.top.__vm_add."
        );
      }
    } catch (error) {
      console.error("Error in componentDidMount:", error);
    }
  }

  componentWillUnmount() {
    try {
      console.log("Ad component unmounting...", this.adRef.current);

      if (typeof window === "undefined" || !window.top) {
        console.error("window.top is not available.");
        return;
      }

      // Verify if window.top is accessible
      try {
        void window.top.location.href;
      } catch (accessError) {
        console.error(
          "Cannot access window.top due to CORS restrictions:",
          accessError
        );
        return;
      }

      // Initialize __vm_remove if it doesn't exist or isn't an array
      if (!Array.isArray(window.top.__vm_remove)) {
        window.top.__vm_remove = [];
        console.warn("window.top.__vm_remove initialized as an empty array.");
      }

      if (this.adRef.current) {
        window.top.__vm_remove.push(this.adRef.current);
        console.log(
          "Element added to window.top.__vm_remove:",
          this.adRef.current
        );
      } else {
        console.error(
          "adRef.current is null when attempting to add to window.top.__vm_remove."
        );
      }
    } catch (error) {
      console.error("Error in componentWillUnmount:", error);
    }
  }

  render() {
    try {
      const { placementId } = this.props;

      if (typeof placementId !== "string") {
        console.error("The 'placementId' prop must be a string.");
        return null;
      }

      if (!placementId) {
        console.warn(
          "No 'placementId' provided. The component will not render."
        );
        return null;
      }

      return (
        <div>
          <div
            className="vm-placement"
            data-id={placementId}
            ref={this.adRef}
          ></div>
        </div>
      );
    } catch (error) {
      console.error("Error in render:", error);
      return null;
    }
  }
}

Ad.propTypes = {
  placementId: PropTypes.string.isRequired,
};

Ad.defaultProps = {
  placementId: "",
};
