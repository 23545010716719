import axios from "axios";
import { getApiRoute } from "src/services";
import { useQuery } from "@tanstack/react-query";

export async function getTrendingTags() {
    let response = { data: null };
    response = await axios.get(getApiRoute("home", "GET_TRENDING_TAGS"));
    return response.data;
}

export const useGetTrendingTags = (params = {}, options = {}) => {
    return useQuery(["trending-tags"], () => getTrendingTags(), {
        ...options,
        // Only retry if there was an error and it was not a 404
        retry: (failureCount, error) => {
            // If the error response is a 404, then don't retry
            if (error.response?.status === 404) {
                return 0;
            }
            return true;
        }
    });
};
