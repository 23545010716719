import { memo, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSessionStorage } from "src/utils/useSessionStorage.js";

const InfiniteScrollList = ({
  children,
  element = 'div',
  useWindow = true,
  loadMore,
  hasMore,
  initialLoad = true,
  getScrollParent,
  id,
  ...props
}) => {

  const [limit, setLimit] = useSessionStorage(`${id}Limit`, 10);
  const childrens = children instanceof Array ? children : [children]

  const loadFunc = () => {
    setLimit(limit + 10)
  }

  return (
    <InfiniteScroll
      element={element}
      pageStart={1}
      loadMore={loadMore ?? loadFunc}
      hasMore={hasMore ?? childrens.length > limit}
      loader={null}
      useWindow={useWindow}
      initialLoad={initialLoad}
      getScrollParent={getScrollParent}
      {...props}
    >
      {childrens.slice(0, limit)}
    </InfiniteScroll>
  )
};


export default memo(InfiniteScrollList);