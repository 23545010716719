
import { memo, useContext } from 'react';
import { Button, Box, Stack } from "@mui/material";
import BottomDialog from './BottomDialog.js';
import { TimelineWrapper, CalendarSelect } from "./AddToCalendarDialog.js";
import { AuthContext } from "src/utils/AuthContext.js";
import ShareDialog from "src/components/blocks/dialogs/ShareDialog.js";

const TimelineDialog = ({
    open,
    calendars,
    handle,
    selectedCalendar,
    setSelectedCalendar,
    handleOpenAddToCalendar,
}) => {
    const { user } = useContext(AuthContext);

    const BottomDialogSx = {
        height: "121px",
        '.MuiDialogContent-root': { padding: "0px" },
        '.MuiDialogTitle-root': { margin: "auto" },
    }

    return (
        <BottomDialog
            open={open}
            hiddenCloseButton
            hiddenRectangle
            handleClose={() => { }}
            sx={BottomDialogSx}
            dialogTitle={<CalendarSelect setSelectedCalendar={setSelectedCalendar}
                user={user} selectedCalendar={selectedCalendar}
                calendars={calendars} size="md"
            />}
            disableScrollLock
            hideBackdrop
        >
            <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
                <Button size="shrinked" variant="contained" onClick={() => {
                    handleOpenAddToCalendar()
                }}>
                    Add to Calendar
                </Button>
                <ShareDialog calendar={selectedCalendar} handle={handle} as="IconButton" />
            </Stack>
        </BottomDialog>
    )
}

export default TimelineWrapper(memo(TimelineDialog, (prevProps, nextProps) => {
    return prevProps.open === nextProps.open
        && prevProps.handle === nextProps.handle
        && prevProps.calendarId === nextProps.calendarId
        && prevProps.selectedCalendar === nextProps.selectedCalendar
}));