import { useEffect, useRef } from 'react';

export function usePrevious(value) {
    const previousValueRef = useRef();

    useEffect(() => {
        previousValueRef.current = value;
    }, [value]);

    return previousValueRef.current;
}